import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-services-products-column',
  templateUrl: './services-products-column.component.html',
  styleUrls: ['./services-products-column.component.scss']
})
export class ServicesProductsColumnComponent implements OnInit, OnDestroy {

  public language: string;
  private langChange: Subscription;
 
  constructor(
    private translate: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.langChange.unsubscribe();
  }

  ngOnInit(): void {
    this.language = this.translate.currentLang
    this.langChange = this.translate.onLangChange.subscribe(() => {
      this.language = this.translate.currentLang
    })

  }

}
