<h4 class="col-title">{{'FOOT.SERVICES-AND-PRODUCTS' | translate}}</h4>
<!-- <div class="divider"></div>             -->
<p class="mt-2"><a routerLink="/" class="link secondary-color">{{'FOOT.MONITORING' | translate}}</a></p>
<p class="mt-1"><a routerLink="products" class="link secondary-color">{{'FOOT.NAVIGATION-AND-MAPS' | translate}}</a></p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color"><span></span></p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color"><span></span></p>

<h4 class="col-title">{{'FOOT.DOWNLOAD-NAVIGATION' | translate}}</h4>
<a href="https://play.google.com/store/apps/details?id=com.dynavix.navigation" target="_blank"
  rel="noopener noreferrer">
  <img src="{{'PRODUCTS.GOOGLEPLAY-LINK' | translate}}" alt="" style="height:3rem" class="mr-1">
</a>
<a href="{{'LINK-TO-APPSTORE' | translate}}" target="_blank" rel="noopener noreferrer">
  <img src="{{'PRODUCTS.APPSTORE-LINK' | translate}}" alt="" style="height:3rem" >
</a>