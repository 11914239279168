import { DateMapper } from './date-mapper';
import { ABTestGroup, EshopProduct, InternalInstalledBundle, InternalInstalledProductVersion, InternalProductVersion, InternalPromoCodeUsage, InternalUserDevice, PlatformType } from '@model/internalInfo';
import { PlatformTypeMap } from '../api/eshop/proto/generated/dynavix/AuthUserDevice_pb';
import { 
  InternalUserDevice as InternalUserDeviceProto, 
  ABTestGroup as ABTestGroupMap,
  InternalProductVersion as InternalProductVersionProto,
  InternalInstalledProductVersion as InternalInstalledProductVersionProto,
  InternalInstalledBundle as InternalInstalledBundleProto,
  EshopProduct as EshopProductProto,
  InternalPromoCodeUsage as InternalPromoCodeUsageProto,
} from '../api/eshop/proto/generated/dynavix/InternalInfoResponse_pb';
import { DomainMapper } from './domain-mapper';
import { Product as ProductProto} from '../api/eshop/proto/generated/dynavix/ProductInfoCommon_pb';
import { Product } from '@model/products';

export class InternalUserDeviceMapper implements DomainMapper<InternalUserDevice, InternalUserDeviceProto> {
  mapToDomain(data: InternalUserDeviceProto): InternalUserDevice {
    let retVal = new InternalUserDevice();
    retVal.activated = data.getActivated();
    retVal.activeMapProductCodes = data.getActivemapproductcodesList();

    const productMapper = new InnerProductMapper()
    retVal.availableProducts = data.getAvailableproductsList().map(item => {return productMapper.mapToDomain(item)});
    retVal.advertisignId = data.getAdvertisignid();
    retVal.anonymous = data.getAnonymous();
    retVal.appId = data.getAppid();
    retVal.appVersionCode = data.getAppversioncode();
    retVal.currentArch = data.getCurrentarch();
    retVal.deviceEnabled = data.getDeviceenabled();
    retVal.deviceId = data.getDeviceid();
    retVal.deviceName = data.getDevicename();

    const deviceABTestGroupMapper = new DeviceABTestGroupMapper();
    retVal.deviceABTestGroup = deviceABTestGroupMapper.mapToDomain(data.getDeviceabtestgroup());
    retVal.email = data.getEmail();
    retVal.enabled = data.getEnabled();
    retVal.firebaseToken = data.getFirebasetoken();
    retVal.hardwareId = data.getHardwareid();
    retVal.huaweiToken = data.getHuaweitoken();

    const installedProductVersionMapper = new InternalInstalledProductVersionMapper();
    
    retVal.installedProductVersions = data.getInstalledproductversionsList()
                                          .map(item => installedProductVersionMapper.mapToDomain(item))
                                          .sort((a, b) => {
                                            if(a.name < b.name) { return -1; }
                                            if(a.name > b.name) { return 1; }
                                            return 0;
                                          });
    retVal.isBeta = data.getIsbeta();
    retVal.isHuaweiDevice = data.getIshuaweidevice();

    const dateMapper = new DateMapper();
    retVal.lastUsed = dateMapper.mapToDomain(data.getLastused());
    retVal.locale = data.getLocale();
    retVal.oSVersion = data.getOsversion();

    const platformMapper = new PlatformTypeMapper();
    retVal.platformType = platformMapper.mapToDomain(data.getPlatformtype());
    retVal.projectType = data.getProjecttype();

    const productVersionMapper = new InternalProductVersionMapper();
    retVal.purchasedProductVersions = data.getPurchasedproductversionsList()
                                        .map(item => {return productVersionMapper.mapToDomain(item)})
                                        .sort((a, b) => {
                                          if(a.name < b.name) { return -1; }
                                          if(a.name > b.name) { return 1; }
                                          return 0;
                                        })
                                        .sort((a, b) => {return (a.enabled === b.enabled)? 0 : a.enabled? -1 : 1;});
    retVal.resolution = data.getResolution();
    retVal.roles = data.getRolesList();
    retVal.supportedArchs = data.getSupportedarchsList();

    const promoCodeMapper = new InternalPromoCodeUsageMapper();
    retVal.usedPromoCodes = data.getUsedpromocodesList().map(item => {return promoCodeMapper.mapToDomain(item)});
    retVal.userDeviceId = data.getUserdeviceid();
    retVal.userEnabled = data.getUserenabled();
    retVal.userId = data.getUserid();
    retVal.username = data.getUsername();
    return retVal;
  }
  mapFromDomain(data: InternalUserDevice): InternalUserDeviceProto {
    throw new Error('Method not implemented.');
  }
}

export class InternalProductVersionMapper implements DomainMapper<InternalProductVersion, InternalProductVersionProto> {
  mapToDomain(data: InternalProductVersionProto): InternalProductVersion {
    const dateMapper = new DateMapper();

    let retVal = new InternalProductVersion();
    retVal.enabled = data.getEnabled();
    retVal.productVersionId = data.getProductversionid();
    retVal.eshopProductId = data.getEshopproductid();
    retVal.merged = data.getMerged();
    retVal.name = data.getName();
    retVal.productName = data.getProductname();
    retVal.revision = data.getRevision();
    retVal.transactionTime = dateMapper.mapToDomain(data.getTransactiontime());
    retVal.udi = data.getUdi();
    retVal.udpActivated = data.getUdpactivated();
    retVal.udpEnabled = data.getUdpenabled();
    retVal.udpId = data.getUdpid();
    retVal.udpvId = data.getUdpvid();
    retVal.validFrom = dateMapper.mapToDomain(data.getValidfrom());
    retVal.validTo = dateMapper.mapToDomain(data.getValidto());
    retVal.version = data.getVersion();

    return retVal;
  }

  mapFromDomain(data: InternalProductVersion): InternalProductVersionProto {
    throw new Error('Method not implemented.');
  }
}

export class InternalInstalledProductVersionMapper implements DomainMapper<InternalInstalledProductVersion, InternalInstalledProductVersionProto> {
  mapToDomain(data: InternalInstalledProductVersionProto): InternalInstalledProductVersion {
    const dateMapper = new DateMapper();
    const bundlemapper = new InternalInstalledBundleMapper();

    let retVal = new InternalInstalledProductVersion();
    retVal.bundles = data.getBundlesList().map(item => bundlemapper.mapToDomain(item))
                                          .sort((a, b) => {
                                            if(a.name < b.name) { return -1; }
                                            if(a.name > b.name) { return 1; }
                                            return 0;
                                          });;
    retVal.lastUpdated = dateMapper.mapToDomain(data.getLastupdated());
    retVal.name = data.getName();
    retVal.productVersionId = data.getProductversionid();
    retVal.version = data.getVersion();

    return retVal;
  }

  mapFromDomain(data: InternalInstalledProductVersion): InternalInstalledProductVersionProto {
    throw new Error('Method not implemented.');
  }
}

export class InternalInstalledBundleMapper implements DomainMapper<InternalInstalledBundle, InternalInstalledBundleProto> {
  mapToDomain(data: InternalInstalledBundleProto): InternalInstalledBundle {

    let retVal = new InternalInstalledBundle();
    retVal.bundleId = data.getBundleid();
    retVal.name = data.getName();
    retVal.revision = data.getRevision();
    retVal.version = data.getVersion();

    return retVal;
  }

  mapFromDomain(data: InternalInstalledBundle): InternalInstalledBundleProto {
    throw new Error('Method not implemented.');
  }
}

export class InternalPromoCodeUsageMapper implements DomainMapper<InternalPromoCodeUsage, InternalPromoCodeUsageProto> {
  mapToDomain(data: InternalPromoCodeUsageProto): InternalPromoCodeUsage {

    let retVal = new InternalPromoCodeUsage();
    retVal.code = data.getCode();
    retVal.deviceId = data.getDeviceid();
    retVal.deviceName = data.getDevicename();
    retVal.hardwareId = data.getHardwareid();

    const platformTypeMapper = new PlatformTypeMapper();
    retVal.platformType = platformTypeMapper.mapToDomain(data.getPlatformtype());

    const productMapper = new EshopProductMapper();
    retVal.products = data.getProductsList().map(item => {return productMapper.mapToDomain(item)});

    const dateMapper = new DateMapper();
    retVal.used = dateMapper.mapToDomain(data.getUsed());
    retVal.userDeviceId = data.getUserdeviceid();
    retVal.userId = data.getUserid();
    retVal.username = data.getUsername();

    return retVal;
  }

  mapFromDomain(data: InternalPromoCodeUsage): InternalPromoCodeUsageProto {
    throw new Error('Method not implemented.');
  }
}

export class EshopProductMapper implements DomainMapper<EshopProduct, EshopProductProto> {
  mapToDomain(data: EshopProductProto): EshopProduct {

    let retVal = new EshopProduct();
    retVal.eshopProductId = data.getEshopproductid();
    retVal.name = data.getName();
    retVal.sku = data.getSku();

    return retVal;
  }

  mapFromDomain(data: EshopProduct): EshopProductProto {
    throw new Error('Method not implemented.');
  }
}

export class InnerProductMapper implements DomainMapper<Product, ProductProto> {
  mapToDomain(data: ProductProto): Product {

    let retVal = new Product();
    retVal.productId = data.getProductid();
    retVal.productSku = data.getProductsku();
    retVal.canbePurchased = data.getCanbepurchased();

    return retVal;
  }

  mapFromDomain(data: Product): ProductProto {
    throw new Error('Method not implemented.');
  }
}

export class DeviceABTestGroupMapper implements DomainMapper<ABTestGroup, number> {
  mapToDomain(data: number): ABTestGroup {
    switch(data) {
      case 0: return ABTestGroup.ABTestGroupNotSet
      case 1: return ABTestGroup.A_Trial5Days
      case 2: return ABTestGroup.B_Trial10Days
      case 3: return ABTestGroup.C_Trial20Days
      default: return ABTestGroup.ABTestGroupNotSet
    }
  }

  mapFromDomain(data: ABTestGroup): number {
    throw new Error('Method not implemented.');
  }
}

export class PlatformTypeMapper implements DomainMapper<PlatformType, PlatformTypeMap[keyof PlatformTypeMap]> {
  mapToDomain(data: PlatformTypeMap[keyof PlatformTypeMap]): PlatformType {
    switch(data) {
      case 0: return PlatformType.NotSet
      case 1: return PlatformType.Ios
      case 2: return PlatformType.Android
      case 3: return PlatformType.WebBrowser
      default: return PlatformType.NotSet
    }
  }

  mapFromDomain(data: PlatformType): PlatformTypeMap[keyof PlatformTypeMap] {
    throw new Error('Method not implemented.');
  }
}
