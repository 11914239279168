<div style="background-image: linear-gradient(to right, #C52F33, #821014); color: white;">
  <section class="theme-container" fxLayout="column" fxLayoutAlign="center center">
    <h2 style="text-align: center; font-size: 2.0em;" class="mt-1">{{ 'HOME.CONTACT.TITLE' | translate }}</h2>
    <h3 style="text-align: center; font-size: 1.2em;">{{ 'HOME.CONTACT.INFO' | translate }}</h3>
    <!-- <section style="width: 100%;" fxLayout="row" fxLayout.xs="column">
      <div fxFlex="50" fxLayoutAlign="center start">
        <p>HOME.CONTACT.INSTRUCTION</p>
      </div>
      <div fxFlex="50" fxLayoutAlign="center start">
        <p>HOME.CONTACT.INSTRUCTION</p>
      </div>
    </section> -->
    <button mat-raised-button type="button" style="margin: 1rem 0 1rem 0;" (click)="openContactDialog()" 
      class="green-btn">{{ 'HOME.CONTACT.WRITE-US' | translate }}</button>
  </section>
</div>