import { DomainMapper } from "./domain-mapper";
import { Currency, Price, Prices, Product } from "@model/products";
import { AuthUserDeviceResponse } from "../api/eshop/proto/generated/dynavix/AuthUserDeviceResponse_pb";
import { RefreshTokenResponse } from "../api/eshop/proto/generated/dynavix/RefreshToken_pb";
import { AuthTokens } from "@model/auth";
import { Token } from "src/app/basics/utils/accessContext";
import { AuthUserDevice as AuthUserDeviceProto } from "../api/eshop/proto/generated/dynavix/AuthUserDevice_pb"; 
import { AuthUserDevice } from "@model/auth"; 
import { DateMapper } from "./date-mapper";

export class AuthMapper implements DomainMapper<AuthTokens, AuthUserDeviceResponse | RefreshTokenResponse> {

  mapToDomain(data: AuthUserDeviceResponse | RefreshTokenResponse): AuthTokens {
      if (data === undefined || data === null) {
          return null;
      }
      const currentTime = new Date();
      let retVal = new AuthTokens();
      let dateMapper = new DateMapper();

      if (data instanceof AuthUserDeviceResponse) {
        retVal.accessToken = new Token<string>(data.getSessionid(), dateMapper.mapToDomain(data.getAccesstokenexpiry()));
        retVal.refreshToken = new Token<string>(data.getRefreshtoken(), dateMapper.mapToDomain(data.getRefreshtokenexpiry()));
      } else {
        retVal.accessToken = new Token<string>(data.getAccesstoken(),dateMapper.mapToDomain(data.getAccesstokenexpiry()));
        retVal.refreshToken = new Token<string>(data.getRefreshtoken(), dateMapper.mapToDomain(data.getRefreshtokenexpiry()));
      }
      console.log(retVal.accessToken.mExpiry);
      
      return retVal;
  }
  
  mapFromDomain(data: AuthTokens): AuthUserDeviceResponse {
      throw new Error("Method not implemented.");
  }
}

export class UserloginMapper implements DomainMapper<AuthUserDevice, AuthUserDeviceProto> {
  mapToDomain(data: AuthUserDeviceProto): AuthUserDevice {
    var x = new AuthUserDevice();
    x.password = data.getPassword();
    x.username = data.getUsername();
    x.platform = data.getPlatformtype();
    return x;
  }
  mapFromDomain(data: AuthUserDevice): AuthUserDeviceProto {    
      var x = new AuthUserDeviceProto();
      x.setUsername(data.username);
      x.setPassword(data.password);
      x.setPlatformtype(data.platform);
      return x;
  }
}