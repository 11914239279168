export class AccessContext{
  public accessToken: Token<string>
  public refreshToken: Token<string>
  public username: string
  public password: string

  constructor(
    accessToken: Token<string> | undefined, refreshToken: Token<string> | undefined,
    username: string | undefined, password: string | undefined) {
    this.accessToken = accessToken; 
    this.refreshToken = refreshToken;
    this.username = username;
    this.password = password;
  }
}
   
export class Token<T> {

  constructor(value: T, expiry: Date) {
    this.setValue(value, expiry)
  }

  public getValue() : T {
    return this.mValue;
  }
  public hasValue() : Boolean {
    return this.mValue !== undefined;
  }
  public isExpired() : Boolean {
    return new Date().getTime() > this.mExpiry.getTime();
  }
  public setValue(value:T, timestamp:Date) {
    this.mValue = value;
    this.mExpiry = timestamp;
  }
  public setValue2(value:T, durationInSec:number) {
    this.mValue = value;
    this.mExpiry = new Date(new Date().getTime() + durationInSec * 1000)
  }
  public forceExpire() {
    this.mExpiry = new Date(0);
  }

  mValue: T;
  mExpiry: Date;
}

