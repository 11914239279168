<h1 mat-dialog-title>{{ 'DIALOG.CONTACT.TITLE' | translate }}</h1>
<!-- <button *ngIf="isNotClosing" mat-button class="close" (click)="onDismiss()"><mat-icon>close</mat-icon></button> -->

<form mat-dialog-content [formGroup]="contactForm" (ngSubmit)="onSend()" id="contactForm">
  <div fxFlex="100" class="px-1">
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ 'DIALOG.CONTACT.FIRST-NAME' | translate }}</mat-label>
        <input matInput formControlName="firstName" required>
        <mat-error *ngIf="contactForm.controls.firstName.errors?.required">{{'DIALOG.CONTACT.FIELD-REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'DIALOG.CONTACT.LAST-NAME' | translate }}</mat-label>
        <input matInput formControlName="lastName" required>
        <mat-error *ngIf="contactForm.controls.lastName.errors?.required">{{'DIALOG.CONTACT.FIELD-REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'DIALOG.CONTACT.PHONE' | translate }}</mat-label>
        <input matInput formControlName="phone" required>
        <mat-error *ngIf="contactForm.controls.phone.errors?.required">{{'DIALOG.CONTACT.FIELD-REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'DIALOG.CONTACT.EMAIL' | translate }}</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="contactForm.controls.email.errors?.required">{{'DIALOG.CONTACT.FIELD-REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'DIALOG.CONTACT.COMPANY' | translate }}</mat-label>
        <input matInput formControlName="company">
        <mat-error *ngIf="contactForm.controls.company.errors?.required">{{'DIALOG.CONTACT.FIELD-REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'DIALOG.CONTACT.VEHICLES-QUANTITY' | translate }}</mat-label>
        <input matInput formControlName="vehiclesQuantity" required>
        <mat-error *ngIf="contactForm.controls.vehiclesQuantity.errors?.required">{{'DIALOG.CONTACT.FIELD-REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <re-captcha (resolved)="resolved($event)" siteKey="6LcdRa4iAAAAACu3z5iP4zvvQ_yZB2hP0chVj8hU"
                                required formControlName="recaptcha"></re-captcha>
  </div>
</form>

<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
    <!-- <button mat-raised-button  (click)="onDismiss()">No</button> -->
    <button mat-raised-button class="red-btn" (click)="onDismiss()">{{ 'BTN.CANCEL' | translate }}</button>
    <button mat-raised-button form="contactForm" class="green-btn">{{ 'DIALOG.CONTACT.SEND' | translate }}</button>
  </div>
</div>