import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

const URL = 'assets/languages-list.json';

@Component({
  selector: 'app-language-setting',
  templateUrl: './language-setting.component.html',
  styleUrls: ['./language-setting.component.scss']
})
export class LanguageSettingComponent implements OnInit {

  currentLang: string = '';
  currentLangString: string = '';
  currentLangFlag: string = '';

  languagesMenu = []

  menuReady: boolean = false;

  constructor(
    private httpClient: HttpClient,
    public translateOp: TranslateService,
  ) { }

  public languageUtils: any;

  async ngOnInit() {
    try {
      this.languageUtils = await lastValueFrom(this.httpClient.get(URL))
    } catch (err) {
      console.error(err)
    }
  }

  public changeLang(lang:string){ 
    this.translateOp.use(lang);  
    localStorage.setItem('dynavix-web-language', lang) 
  } 
}
