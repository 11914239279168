<h4 class="col-title">{{'FOOT.CONTACT' | translate}}</h4>
<!-- <div class="divider"></div>             -->
<p fxLayout="row" fxLayoutAlign="start center" class="mt-2"><span>Dynavix a.s.</span></p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-1"><span>Branická 69/66</span></p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-1"><span>147 00 {{ 'ABOUTUS.ADDRESS.CITY' | translate }} 4</span></p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-1"><span></span></p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-1"><span></span></p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-1"><span>+420 222 509 570</span></p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
    <span>
        <!-- {{'FOOT.CONTACT' | translate}}:  -->
        <a class="email" href="mailto:support@dynavix.cz">support@dynavix.cz</a></span>
</p>
<p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
    <span>
        <!-- B2B {{'FOOT.CONTACT' | translate}}:  -->
        <a class="email" href="mailto:sales@dynavix.cz">sales@dynavix.cz</a></span>
</p>