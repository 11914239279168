import { Injectable, NgZone, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { AuthUserDevice } from '@model/auth';
import { UserInfo, UserRole } from '@model/userInfo';
import { AuthUserDeviceResponse } from 'src/app/data/api/eshop/proto/generated/dynavix/AuthUserDeviceResponse_pb';
import { RefreshToken, RefreshTokenResponse } from 'src/app/data/api/eshop/proto/generated/dynavix/RefreshToken_pb';
import { GetWebUserInfo, GetWebUserInfoResponse } from 'src/app/data/api/eshop/proto/generated/dynavix/WebUserInfo_pb';
import { AuthMapper, UserloginMapper } from 'src/app/data/mappers/auth-mapper';
import { UserInfoMapper } from 'src/app/data/mappers/userInfo-mapper';
import { AuthOperations } from '@operations-basics/auth.operations';
import { AccessContext, Token } from '../utils/accessContext';
import { HttpManager } from '../utils/http-request-sender';

interface ServiceWithAccessContext {
  context: AccessContext;
  lastUsername: string;
  logOutCurrentUser();
  sendLoginRequest(): void;
  sendRefreshRequest(refreshToken: string): void;
  updateCurrentUser():  void;
}

abstract class AccessState {  
  constructor(protected authService: ServiceWithAccessContext) {}
  public abstract next(): AccessState;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements AuthOperations, ServiceWithAccessContext, OnInit {
  
  public context: AccessContext;
  private state: AccessState;
  private loginRequestData: AuthUserDevice;
  private usernameSubject: Subject<string>;
  private currentUserSubject: Subject<UserInfo>;
  private currentUser: UserInfo;
  public lastUsername: string = null;

  constructor(
    private zone: NgZone,
    private httpManager: HttpManager,
    private snackBar: MatSnackBar,
    private translationService: TranslateService,
    private router: Router,
  ) { 

    this.usernameSubject = new Subject();
    this.usernameSubject.next(null);
    this.currentUserSubject = new Subject();
    this.currentUserSubject.next(null);
    this.context =  new AccessContext(null, null , null, null );
    this.currentUser = new UserInfo();

    const savedAccessToken = localStorage.getItem("accessToken");
    const savedAccessTokenExpiry = localStorage.getItem("accessTokenExpiry");
  
    const savedRefreshToken = localStorage.getItem("refreshToken");
    const savedRefreshTokenExpiry = localStorage.getItem("refreshTokenExpiry");
    const savedRefreshTokenRecentlyUsed = localStorage.getItem("refreshTokenRecentlyUsed");
  
    if(savedAccessToken && savedAccessTokenExpiry && savedRefreshToken && savedRefreshTokenExpiry) {
      this.context.accessToken = new Token<string>(savedAccessToken, new Date(savedAccessTokenExpiry));
      this.context.refreshToken = new Token<string>(savedRefreshToken, new Date(savedRefreshTokenExpiry));
      this.state = new AuthService.HasAccessToken(this);
      //TO-DO only tmp
      this.currentUser.roles = [UserRole.ESHOP_DEVELOPER];
    } else {
      this.state = new AuthService.NoAccessToken(this);
    }
  
    // setInterval(() => this.runLoop(), 100);
  }

  ngOnInit(): void {
    // this.updateCurrentUser();
  }
  
  
  private runLoop() {
    if(!this.state) {
      this.state = new AuthService.NoAccessToken(this);
      return
    }
    this.zone.run(()=>{
      this.state = this.state.next()
    }, this);
  }
  
  public login(request: AuthUserDevice): void {
    this.context.username = request.username;
    this.context.password = request.password;
    this.loginRequestData = request;
    this.context.accessToken?.forceExpire();
    this.context.refreshToken?.forceExpire();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessTokenExpiry");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("refreshTokenExpiry");
    this.state = new AuthService.NoAccessToken(this);
  }

  public logout(): void {
    this.context.accessToken.forceExpire();
    this.context.refreshToken.forceExpire();
    this.usernameSubject.next(null);
    this.currentUserSubject.next(null);
    this.currentUser = null;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessTokenExpiry");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("refreshTokenExpiry");
    this.router.navigate(["log-in"]);
  }

  public getCurrentUser(): UserInfo {
    return this.currentUser;
  }

  public getCurrentUserAsObservable():  Observable<UserInfo> {
    return this.currentUserSubject.asObservable();
  }

  public getAccessToken(): Token<string> {
    return this.context.accessToken;
  }

  public sendLoginRequest(): void {
    const mapper = new UserloginMapper()
    let request = mapper.mapFromDomain(this.loginRequestData)

    this.httpManager.sendRequest(request, AuthUserDeviceResponse).subscribe( {
      next: (data: AuthUserDeviceResponse) => {
        const mapper = new AuthMapper()
        var retVal = mapper.mapToDomain(data)
        this.context.accessToken = retVal.accessToken
        this.context.refreshToken = retVal.refreshToken
        
        this.snackBar.open(this.translationService.instant("ACCOUNT.LOGIN-SUCCESS"), "", { duration: 3000, panelClass: ['green-snackbar']});
        this.router.navigate(['']);
        localStorage.setItem("accessToken", this.context.accessToken.getValue())
        localStorage.setItem("accessTokenExpiry", this.context.accessToken.mExpiry.toUTCString())
        localStorage.setItem("refreshToken", this.context.refreshToken.getValue())
        localStorage.setItem("refreshTokenExpiry", this.context.refreshToken.mExpiry.toUTCString())
        localStorage.setItem("refreshTokenRecentlyUsed", "false");
      },
      error: (err) => {
        this.snackBar.open(this.translationService.instant("ACCOUNT.LOGIN-FAILURE"), '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    })

    this.context.username = null;
    this.context.password = null;
    this.loginRequestData = null;
  }


  public sendRefreshRequest(): Observable<Token<string>> {
    console.log("sendin refresh request");
    
    const subject = new Subject<Token<string>>();    

    if(!this.context.refreshToken) {
      subject.error("user is not authenticated")
      return subject.asObservable()
    }
    let request = new RefreshToken();
    request.setRefreshtoken(this.context.refreshToken.getValue());
    localStorage.setItem("refreshTokenRecentlyUsed", "true");

    // TEMPORARY SOLUTION
    // this.context.accessToken = new Token<string>(this.context.accessToken.getValue(), new Date(new Date().getTime() + 500000))
    // setTimeout(()=> {
      // subject.next(this.context.accessToken);
      // subject.error(new HttpResponseError());
    // }, 200)

    // PRODUCTION SOLUTION - until RefreshToken request is made on server side
    this.httpManager.sendRequest(request, RefreshTokenResponse).subscribe( {
      next: (data: RefreshTokenResponse) => {
        const mapper = new AuthMapper()
        var retVal = mapper.mapToDomain(data)
        this.context.accessToken = retVal.accessToken
        this.context.refreshToken = retVal.refreshToken
        subject.next(retVal.accessToken)
        localStorage.setItem("accessToken", this.context.accessToken.getValue())
        localStorage.setItem("accessTokenExpiry", this.context.accessToken.mExpiry.toUTCString())
        localStorage.setItem("refreshToken", this.context.refreshToken.getValue())
        localStorage.setItem("refreshTokenExpiry", this.context.refreshToken.mExpiry.toUTCString())
        localStorage.setItem("refreshTokenRecentlyUsed", "false");
      },
      error: (err) => {
        subject.error(err)
      }
    })
    return subject.asObservable()

  }

  public logOutCurrentUser() {
    this.usernameSubject.next(null);
    this.currentUser = null;
    this.context.accessToken = null;
    this.context.refreshToken = null;
    localStorage.removeItem("accessToken")
    localStorage.removeItem("accessTokenExpiry")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("refreshTokenExpiry")
  }

  public forceExpireAT() {
    this.context.accessToken.forceExpire()
  }

  public updateCurrentUser() {
    this.httpManager.sendRequest(new GetWebUserInfo(), GetWebUserInfoResponse).subscribe({
      next: (data) => {
        const mapper = new UserInfoMapper();
        this.currentUser = mapper.mapToDomain(data);
        console.log(data);
        this.usernameSubject.next(data.toString());
        this.lastUsername = data.toString();
      },
      error: (err) => {
        console.log(err);
        this.usernameSubject.next("missing name");
        this.lastUsername = "missing name";
        let retVal = new UserInfo();
        retVal.showedname = 'missing showedname';
        retVal.username = 'missing username';
        retVal.roles = [UserRole.ESHOP_DEVELOPER];
        this.currentUserSubject.next(retVal);
        this.currentUser = retVal;
      }
    })
  }
  
  static NoAccessToken = class NoAccessToken extends AccessState {
  
    public next(): AccessState {
      if(this.authService.context.refreshToken && !this.authService.context.refreshToken.isExpired()) {
        this.authService.sendRefreshRequest(this.authService.context.refreshToken.getValue())
        return new AuthService.WaitingForAccessToken( this.authService);
      } 
      if(this.authService.context.username) {
        this.authService.sendLoginRequest()
        return new AuthService.WaitingForAccessToken( this.authService);
      } 
      return this;
    }
  }
  
  static WaitingForAccessToken = class WaitingForAccessToken extends AccessState {
  
    public next(): AccessState {
      if(!this.authService.context.accessToken || !this.authService.context.refreshToken) {
        return this;
      }
  
      if(!this.authService.context.accessToken.isExpired() && !this.authService.context.refreshToken.isExpired()) {
        return new AuthService.HasAccessToken(this.authService);
      }
      return this;
    }
  }
  
  static HasAccessToken = class HasAccessToken extends AccessState {

    private askedForUpdate = false;
  
    public next(): AccessState {
      if(this.authService.context.refreshToken.isExpired()) {
        this.authService.logOutCurrentUser();
      }
      if(this.authService.context.accessToken.isExpired()) {
        return new AuthService.NoAccessToken(this.authService);
      }
      if(this.authService.context.username) {
        this.authService.sendLoginRequest();
      }

      if(!this.authService.lastUsername && !this.askedForUpdate) {
        this.authService.updateCurrentUser();
        console.log("update that user");
        this.askedForUpdate = true;
      }

      return this
    }
  }

}

