import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FxLayoutInput } from 'src/app/data/common/layout';


@Component({
  selector: 'app-info-stripe',
  templateUrl: './info-stripe.component.html',
  styleUrls: ['./info-stripe.component.scss']
 
})

export class InfoStripeComponent implements OnInit {
  onButtonClick() {
  this.buttonClickEvent.emit()
  }
  @Input()
  imgUrl: string
  
  @Input()
  layout: FxLayoutInput = "row wrap"

  @Input()
  heading: string

  @Input()
  paragraph: string

  @Input()
  button: string
  @Input()
  link: string
  @Input()
  class: string

  @Output() 
  buttonClickEvent = new EventEmitter<string>();
 
  constructor() { }

  ngOnInit(): void {
    
  }
 
}
