import { Injectable } from "@angular/core";
import { AESOperations } from "@operations-basics/aes.operations";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AESService implements AESOperations {

  private _key: string = CryptoJS.enc.Utf8.parse("ec28816347a31e9de8dde252e6e93239")
  private _iv: string = CryptoJS.enc.Utf8.parse("a5b7bfa2c8177d55")

  public encryptUsingAES256(string2enc: string): string {
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(string2enc), 
      this._key, 
      {
        // keySize: 32,
        iv: this._iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }

  public decryptUsingAES256(string2dec: string): string {
    return CryptoJS.AES.decrypt(
      string2dec, this._key, {
        // keySize: 32,
        iv: this._iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
  }
  
}