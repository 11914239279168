import { Injectable } from '@angular/core';
import { HttpManager } from 'src/app/basics/utils/http-request-sender';
import { AboutUsOperations } from '@operations/about-us.operations';

// const SERVER_URL = 'https://account-europe-cz01.dynavix.com/AccountServer/x-protobuf/reply/'
const proxyServerURL = "https://api.fleet.dynavix.com/http-server-for-web/"
// const proxyServerURL = "http://localhost:8090/"

@Injectable({
  providedIn: 'root'
})
export class AboutUsService implements AboutUsOperations {

  constructor(
    private httpManager: HttpManager
  ) { }

  public async sendContactMessage(email: string, name: string, subj: string, message: string): Promise<void> {
    let retVal;
    try {
      retVal = await fetch(proxyServerURL + "contactus", {
        method: "POST",
        body: JSON.stringify({
          "relatedToFleet": 0,
          "msg": `
          Jméno: ${name}\n
          Email: ${email}\n 
          Předmět": ${subj}\n
          Zpráva": ${message}\n
          `
        }),
        headers: { "Content-type": "application/json" }
      });
      console.log(retVal);
    } catch (e) {
      console.error(e);
    }

    return retVal;
  }

  public async sendContactMessageAboutFleet(email: string, phone: string, firstName: string, lastName: string, company: string, numberVehicles: number): Promise<void> {
    let retVal;
    try {
      retVal = await fetch(proxyServerURL + "contactus", {
        method: "POST",
        body: JSON.stringify({
          "relatedToFleet": 1,
          "msg": `
          Jméno: ${firstName}\n
          Příjmení: ${lastName}\n
          Telefon: ${phone}\n 
          Email: ${email}\n 
          Firma: ${company}\n 
          Počet vozidel: ${numberVehicles}
          `
        }),
        headers: { "Content-type": "application/json" }
      });
      console.log(retVal);
    } catch (e) {
      console.error(e);
    }

    return retVal;
  }
}
