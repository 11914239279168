import { Product } from './products';
export class InternalUserDevice {
  purchasedProductVersions: InternalProductVersion[];
  activeMapProductCodes: string[];
  userDeviceId: number;
  username: string; //
  hardwareId: string; //
  platformType: PlatformType;  //
  deviceName: string; // device
  lastUsed: Date;
  usedPromoCodes: InternalPromoCodeUsage[];
  installedProductVersions: InternalInstalledProductVersion[];
  firebaseToken: string;
  enabled: boolean; //
  activated: boolean; // userdevice
  anonymous: boolean; //
  appVersionCode: number; //
  oSVersion: string; //
  resolution: string; //
  advertisignId: string;
  isBeta: boolean; // userDevice
  appId: string;
  projectType: string; // userDevice
  locale: string;
  userId: number;
  deviceId: number;
  userEnabled: boolean; //
  deviceEnabled: boolean; //
  email: string; //
  isHuaweiDevice: boolean; //
  huaweiToken: string;
  roles: string[];
  deviceABTestGroup: ABTestGroup; // device
  supportedArchs: string[]; //device
  currentArch: string; //device
  availableProducts: Product[];

  // featureTokens
}

export class InternalProductVersion {
  name: string;
  productVersionId: number;
  productName: string;
  eshopProductId: number;
  validFrom: Date;
  validTo: Date;
  transactionTime: Date;
  udi: number;
  udpId: number;
  udpvId: number;
  enabled: boolean;
  merged: boolean;
  udpEnabled: boolean;
  udpActivated: boolean;
  version: number;
  revision: number;
}

export class InternalInstalledProductVersion {
  bundles: InternalInstalledBundle[];
  productVersionId: number;
  name: string;
  version: number;
  lastUpdated: Date;
  showBundles: boolean = true;
}

export class InternalInstalledBundle {
  bundleId: number;
  name: string;
  version: number;
  revision: number;
}

export class InternalPromoCodeUsage {
  used: Date; //
  username: string; // 
  userDeviceId: number; 
  deviceId: number; 
  deviceName: string; //
  platformType: PlatformType; //
  userId: number; 
  hardwareId: string; //
  code: string; //
  products: EshopProduct[]; //
}

export class EshopProduct {
  eshopProductId: number;
  name: string; 
  sku: string; //
}


export enum PlatformType {
  NotSet = 'not set',
  Ios = 'IOS',
  Android = 'Android',
  WebBrowser = 'Web browser',
}

export enum ABTestGroup {
  ABTestGroupNotSet = 'ABTestGroupNotSet',
  A_Trial5Days = 'A_Trial5Days',
  B_Trial10Days = 'B_Trial10Days',
  C_Trial20Days = 'C_Trial20Days',
}

export class UpdatePurchasedProductVersionsRequest {
  validFrom: Date;
  validTo: Date;
  enabled: boolean;
}


// summary false true -> x v                DONE
// summary -- -> -                          DONE

// translate username, anonynimni, role     DONE

// same date in edit no iso                 DONE
// forevere date parse                      DONE
// edit for row no table                    DONE

// full width                               DONE

// zariyeni - userdevice                    DONE
// zpet na puvodni odkud proklik


//horstdumm12@gmail.com

// double refrehrequest                     DONE
// login stranka - bad heslo - hlaska       DONE
// enter = submit                           DONE


// auto email                               DONE
// submit enter                             DONE

// abt a project to device in details       DONE
// last used descending                     DONE

// device add username                      DONE
// vazba add username, hW id, platform type DONE
// user add hW id, platform type            DONE
// anonymni no false true                   DONE

// sync translation                         DONE
// fix multi call getWebUserInfo            DONE
// userdevice as first                      DONE
// click only button no row                 DONE
// link userDeviceID as in detail           DONE

//null and forever in default               DONE

// ZIP CODES only required                  DONE
// missing recaptcha                        

// detail translate 
// device - device name, osversion,
//  resolution, 
//                  lastused                DONE
// ISO date + z                  