<span [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger" class="lang font-awesome special-link"> 
  <!-- <img [src]="'assets/images/flags/'+translateService.currentLang+'.svg'" width="18" [alt]="translateService.currentLang">    -->
  <span class="flag-menu-title" class="search-button">{{ translateOp.currentLang }}</span>
  <!-- <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon> -->
</span>

<mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
  <span (mouseleave)="langMenuTrigger.closeMenu()"> 
      <button *ngFor="let lang of this.languageUtils" mat-menu-item (click)="changeLang(lang.code)">  
      <!-- <button *ngFor="let lang of translateService.getLangs()" mat-menu-item (click)="changeLang(lang)">   -->
          <img [src]="'assets/images/flags/'+lang.code+'.svg'" width="18" [alt]="lang" class="mat-elevation-z1"> 
          <span class="px-1">{{ lang.title }}</span>   
      </button> 
  </span>
</mat-menu>