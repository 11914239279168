import { Observable } from "rxjs";
import { AuthUserDevice } from "@model/auth";
import { UserInfo } from "@model/userInfo";
import { Token } from "../utils/accessContext";

export abstract class AuthOperations {

  public abstract login(request: AuthUserDevice): void
  public abstract logout(): void
  public abstract sendRefreshRequest(): Observable<Token<string>>
  public abstract getCurrentUserAsObservable(): Observable<UserInfo | undefined>
  public abstract getCurrentUser(): UserInfo | undefined
  public abstract getAccessToken(): Token<string>
  public abstract forceExpireAT(): void
}  