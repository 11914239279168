import { lastValueFrom } from 'rxjs';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { Address, BillingAddress } from '@model/order';
import { UserOperations } from '@operations/user.operations';
import { UserInfo } from '@model/userInfo';
import { WebRegistration } from '@model/web-sign-up';
import { ConfirmRegistrationDialogComponent } from '../confirm-registration-dialog/confirm-registration-dialog.component';
import { AuthOperations } from '@operations-basics/auth.operations';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, AfterViewInit {

  // @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;

  registrationForm: FormGroup;
  countries = [];
  del_address_wanted = false;
  public isSendingForm = false;

  public pswdMinLength = 4
  public pswdMaxLength = 255

  private goToCheckout: boolean = false;

  public lang = "cz";

  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private translationService: TranslateService,
    private router: Router,
    private userOp: UserOperations,
    private authOp: AuthOperations,
    private route: ActivatedRoute,
  ) { }

  ngAfterViewInit(): void {
    // if (localStorage.getItem('registration') === "true") {
    //   location.reload();
    //   localStorage.setItem('registration', "false");
    // } else {
    //   localStorage.setItem('registration', "true");
    // }

    // this.captcha.reset();
    // console.log("reseted");

  }

  ngOnInit() {
    this.goToCheckout = this.route.snapshot.queryParamMap.get('to-checkout') === "true";
    if (this.goToCheckout) {
      console.log('to-checkout true');
    }
    // this.captchaElem.reset();

    this.countries = this.appService.getCountries();

    this.registrationForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      showedName: '',
      password: ['', [
        Validators.required, 
        Validators.minLength(this.pswdMinLength), 
        Validators.maxLength(this.pswdMaxLength)]],
      confirmPassword: ['', Validators.required],

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      billingName: '',
      company: '',
      // email: ['', { validators: [Validators.required, Validators.email] }],
      phone: [''],
      country: [{
        name: "Česká republika",
        code: "CZ"
      }, Validators.required],
      city: ['', Validators.required],
      state: '',
      zip: ['', { validators: [Validators.required] }],
      address: ['', Validators.required],
      businessID: [''],
      taxID: [''],
      recaptcha: ['', Validators.required], //secret key 6LcdRa4iAAAAAPyUdh47wlH7NKq1Sd4xrp5NxkEN
      del_firstName: [''],
      del_lastName: [''],
      del_email: ['', { validators: [Validators.email] }],
      del_phone: [''],
      del_country: ['Česká republika', Validators.required],
      del_city: [''],
      del_state: '',
      del_zip: [''],
      del_address: [''],
    }, {
      validator: ComparePassword("password", "confirmPassword")
    });

    this.lang = this.translationService.currentLang
  }


  public onSubmitRegistrationForm() {

    if (this.registrationForm.valid) {
      var request = new WebRegistration();
      request.userInfo = new UserInfo();
      request.userInfo.username = this.registrationForm.get("username").value;
      request.userInfo.showedname = this.registrationForm.get("showedName").value;
      request.password = this.registrationForm.get("password").value;

      request.billingAddress = new BillingAddress();
      request.billingAddress.firstName = this.registrationForm.get("firstName").value;
      request.billingAddress.lastName = this.registrationForm.get("lastName").value;
      request.billingAddress.billingName = this.registrationForm.get("billingName").value;
      request.billingAddress.email = this.registrationForm.get("username").value;
      request.billingAddress.phone = this.registrationForm.get("phone").value;
      request.billingAddress.address = this.registrationForm.get("address").value;
      request.billingAddress.zip = this.registrationForm.get("zip").value;
      request.billingAddress.city = this.registrationForm.get("city").value;
      request.billingAddress.country = this.registrationForm.get("country").value.code;

      request.billingAddress.taxID = this.registrationForm.get("taxID").value;
      request.billingAddress.company = this.registrationForm.get("company").value;
      request.billingAddress.businessID = this.registrationForm.get("businessID").value;

      if (this.del_address_wanted) {
        request.deliveryAddress = new Address();
        request.deliveryAddress.firstName = this.registrationForm.get("del_firstName").value;
        request.deliveryAddress.lastName = this.registrationForm.get("del_lastName").value;
        request.deliveryAddress.email = this.registrationForm.get("del_email").value;
        request.deliveryAddress.phone = this.registrationForm.get("del_phone").value;
        request.deliveryAddress.address = this.registrationForm.get("del_address").value;
        request.deliveryAddress.zip = this.registrationForm.get("del_zip").value;
        request.deliveryAddress.city = this.registrationForm.get("del_city").value;
        request.deliveryAddress.country = this.registrationForm.get("del_country").value;
      }

      this.isSendingForm = true;
      this.userOp.registerNewUser(request).then((response) => {
        this.isSendingForm = false;
        if(response["code"] == 409) {
          alert(this.translationService.instant('ACCOUNT.REGISTRATION.FAILURE-USER-EXISTS'))
          return
        }

        if( response["code"] != 200 ) {
          alert(this.translationService.instant('ACCOUNT.REGISTRATION.FAILURE'));
          return;
        }

        const dialogRef = this.dialog.open(ConfirmRegistrationDialogComponent, {
          maxWidth: "400px",
          data: {
            email: request.userInfo.username
          }
        });
        lastValueFrom(dialogRef.afterClosed())
          .then(() => {
            this.router.navigate(["/log-in"]);
          });
      }).catch((err) => {
        this.isSendingForm = false;
        console.error("caught erorr: ", err);
        
        alert(this.translationService.instant('ACCOUNT.REGISTRATION.FAILURE-SERVER'));
      })
    } else {
      console.log("form validation failed");
    }

  }

  public addDeliveryAddress() {
    this.del_address_wanted = true;
  }

  public removeDeliveryAddress() {
    this.del_address_wanted = false;
  }

  public copyAddress() {
    this.registrationForm.patchValue({
      del_firstName: this.registrationForm.get("firstName").value,
      del_lastName: this.registrationForm.get("lastName").value,
      del_email: this.registrationForm.get("email").value,
      del_phone: this.registrationForm.get("phone").value,
      del_country: this.registrationForm.get("country").value,
      del_city: this.registrationForm.get("city").value,
      del_state: this.registrationForm.get("state").value,
      del_zip: this.registrationForm.get("zip").value,
      del_address: this.registrationForm.get("address").value,
    })
  }

  public compareObjects(o1: any, o2: any): boolean {
    return o1.code === o2.code;
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.registrationForm.patchValue({ "recaptcha": true })
  }

}

export function ComparePassword(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}