<h1 mat-dialog-title>{{data?.title}}</h1>
<!-- 
<div mat-dialog-content>
  <p>{{data?.message}}</p>
</div> -->

<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
    <button mat-raised-button color="warn" (click)="onContinueShopping()">
      {{'SHOPPING-CART.CONTINUE-SHOPPING' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="onNavigateToCart()">
      {{'SHOPPING-CART.VIEW-CART' | translate}}
    </button>
  </div>
</div>