// source: dynavix/ProductInfoCommon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.ProductServerDto.InstalledBundle', null, global);
goog.exportSymbol('proto.ProductServerDto.Product', null, global);
goog.exportSymbol('proto.ProductServerDto.ProductCategory', null, global);
goog.exportSymbol('proto.ProductServerDto.ProductFileBundleInfo', null, global);
goog.exportSymbol('proto.ProductServerDto.ProductFileInfo', null, global);
goog.exportSymbol('proto.ProductServerDto.ProductType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProductServerDto.Product, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.Product.displayName = 'proto.ProductServerDto.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.ProductFileBundleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProductServerDto.ProductFileBundleInfo.repeatedFields_, null);
};
goog.inherits(proto.ProductServerDto.ProductFileBundleInfo, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.ProductFileBundleInfo.displayName = 'proto.ProductServerDto.ProductFileBundleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.ProductFileInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProductServerDto.ProductFileInfo, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.ProductFileInfo.displayName = 'proto.ProductServerDto.ProductFileInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.InstalledBundle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProductServerDto.InstalledBundle, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.InstalledBundle.displayName = 'proto.ProductServerDto.InstalledBundle';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    localizedname: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    localizedshortdesc: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    localizedlongdesc: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    price: jspb.Message.getFieldWithDefault(msg, 4, 0),
    discountprice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    producttype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    productid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    producteshopid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    productsku: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    iconresource: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    imageurls: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    productcategory: jspb.Message.getFieldWithDefault(msg, 12, 0),
    currency: (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
    formattedprice: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    formatteddiscountprice: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    visibleafterpurchase: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    hiddenfordownload: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    canbepurchased: jspb.Message.getBooleanFieldWithDefault(msg, 19, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.Product}
 */
proto.ProductServerDto.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.Product;
  return proto.ProductServerDto.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.Product}
 */
proto.ProductServerDto.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalizedname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalizedshortdesc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalizedlongdesc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountprice(value);
      break;
    case 6:
      var value = /** @type {!proto.ProductServerDto.ProductType} */ (reader.readEnum());
      msg.setProducttype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProducteshopid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductsku(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconresource(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurls(value);
      break;
    case 12:
      var value = /** @type {!proto.ProductServerDto.ProductCategory} */ (reader.readEnum());
      msg.setProductcategory(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedprice(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormatteddiscountprice(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleafterpurchase(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHiddenfordownload(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanbepurchased(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {!proto.ProductServerDto.ProductType} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {!proto.ProductServerDto.ProductCategory} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeBool(
      16,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeBool(
      17,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeBool(
      19,
      f
    );
  }
};


/**
 * optional string LocalizedName = 1;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getLocalizedname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setLocalizedname = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearLocalizedname = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasLocalizedname = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string LocalizedShortDesc = 2;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getLocalizedshortdesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setLocalizedshortdesc = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearLocalizedshortdesc = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasLocalizedshortdesc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string LocalizedLongDesc = 3;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getLocalizedlongdesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setLocalizedlongdesc = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearLocalizedlongdesc = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasLocalizedlongdesc = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 Price = 4;
 * @return {number}
 */
proto.ProductServerDto.Product.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setPrice = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearPrice = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 DiscountPrice = 5;
 * @return {number}
 */
proto.ProductServerDto.Product.prototype.getDiscountprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setDiscountprice = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearDiscountprice = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasDiscountprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ProductType ProductType = 6;
 * @return {!proto.ProductServerDto.ProductType}
 */
proto.ProductServerDto.Product.prototype.getProducttype = function() {
  return /** @type {!proto.ProductServerDto.ProductType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.ProductServerDto.ProductType} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setProducttype = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearProducttype = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasProducttype = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 ProductId = 7;
 * @return {number}
 */
proto.ProductServerDto.Product.prototype.getProductid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setProductid = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearProductid = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasProductid = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 ProductEshopId = 8;
 * @return {number}
 */
proto.ProductServerDto.Product.prototype.getProducteshopid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setProducteshopid = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearProducteshopid = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasProducteshopid = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string ProductSku = 9;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getProductsku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setProductsku = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearProductsku = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasProductsku = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string IconResource = 10;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getIconresource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setIconresource = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearIconresource = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasIconresource = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string ImageUrls = 11;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getImageurls = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setImageurls = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearImageurls = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasImageurls = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ProductCategory ProductCategory = 12;
 * @return {!proto.ProductServerDto.ProductCategory}
 */
proto.ProductServerDto.Product.prototype.getProductcategory = function() {
  return /** @type {!proto.ProductServerDto.ProductCategory} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.ProductServerDto.ProductCategory} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setProductcategory = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearProductcategory = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasProductcategory = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string Currency = 13;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setCurrency = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearCurrency = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string FormattedPrice = 14;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getFormattedprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setFormattedprice = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearFormattedprice = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasFormattedprice = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string FormattedDiscountPrice = 15;
 * @return {string}
 */
proto.ProductServerDto.Product.prototype.getFormatteddiscountprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setFormatteddiscountprice = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearFormatteddiscountprice = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasFormatteddiscountprice = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool VisibleAfterPurchase = 16;
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.getVisibleafterpurchase = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setVisibleafterpurchase = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearVisibleafterpurchase = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasVisibleafterpurchase = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool HiddenForDownload = 17;
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.getHiddenfordownload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setHiddenfordownload = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearHiddenfordownload = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasHiddenfordownload = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool CanBePurchased = 19;
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.getCanbepurchased = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.setCanbepurchased = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.Product} returns this
 */
proto.ProductServerDto.Product.prototype.clearCanbepurchased = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.Product.prototype.hasCanbepurchased = function() {
  return jspb.Message.getField(this, 19) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProductServerDto.ProductFileBundleInfo.repeatedFields_ = [9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.ProductFileBundleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.ProductFileBundleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.ProductFileBundleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    productfilebundleid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    localizedname: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    localizeddesc: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    revision: jspb.Message.getFieldWithDefault(msg, 5, 0),
    compressedsize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    uncompressedsize: jspb.Message.getFieldWithDefault(msg, 7, 0),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    productfilesList: jspb.Message.toObjectList(msg.getProductfilesList(),
    proto.ProductServerDto.ProductFileInfo.toObject, includeInstance),
    iconresource: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    previousproductfilebundleidsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    label: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    sublabel: (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
    productversionid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    downloadurlbase: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    installationpathbase: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo}
 */
proto.ProductServerDto.ProductFileBundleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.ProductFileBundleInfo;
  return proto.ProductServerDto.ProductFileBundleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.ProductFileBundleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo}
 */
proto.ProductServerDto.ProductFileBundleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductfilebundleid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalizedname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalizeddesc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRevision(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompressedsize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUncompressedsize(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 9:
      var value = new proto.ProductServerDto.ProductFileInfo;
      reader.readMessage(value,proto.ProductServerDto.ProductFileInfo.deserializeBinaryFromReader);
      msg.addProductfiles(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconresource(value);
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPreviousproductfilebundleids(values[i]);
      }
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSublabel(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductversionid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadurlbase(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstallationpathbase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.ProductFileBundleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.ProductFileBundleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.ProductFileBundleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getProductfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.ProductServerDto.ProductFileInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPreviousproductfilebundleidsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional int64 ProductFileBundleId = 1;
 * @return {number}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getProductfilebundleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setProductfilebundleid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearProductfilebundleid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasProductfilebundleid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string LocalizedName = 2;
 * @return {string}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getLocalizedname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setLocalizedname = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearLocalizedname = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasLocalizedname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string LocalizedDesc = 3;
 * @return {string}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getLocalizeddesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setLocalizeddesc = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearLocalizeddesc = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasLocalizeddesc = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 Version = 4;
 * @return {number}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 Revision = 5;
 * @return {number}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setRevision = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearRevision = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasRevision = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 CompressedSize = 6;
 * @return {number}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getCompressedsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setCompressedsize = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearCompressedsize = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasCompressedsize = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 UncompressedSize = 7;
 * @return {number}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getUncompressedsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setUncompressedsize = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearUncompressedsize = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasUncompressedsize = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool Required = 8;
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setRequired = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearRequired = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasRequired = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated ProductFileInfo ProductFiles = 9;
 * @return {!Array<!proto.ProductServerDto.ProductFileInfo>}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getProductfilesList = function() {
  return /** @type{!Array<!proto.ProductServerDto.ProductFileInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProductServerDto.ProductFileInfo, 9));
};


/**
 * @param {!Array<!proto.ProductServerDto.ProductFileInfo>} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
*/
proto.ProductServerDto.ProductFileBundleInfo.prototype.setProductfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.ProductServerDto.ProductFileInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.ProductFileInfo}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.addProductfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.ProductServerDto.ProductFileInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearProductfilesList = function() {
  return this.setProductfilesList([]);
};


/**
 * optional string IconResource = 10;
 * @return {string}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getIconresource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setIconresource = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearIconresource = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasIconresource = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated int64 PreviousProductFileBundleIds = 11;
 * @return {!Array<number>}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getPreviousproductfilebundleidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setPreviousproductfilebundleidsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.addPreviousproductfilebundleids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearPreviousproductfilebundleidsList = function() {
  return this.setPreviousproductfilebundleidsList([]);
};


/**
 * optional string Label = 12;
 * @return {string}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string SubLabel = 13;
 * @return {string}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getSublabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setSublabel = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearSublabel = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasSublabel = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int64 ProductVersionId = 14;
 * @return {number}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getProductversionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setProductversionid = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearProductversionid = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasProductversionid = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string DownloadUrlBase = 15;
 * @return {string}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getDownloadurlbase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setDownloadurlbase = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearDownloadurlbase = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasDownloadurlbase = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string InstallationPathBase = 16;
 * @return {string}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.getInstallationpathbase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.setInstallationpathbase = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileBundleInfo} returns this
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.clearInstallationpathbase = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileBundleInfo.prototype.hasInstallationpathbase = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.ProductFileInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.ProductFileInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.ProductFileInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.ProductFileInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    productfileid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    md5hash: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    installationpath: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    iscompressed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    version: jspb.Message.getFieldWithDefault(msg, 5, 0),
    revision: jspb.Message.getFieldWithDefault(msg, 6, 0),
    compressedsize: jspb.Message.getFieldWithDefault(msg, 7, 0),
    uncompressedsize: jspb.Message.getFieldWithDefault(msg, 8, 0),
    filename: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    isextension: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    downloadurl: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.ProductFileInfo}
 */
proto.ProductServerDto.ProductFileInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.ProductFileInfo;
  return proto.ProductServerDto.ProductFileInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.ProductFileInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.ProductFileInfo}
 */
proto.ProductServerDto.ProductFileInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductfileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMd5hash(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstallationpath(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscompressed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRevision(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompressedsize(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUncompressedsize(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsextension(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.ProductFileInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.ProductFileInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.ProductFileInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.ProductFileInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int64 ProductFileId = 1;
 * @return {number}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getProductfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setProductfileid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearProductfileid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasProductfileid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Md5Hash = 2;
 * @return {string}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getMd5hash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setMd5hash = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearMd5hash = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasMd5hash = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string InstallationPath = 3;
 * @return {string}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getInstallationpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setInstallationpath = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearInstallationpath = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasInstallationpath = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool IsCompressed = 4;
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getIscompressed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setIscompressed = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearIscompressed = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasIscompressed = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 Version = 5;
 * @return {number}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 Revision = 6;
 * @return {number}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setRevision = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearRevision = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasRevision = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 CompressedSize = 7;
 * @return {number}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getCompressedsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setCompressedsize = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearCompressedsize = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasCompressedsize = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 UncompressedSize = 8;
 * @return {number}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getUncompressedsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setUncompressedsize = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearUncompressedsize = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasUncompressedsize = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string FileName = 9;
 * @return {string}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setFilename = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearFilename = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasFilename = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool IsExtension = 11;
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getIsextension = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setIsextension = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearIsextension = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasIsextension = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string DownloadUrl = 12;
 * @return {string}
 */
proto.ProductServerDto.ProductFileInfo.prototype.getDownloadurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.setDownloadurl = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.ProductFileInfo} returns this
 */
proto.ProductServerDto.ProductFileInfo.prototype.clearDownloadurl = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.ProductFileInfo.prototype.hasDownloadurl = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.InstalledBundle.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.InstalledBundle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.InstalledBundle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InstalledBundle.toObject = function(includeInstance, msg) {
  var f, obj = {
    bundleid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bundleversion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bundlerevision: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.InstalledBundle}
 */
proto.ProductServerDto.InstalledBundle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.InstalledBundle;
  return proto.ProductServerDto.InstalledBundle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.InstalledBundle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.InstalledBundle}
 */
proto.ProductServerDto.InstalledBundle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBundleid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBundleversion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBundlerevision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.InstalledBundle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.InstalledBundle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.InstalledBundle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InstalledBundle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 BundleId = 1;
 * @return {number}
 */
proto.ProductServerDto.InstalledBundle.prototype.getBundleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InstalledBundle} returns this
 */
proto.ProductServerDto.InstalledBundle.prototype.setBundleid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InstalledBundle} returns this
 */
proto.ProductServerDto.InstalledBundle.prototype.clearBundleid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InstalledBundle.prototype.hasBundleid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 BundleVersion = 2;
 * @return {number}
 */
proto.ProductServerDto.InstalledBundle.prototype.getBundleversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InstalledBundle} returns this
 */
proto.ProductServerDto.InstalledBundle.prototype.setBundleversion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InstalledBundle} returns this
 */
proto.ProductServerDto.InstalledBundle.prototype.clearBundleversion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InstalledBundle.prototype.hasBundleversion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 BundleRevision = 3;
 * @return {number}
 */
proto.ProductServerDto.InstalledBundle.prototype.getBundlerevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InstalledBundle} returns this
 */
proto.ProductServerDto.InstalledBundle.prototype.setBundlerevision = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InstalledBundle} returns this
 */
proto.ProductServerDto.InstalledBundle.prototype.clearBundlerevision = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InstalledBundle.prototype.hasBundlerevision = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.ProductServerDto.ProductType = {
  NOTSET: 0,
  MAP: 1,
  DOWNLOADABLE: 2,
  SUBSCRIPTION: 3,
  UPGRADESUBSCRIPTION: 4,
  PREMIUM: 5
};

/**
 * @enum {number}
 */
proto.ProductServerDto.ProductCategory = {
  CATEGORYNOTSET: 0,
  CATEGORYMAP: 1,
  CATEGORYSOUND: 2,
  CATEGORYFEATURE: 3,
  CATEGORYTRAFFIC: 4,
  CATEGORYPACK: 5
};

goog.object.extend(exports, proto.ProductServerDto);
