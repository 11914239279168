import { DomainMapper } from "./domain-mapper";
import { Order as OrderProto } from "../api/eshop/proto/generated/dynavix/eshop/EshopOrder_pb";
import { Order } from "@model/order";

export class OrderMapper implements DomainMapper<Order, OrderProto> {

  constructor(
  ) {

  }

  mapToDomain(data: OrderProto): Order {
      if (data === undefined || data === null) {
          return null
      }

      return new Order()
  }
  
  mapFromDomain(data: Order): OrderProto {
      throw new Error("Method not implemented.");
  }
}