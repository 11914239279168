import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsDialogComponent } from 'src/app/shared/contact-us-dialog/contact-us-dialog.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  
  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  public openContactDialog(){  
    const dialogRef = this.dialog.open(ContactUsDialogComponent, {
      maxWidth: "400px",
      panelClass: "contact-us-dialog"
    }); 
    dialogRef.backdropClick().subscribe(() => { dialogRef.componentInstance.isNotClosing = true; });
    dialogRef.afterClosed().subscribe(dialogResult => { 
    }); 
  }
}
