<div class="bg-primary footer">
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">
        <app-services-products-column fxFlex="100" fxFlex.gt-sm="48" fxFlex.sm="80" class="contact-information first-col" ngClass.xs="first-col-xs">
        </app-services-products-column>
        <app-usefull-links-column fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="80" class="first-col" ngClass.xs="other-col-xs"></app-usefull-links-column>
        <app-contact-column fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="80" class="first-col" ngClass.xs="other-col-xs"></app-contact-column>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" class="copyright secondary-color">
        <p class="theme-container" ngClass.xs="mt-1 copyright-text">© Copyright {{year}} Dynavix a.s. / All rights reserved</p>
    </div>
</div>
<!-- <footer class="bg-primary footer">
    <div *ngIf="isLangCZ" fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">  
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="80" class="first-col"> 
            <h3 class="col-title">{{'FOOT.SUPPORT' | translate | uppercase}}</h3>
            <div class="divider"></div>
            <p class="mt-2"><a href="support/support-for-android" class="link secondary-color">{{'FOOT.DYNAVIX_FOR_ANDROID' | translate}}</a></p>
            <p class="mt-1"><a href="support/support-for-iphone" class="link secondary-color">{{'FOOT.DYNAVIX_FOR_IPHONE' | translate}}</a></p>
            <p class="mt-1"><a href="support/support-for-pnd" class="link secondary-color">{{'FOOT.DYNAVIX_FOR_PND' | translate}}</a></p>
            <p class="mt-1"><a href="support/manuals" class="link secondary-color">{{'FOOT.MANUALS' | translate}}</a></p>
            <p class="mt-1"><a href="support/repair" class="link secondary-color">{{'FOOT.POST-WARRANTY_REPAIR' | translate}}</a></p>
            <p class="mt-1"><a href="about-us" class="link secondary-color">{{'FOOT.WRITE_US' | translate}}</a></p>
            <p class="mt-1"><a href="e-shop-requirement" class="link secondary-color">{{'FOOT.TERMS_AND_CONDITIONS' | translate}}</a></p>
            <p class="mt-1"><a href="privacy-policy" class="link secondary-color">{{'FOOT.PROTECTION_OF_PERSONAL_DATA' | translate}}</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="80" ngClass.xs="first-col-xs"> 
            <h3 class="col-title">{{'FOOT.MY_ACCOUNT' | translate}}</h3>
            <div class="divider"></div>
            <p class="mt-2"><a href="javascript:void(0);" class="link secondary-color" href="log-in">{{ 'ACCOUNT.LOGIN' | translate }}</a></p>
            <p class="mt-1"><a href="sign-up" class="link secondary-color">{{ 'ACCOUNT.REGISTRATION.TITLE' | translate }}</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="80" class="contact-information" ngClass.xs="first-col-xs"> 
            <h3 class="col-title">{{'FOOT.CONTACT' | translate}}</h3>
            <div class="divider"></div>            
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <span>Dynavix a.s.</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>Branická 69/66, 147 00 Praha 4</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>+420 222 509 570</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>{{'FOOT.CONTACT' | translate}}: <a class="email" href="mailto:support@dynavix.cz">support@dynavix.cz</a></span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>B2B {{'FOOT.CONTACT' | translate}}: <a class="email" href="mailto:sales@dynavix.cz">sales@dynavix.cz</a></span>
            </p>
        </div>
    </div>
    <div *ngIf="!isLangCZ" fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">  
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="80" class="contact-information first-col" ngClass.xs="first-col-xs"> 
            <h3 class="col-title">{{'FOOT.CONTACT' | translate}}</h3>
            <div class="divider"></div>            
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <span>Dynavix a.s.</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>Branická 69/66, 147 00 Praha 4</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>Czech Republic (EU)</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>+420 222 509 570</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>{{'FOOT.CONTACT' | translate}}: <a class="email" href="mailto:support@dynavix.cz">support@dynavix.cz</a></span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <span>B2B {{'FOOT.CONTACT' | translate}}: <a class="email" href="mailto:sales@dynavix.cz">sales@dynavix.cz</a></span>
            </p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="80" class="second-col"> 
            <h3 class="col-title">{{'FOOT.SUPPORT' | translate | uppercase}}</h3>
            <div class="divider"></div>
            <p class="mt-1"><a href="support/manuals" class="link secondary-color">{{'FOOT.MANUALS' | translate}}</a></p>
        </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color">
        <div fxFlex="19.2"></div>
        <p fxFlex="86.5" ngClass.xs="mt-1 copyright-text">© Copyright 2022 Dynavix a.s. / All rights reserved</p>
    </div>
</footer> -->