// source: dynavix/AuthUserDeviceResponse.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var dynavix_ResponseStatus_pb = require('../dynavix/ResponseStatus_pb.js');
goog.object.extend(proto, dynavix_ResponseStatus_pb);
var dynavix_bcl_pb = require('../dynavix/bcl_pb.js');
goog.object.extend(proto, dynavix_bcl_pb);
goog.exportSymbol('proto.AccountServerDto.ActiveUserDevice', null, global);
goog.exportSymbol('proto.AccountServerDto.AuthUserDeviceResponse', null, global);
goog.exportSymbol('proto.AccountServerDto.DeactivatedUserDevice', null, global);
goog.exportSymbol('proto.AccountServerDto.UserDeviceRegistrationStatusType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AccountServerDto.AuthUserDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AccountServerDto.AuthUserDeviceResponse.repeatedFields_, null);
};
goog.inherits(proto.AccountServerDto.AuthUserDeviceResponse, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.AccountServerDto.AuthUserDeviceResponse.displayName = 'proto.AccountServerDto.AuthUserDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AccountServerDto.DeactivatedUserDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AccountServerDto.DeactivatedUserDevice, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.AccountServerDto.DeactivatedUserDevice.displayName = 'proto.AccountServerDto.DeactivatedUserDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AccountServerDto.ActiveUserDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AccountServerDto.ActiveUserDevice, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.AccountServerDto.ActiveUserDevice.displayName = 'proto.AccountServerDto.ActiveUserDevice';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AccountServerDto.AuthUserDeviceResponse.repeatedFields_ = [5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AccountServerDto.AuthUserDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AccountServerDto.AuthUserDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AccountServerDto.AuthUserDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsestatus: (f = msg.getResponsestatus()) && dynavix_ResponseStatus_pb.ResponseStatus.toObject(includeInstance, f),
    sessionid: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    sessionexpiryseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    anonymous: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    deactivateduserdevicesList: jspb.Message.toObjectList(msg.getDeactivateduserdevicesList(),
    proto.AccountServerDto.DeactivatedUserDevice.toObject, includeInstance),
    registrationstatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    activeuserdevicesList: jspb.Message.toObjectList(msg.getActiveuserdevicesList(),
    proto.AccountServerDto.ActiveUserDevice.toObject, includeInstance),
    maxactivedevices: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isappupdateavailable: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isremotelogenabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    accesstokenexpiry: (f = msg.getAccesstokenexpiry()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    refreshtoken: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    refreshtokenexpiry: (f = msg.getRefreshtokenexpiry()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse}
 */
proto.AccountServerDto.AuthUserDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AccountServerDto.AuthUserDeviceResponse;
  return proto.AccountServerDto.AuthUserDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AccountServerDto.AuthUserDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse}
 */
proto.AccountServerDto.AuthUserDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dynavix_ResponseStatus_pb.ResponseStatus;
      reader.readMessage(value,dynavix_ResponseStatus_pb.ResponseStatus.deserializeBinaryFromReader);
      msg.setResponsestatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSessionexpiryseconds(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnonymous(value);
      break;
    case 5:
      var value = new proto.AccountServerDto.DeactivatedUserDevice;
      reader.readMessage(value,proto.AccountServerDto.DeactivatedUserDevice.deserializeBinaryFromReader);
      msg.addDeactivateduserdevices(value);
      break;
    case 6:
      var value = /** @type {!proto.AccountServerDto.UserDeviceRegistrationStatusType} */ (reader.readEnum());
      msg.setRegistrationstatus(value);
      break;
    case 7:
      var value = new proto.AccountServerDto.ActiveUserDevice;
      reader.readMessage(value,proto.AccountServerDto.ActiveUserDevice.deserializeBinaryFromReader);
      msg.addActiveuserdevices(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxactivedevices(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsappupdateavailable(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsremotelogenabled(value);
      break;
    case 11:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setAccesstokenexpiry(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtoken(value);
      break;
    case 13:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setRefreshtokenexpiry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AccountServerDto.AuthUserDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AccountServerDto.AuthUserDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AccountServerDto.AuthUserDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsestatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dynavix_ResponseStatus_pb.ResponseStatus.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDeactivateduserdevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.AccountServerDto.DeactivatedUserDevice.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.AccountServerDto.UserDeviceRegistrationStatusType} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getActiveuserdevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.AccountServerDto.ActiveUserDevice.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAccesstokenexpiry();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRefreshtokenexpiry();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseStatus ResponseStatus = 1;
 * @return {?proto.ResponseStatus}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getResponsestatus = function() {
  return /** @type{?proto.ResponseStatus} */ (
    jspb.Message.getWrapperField(this, dynavix_ResponseStatus_pb.ResponseStatus, 1));
};


/**
 * @param {?proto.ResponseStatus|undefined} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
*/
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setResponsestatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearResponsestatus = function() {
  return this.setResponsestatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasResponsestatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string SessionId = 2;
 * @return {string}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setSessionid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearSessionid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasSessionid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 SessionExpirySeconds = 3;
 * @return {number}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getSessionexpiryseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setSessionexpiryseconds = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearSessionexpiryseconds = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasSessionexpiryseconds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool Anonymous = 4;
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getAnonymous = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setAnonymous = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearAnonymous = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasAnonymous = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated DeactivatedUserDevice DeactivatedUserDevices = 5;
 * @return {!Array<!proto.AccountServerDto.DeactivatedUserDevice>}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getDeactivateduserdevicesList = function() {
  return /** @type{!Array<!proto.AccountServerDto.DeactivatedUserDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AccountServerDto.DeactivatedUserDevice, 5));
};


/**
 * @param {!Array<!proto.AccountServerDto.DeactivatedUserDevice>} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
*/
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setDeactivateduserdevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.AccountServerDto.DeactivatedUserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AccountServerDto.DeactivatedUserDevice}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.addDeactivateduserdevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.AccountServerDto.DeactivatedUserDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearDeactivateduserdevicesList = function() {
  return this.setDeactivateduserdevicesList([]);
};


/**
 * optional UserDeviceRegistrationStatusType RegistrationStatus = 6;
 * @return {!proto.AccountServerDto.UserDeviceRegistrationStatusType}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getRegistrationstatus = function() {
  return /** @type {!proto.AccountServerDto.UserDeviceRegistrationStatusType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.AccountServerDto.UserDeviceRegistrationStatusType} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setRegistrationstatus = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearRegistrationstatus = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasRegistrationstatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ActiveUserDevice ActiveUserDevices = 7;
 * @return {!Array<!proto.AccountServerDto.ActiveUserDevice>}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getActiveuserdevicesList = function() {
  return /** @type{!Array<!proto.AccountServerDto.ActiveUserDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AccountServerDto.ActiveUserDevice, 7));
};


/**
 * @param {!Array<!proto.AccountServerDto.ActiveUserDevice>} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
*/
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setActiveuserdevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.AccountServerDto.ActiveUserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AccountServerDto.ActiveUserDevice}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.addActiveuserdevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.AccountServerDto.ActiveUserDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearActiveuserdevicesList = function() {
  return this.setActiveuserdevicesList([]);
};


/**
 * optional int32 MaxActiveDevices = 8;
 * @return {number}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getMaxactivedevices = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setMaxactivedevices = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearMaxactivedevices = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasMaxactivedevices = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool IsAppUpdateAvailable = 9;
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getIsappupdateavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setIsappupdateavailable = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearIsappupdateavailable = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasIsappupdateavailable = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool IsRemoteLogEnabled = 10;
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getIsremotelogenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setIsremotelogenabled = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearIsremotelogenabled = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasIsremotelogenabled = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bcl.DateTime AccessTokenExpiry = 11;
 * @return {?proto.bcl.DateTime}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getAccesstokenexpiry = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 11));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
*/
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setAccesstokenexpiry = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearAccesstokenexpiry = function() {
  return this.setAccesstokenexpiry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasAccesstokenexpiry = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string RefreshToken = 12;
 * @return {string}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getRefreshtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearRefreshtoken = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasRefreshtoken = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bcl.DateTime RefreshTokenExpiry = 13;
 * @return {?proto.bcl.DateTime}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.getRefreshtokenexpiry = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 13));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
*/
proto.AccountServerDto.AuthUserDeviceResponse.prototype.setRefreshtokenexpiry = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountServerDto.AuthUserDeviceResponse} returns this
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.clearRefreshtokenexpiry = function() {
  return this.setRefreshtokenexpiry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.AuthUserDeviceResponse.prototype.hasRefreshtokenexpiry = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.AccountServerDto.DeactivatedUserDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AccountServerDto.DeactivatedUserDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AccountServerDto.DeactivatedUserDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicename: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lastused: (f = msg.getLastused()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    associatedusername: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AccountServerDto.DeactivatedUserDevice}
 */
proto.AccountServerDto.DeactivatedUserDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AccountServerDto.DeactivatedUserDevice;
  return proto.AccountServerDto.DeactivatedUserDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AccountServerDto.DeactivatedUserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AccountServerDto.DeactivatedUserDevice}
 */
proto.AccountServerDto.DeactivatedUserDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicename(value);
      break;
    case 2:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setLastused(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedusername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AccountServerDto.DeactivatedUserDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AccountServerDto.DeactivatedUserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AccountServerDto.DeactivatedUserDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastused();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string DeviceName = 1;
 * @return {string}
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.getDevicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AccountServerDto.DeactivatedUserDevice} returns this
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.setDevicename = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.DeactivatedUserDevice} returns this
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.clearDevicename = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.hasDevicename = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bcl.DateTime LastUsed = 2;
 * @return {?proto.bcl.DateTime}
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.getLastused = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 2));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.AccountServerDto.DeactivatedUserDevice} returns this
*/
proto.AccountServerDto.DeactivatedUserDevice.prototype.setLastused = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountServerDto.DeactivatedUserDevice} returns this
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.clearLastused = function() {
  return this.setLastused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.hasLastused = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string AssociatedUsername = 3;
 * @return {string}
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.getAssociatedusername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AccountServerDto.DeactivatedUserDevice} returns this
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.setAssociatedusername = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.DeactivatedUserDevice} returns this
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.clearAssociatedusername = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.DeactivatedUserDevice.prototype.hasAssociatedusername = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AccountServerDto.ActiveUserDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.AccountServerDto.ActiveUserDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AccountServerDto.ActiveUserDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AccountServerDto.ActiveUserDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicename: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lastused: (f = msg.getLastused()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    userdeviceid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AccountServerDto.ActiveUserDevice}
 */
proto.AccountServerDto.ActiveUserDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AccountServerDto.ActiveUserDevice;
  return proto.AccountServerDto.ActiveUserDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AccountServerDto.ActiveUserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AccountServerDto.ActiveUserDevice}
 */
proto.AccountServerDto.ActiveUserDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicename(value);
      break;
    case 2:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setLastused(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserdeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AccountServerDto.ActiveUserDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AccountServerDto.ActiveUserDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AccountServerDto.ActiveUserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AccountServerDto.ActiveUserDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastused();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string DeviceName = 1;
 * @return {string}
 */
proto.AccountServerDto.ActiveUserDevice.prototype.getDevicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AccountServerDto.ActiveUserDevice} returns this
 */
proto.AccountServerDto.ActiveUserDevice.prototype.setDevicename = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.ActiveUserDevice} returns this
 */
proto.AccountServerDto.ActiveUserDevice.prototype.clearDevicename = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.ActiveUserDevice.prototype.hasDevicename = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bcl.DateTime LastUsed = 2;
 * @return {?proto.bcl.DateTime}
 */
proto.AccountServerDto.ActiveUserDevice.prototype.getLastused = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 2));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.AccountServerDto.ActiveUserDevice} returns this
*/
proto.AccountServerDto.ActiveUserDevice.prototype.setLastused = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountServerDto.ActiveUserDevice} returns this
 */
proto.AccountServerDto.ActiveUserDevice.prototype.clearLastused = function() {
  return this.setLastused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.ActiveUserDevice.prototype.hasLastused = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 UserDeviceId = 3;
 * @return {number}
 */
proto.AccountServerDto.ActiveUserDevice.prototype.getUserdeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.AccountServerDto.ActiveUserDevice} returns this
 */
proto.AccountServerDto.ActiveUserDevice.prototype.setUserdeviceid = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.AccountServerDto.ActiveUserDevice} returns this
 */
proto.AccountServerDto.ActiveUserDevice.prototype.clearUserdeviceid = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountServerDto.ActiveUserDevice.prototype.hasUserdeviceid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.AccountServerDto.UserDeviceRegistrationStatusType = {
  OK: 0,
  NOTOKUSERDEVICEDISABLED: 1,
  NOTOKTOOMANYACTIVATEDDEVICES: 2
};

goog.object.extend(exports, proto.AccountServerDto);
