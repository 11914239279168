import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AboutUsOperations } from '@operations/about-us.operations';

@Component({
  selector: 'app-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss']
})
export class ContactUsDialogComponent implements OnInit {

  contactForm: FormGroup;

  public isNotClosing: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ContactUsDialogComponent>,
    private aboutUsRepo: AboutUsOperations,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private translationService: TranslateService
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', { validators: [Validators.required, Validators.email] }],
      company: ['',],
      recaptcha: ['', Validators.required],
      vehiclesQuantity: [1, Validators.min(1)]
    });
  }

  async onSend(): Promise<void> {
    if (!this.contactForm.valid) {
      return;
    }
    this.aboutUsRepo.sendContactMessageAboutFleet(
      this.contactForm.get("email").value,
      this.contactForm.get("phone").value,
      this.contactForm.get("firstName").value,
      this.contactForm.get("lastName").value,
      this.contactForm.get("company").value,
      this.contactForm.get("vehiclesQuantity").value
    ).then(() => {
      this.snackBar.open(this.translationService.instant("ABOUTUS.SEND-MESSAGE-SUCCESS"), "", { duration: 3000, panelClass: ['green-snackbar'] });
    }).catch
      ((err) => {
        this.snackBar.open(this.translationService.instant("ABOUTUS.SEND-MESSAGE-FAILURE"), "", { duration: 3000, panelClass: ['red-snackbar'] });
      })

    // try {
    //   const retVal = await fetch("http://localhost:8090/contactus", {
    //     method: "POST",
    //     body: JSON.stringify({
    //       "msg": `
    //         Jméno: ${this.contactForm.get("firstName").value}\n
    //         Příjmení: ${this.contactForm.get("lastName").value}\n
    //         Telefon: ${this.contactForm.get("phone").value}\n 
    //         Email: ${this.contactForm.get("email").value}\n 
    //         Firma: ${this.contactForm.get("company").value}\n 
    //         Počet vozidel: ${this.contactForm.get("vehiclesQuantity").value}
    //       `
    //     }),
    //     headers: {
    //       "Content-type": "application/json; charset=UTF-8"
    //     }
    //   });
    //   console.log(retVal);
    // } catch( e ) {
    //   console.error( e );
    // }

    this.isNotClosing = false;
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.isNotClosing = false;
    this.dialogRef.close(false);
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.contactForm.patchValue({
      "recaptcha":true,
    })
  }

}