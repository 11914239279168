<form [formGroup]="registrationForm" class="mt-2 theme-container" (ngSubmit)="onSubmitRegistrationForm()">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <h2>{{'ACCOUNT.LOGIN-INFO' | translate}}</h2>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.EMAIL' | translate}}</mat-label>
        <input matInput formControlName="username" required>
        <mat-error *ngIf="registrationForm.controls.username.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.SHOWED-NAME' | translate}}</mat-label>
        <input matInput formControlName="showedName">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.PASSWORD' | translate}}</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-error *ngIf="registrationForm.controls.password.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
        <mat-error *ngIf="registrationForm.controls.password.errors?.minlength" 
          [innerText]="'INPUT.MIN-LENGTH-N' | translate: {value1: pswdMinLength}">
        </mat-error>
        <mat-error *ngIf="registrationForm.controls.password.errors?.maxlength" 
          [innerText]="'INPUT.MAX-LENGTH-N' | translate: {value1: pswdMaxLength}">
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.PASSWORD-CHANGE.CONFIRM-NEW-PASSWORD' | translate}}</mat-label>
        <input matInput type="password" formControlName="confirmPassword" required>
        <mat-error *ngIf="registrationForm.controls.confirmPassword.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
        <mat-error
          *ngIf="registrationForm.controls.confirmPassword.hasError('mustMatch')">{{'ACCOUNT.PASSWORD-CHANGE.NOT-MATCHING'
          | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- <div class="divider mt-1"></div> -->
  <div fxLayout="row wrap" class="mt-3">
    <div fxFlex="100">
      <h2>{{'ACCOUNT.BILLING-ADDRESS' | translate}}</h2>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.NAME' | translate}}</mat-label>
        <input matInput formControlName="firstName" required>
        <mat-error *ngIf="registrationForm.controls.firstName.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.SURNAME' | translate}}</mat-label>
        <input matInput formControlName="lastName" required>
        <mat-error *ngIf="registrationForm.controls.lastName.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.BILLING-NAME' | translate}}</mat-label>
        <input matInput formControlName="billingName">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.ADDRESS' | translate}}</mat-label>
        <input matInput formControlName="address" required>
        <mat-error *ngIf="registrationForm.controls.address.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.EMAIL' | translate}}</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="registrationForm.controls.email.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
        <mat-error *ngIf="registrationForm.controls.email.errors?.email">{{'CHECKOUT.EMAIL-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field> -->
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.PHONE' | translate}}</mat-label>
        <input matInput formControlName="phone">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.COUNTRY' | translate}}</mat-label>
        <mat-select formControlName="country" required [compareWith]="compareObjects">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{country.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="registrationForm.controls.country.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.CITY' | translate}}</mat-label>
        <input matInput formControlName="city" required>
        <mat-error *ngIf="registrationForm.controls.city.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.ZIP/POSTAL-CODE' | translate}}</mat-label>
        <input matInput formControlName="zip" required>
        <mat-error *ngIf="registrationForm.controls.zip.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field>
    </div>
    <!-- <div fxFlex="100" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.ADDRESS' | translate}}</mat-label>
        <input matInput formControlName="address" required>
        <mat-error *ngIf="registrationForm.controls.address.errors?.required">{{'INPUT.FIELD-REQUIRED' |
          translate}}</mat-error>
      </mat-form-field>
    </div> -->
    <div fxFlex="100">
      <h2>{{'ACCOUNT.COMPANY-INFO' | translate}}</h2>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.COMPANY' | translate}}</mat-label>
        <input matInput formControlName="company">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.IN' | translate}}</mat-label>
        <input matInput formControlName="businessID">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.TIN' | translate}}</mat-label>
        <input matInput formControlName="taxID">
      </mat-form-field>
    </div>
  </div>
  <!-- <button mat-raised-button color="primary" *ngIf="!del_address_wanted" (click)="addDeliveryAddress()">{{'ACCOUNT.REGISTRATION.ADD-DELIVERY-ADDRESS' | translate}}</button>
  <button mat-raised-button color="accent" *ngIf="del_address_wanted" (click)="removeDeliveryAddress()" class="mr-1">{{'ACCOUNT.REGISTRATION.REMOVE-DELIVERY-ADDRESS' | translate}}</button>
  <button mat-raised-button color="accent" *ngIf="del_address_wanted" (click)="copyAddress()">{{'ACCOUNT.REGISTRATION.COPY-DATA' | translate}}</button>
  <div *ngIf="del_address_wanted" fxLayout="row wrap" class="mt-3">
    <div fxFlex="100">
      <h2>{{'ACCOUNT.DELIVERY-ADDRESS' | translate}}</h2>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.NAME' | translate}}</mat-label>
        <input matInput formControlName="del_firstName">
        <mat-error *ngIf="registrationForm.controls.firstName.errors?.required">{{'INPUT.FIELD-REQUIRED' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.SURNAME' | translate}}</mat-label>
        <input matInput formControlName="del_lastName">
        <mat-error *ngIf="registrationForm.controls.lastName.errors?.required">{{'INPUT.FIELD-REQUIRED' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.EMAIL' | translate}}</mat-label>
        <input matInput formControlName="del_email">
        <mat-error *ngIf="registrationForm.controls.email.errors?.required">{{'INPUT.FIELD-REQUIRED' | translate}}</mat-error>
        <mat-error *ngIf="registrationForm.controls.del_email.errors?.email">{{'CHECKOUT.EMAIL-REQUIRED' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.PHONE' | translate}}</mat-label>
        <input matInput formControlName="del_phone">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.COUNTRY' | translate}}</mat-label>
        <mat-select formControlName="del_country">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{country}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="registrationForm.controls.country.errors?.required">{{'INPUT.FIELD-REQUIRED' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.CITY' | translate}}</mat-label>
        <input matInput formControlName="del_city">
        <mat-error *ngIf="registrationForm.controls.city.errors?.required">{{'INPUT.FIELD-REQUIRED' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.ZIP/POSTAL-CODE' | translate}}</mat-label>
        <input matInput formControlName="del_zip">
        <mat-error *ngIf="registrationForm.controls.zip.errors?.required">{{'INPUT.FIELD-REQUIRED' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" class="px-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ACCOUNT.REGISTRATION.ADDRESS' | translate}}</mat-label>
        <input matInput formControlName="del_address">
        <mat-error *ngIf="registrationForm.controls.address.errors?.required">{{'INPUT.FIELD-REQUIRED' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div> -->
  <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2 p-1">
    <ng-container *ngIf="this.lang === 'en'">
      <re-captcha id="re-captcha" site_key="6LcdRa4iAAAAACu3z5iP4zvvQ_yZB2hP0chVj8hU" formControlName="recaptcha"
        theme="light" language="en"></re-captcha>
    </ng-container>
    <ng-container *ngIf="this.lang === 'cz'">
      <re-captcha id="re-captcha" site_key="6LcdRa4iAAAAACu3z5iP4zvvQ_yZB2hP0chVj8hU" formControlName="recaptcha"
        theme="light" language="cs"></re-captcha>
    </ng-container>
    <ng-container *ngIf="this.lang === 'de'">
      <re-captcha id="re-captcha" site_key="6LcdRa4iAAAAACu3z5iP4zvvQ_yZB2hP0chVj8hU" formControlName="recaptcha"
        theme="light" language="de"></re-captcha>
    </ng-container>
  </div> -->
  <re-captcha
    (resolved)="resolved($event)"
    siteKey="6LcdRa4iAAAAACu3z5iP4zvvQ_yZB2hP0chVj8hU"
    required
  ></re-captcha>
  <button mat-raised-button style="margin-bottom: 1rem;" type="submit" color="warn"
    lang="this.translationService.currentLang" [disabled]="isSendingForm">
    {{'ACCOUNT.REGISTRATION.TITLE' | translate}}
  </button>
</form>