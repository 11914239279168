import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { UserRole } from '@model/userInfo';
import { AuthOperations } from '@operations-basics/auth.operations';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private authOp: AuthOperations,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(this.authOp.getCurrentUser());
    
    // return of(this.authOp.getCurrentUser() !== undefined && this.authOp.getCurrentUser() !== null)
    return of(this.authOp.getCurrentUser()?.roles?.includes(UserRole.ESHOP_DEVELOPER) ?? false)
  }
  
}
