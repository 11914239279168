import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  public partners = [];

  constructor() { }

  ngOnInit(): void {
    this.getPartners();
  }

  private getPartners() {
    this.partners = [
      { name: "partner1", image: "assets/images/dynavix/logos/01.png" },
      { name: "partner2", image: "assets/images/dynavix/logos/02.png" },
      { name: "partner3", image: "assets/images/dynavix/logos/03.png" },
      { name: "partner4", image: "assets/images/dynavix/logos/04.png" },
      { name: "partner5", image: "assets/images/dynavix/logos/05.png" },
      { name: "partner6", image: "assets/images/dynavix/logos/06.png" },
    ]

  }

}
