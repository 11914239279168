import { map, Observable, of, Subject, switchMap, take } from "rxjs";
import * as jspb from "google-protobuf";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { AuthOperations } from "@operations-basics/auth.operations";
import { Token } from "./accessContext";

const sharedUrl = 'https://devel-europe-cz01.dynavix.com/'

const requestsWhitelist = [
  'AuthUserDevice',
  'RefreshToken',
  'SendContactMessage',
  'WebRegistration'
]

export enum Server {
  AccountServer = 'AccountServer',
  ProductServer = 'ProductServer',
  TrafficServer = 'TrafficServer',
}

@Injectable()
export class HttpManager {
  
  constructor(
    private httpClient: HttpClient,
    private readonly injector: Injector,
  ) {}

  private get authService() {
    return this.injector.get(AuthOperations);
  }

  public sendRequest<Request extends jspb.Message>(request: Request, responseDecoder: typeof jspb.Message): Observable<typeof responseDecoder> {

    const responseString = new String(responseDecoder["displayName"]).split("Dto.").pop()
    const requestString = responseString.replace('Response', '')
    const encodedRequest = request.serializeBinary();
    const offset = encodedRequest.byteOffset;
    const length = encodedRequest.byteLength;
    const encodedRequestArrayBuffer = encodedRequest.buffer.slice(offset, offset + length);
    const serverName = new String(responseDecoder["displayName"]).split("Dto.")[0].split(".").pop()

    const url = sharedUrl + serverName + '/x-protobuf/reply/' + requestString

    return this.obtainAuthHeadersMetadata(requestString).pipe(
      take(1),
      switchMap((headers: HttpHeaders) => new Observable<Token<string>>((subscriber) => {
        this.sendBufferedRequest(encodedRequestArrayBuffer, headers, url, responseDecoder).subscribe({
          next: (data) => {
            subscriber.next(data)
          },
          error: (err) => {
            subscriber.error(err)
          }
        })
      }))
    ) 
  }

  private sendBufferedRequest(encodedRequestArrayBuffer: any, headers: any, url: string, responseDecoder: typeof jspb.Message): Observable<typeof responseDecoder> {
    var subject = new Subject<string>();    
    this.httpClient.post(url, encodedRequestArrayBuffer, {headers, responseType: 'arraybuffer'})
    .subscribe( {
      next: (data: any) => {
        subject.next(responseDecoder.deserializeBinary(new Uint8Array(data)));
      },
      error: err => {
        subject.error(err)
      }
    })
    return subject.asObservable();
  }

  private obtainAuthHeadersMetadata(requestString: string): Observable<HttpHeaders> {

    if(requestsWhitelist.find(request => request === requestString)) {
      return of(new HttpHeaders({
        Accept: 'application/x-protobuf',
        'Content-Type': 'application/x-protobuf',
      }))
      
    }

    if(!requestsWhitelist.find(request => request === requestString) && this.authService.getAccessToken() && !this.authService.getAccessToken().isExpired()) {
      return of(new HttpHeaders({
        Accept: 'application/x-protobuf',
        'Content-Type': 'application/x-protobuf',
        'ss-id': this.authService.getAccessToken().getValue()
      }))
    }
    var subject = new Subject<HttpHeaders>();   
    this.authService.sendRefreshRequest().subscribe
        ({
          next:(accessToken) => {
            if (accessToken && !accessToken.isExpired()) {
                subject.next(new HttpHeaders({
                  Accept: 'application/x-protobuf',
                  'Content-Type': 'application/x-protobuf',
                  'ss-id': accessToken.getValue()
                }))
            } else {
              subject.error(new Error('Refresh session request failed'));
            }
          },
          error:(err) => {
            console.error("Refresh session request failed. Request not sent due lacking permissions");
            subject.error(new Error('Refresh session request failed'));
          }
        })
    return subject.asObservable()
}
}

