import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Currency } from '@model/products';
import { CurrencyChangeSyncOperations } from '@operations-basics/currency-change-sync.operations';

@Injectable({
  providedIn: 'root'
})
export class CurrencyChangeSyncService implements CurrencyChangeSyncOperations{

  constructor() { 
  }

  private i = 0
  private currencyChangeSource = new BehaviorSubject(null);
  public currencyChange = this.currencyChangeSource.asObservable()

  public changeCurrency(newCurrency: Currency) {
    this.currencyChangeSource.next(newCurrency)
  }
}
