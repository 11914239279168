import { Observable } from "rxjs";
import { Order } from "@model/order";
import { Price, Product } from "@model/products";

export abstract class OrderOperations {

  public abstract getPPLServiceCost(currencyCode: string): Observable<Price>
  public abstract getCashOnDeliveryServiceCost(currencyCode: string): Observable<Price>
  public abstract placeOrder(order: Order): Promise<string>

  public abstract getAllOrders(): Observable<Order[]>
  public abstract editOrder(order: Order): Observable<void>
  public abstract deleteOrder(order: Order): Observable<void>
}  