<div class="brands-carousel"> 
    <div class="swiper-container h-100" [swiper]="config">
        <h2 style="text-align: center;">{{ title | translate}}</h2>
        <div class="swiper-wrapper h-100">      
            <div *ngFor="let brand of brands" class="swiper-slide h-100">
                <div class="p-0">
                    <a class="brand-item">
                        <img [attr.data-src]="brand.image" class="swiper-lazy">
                    </a>
                    <div class="swiper-lazy-preloader"></div>
                </div>
            </div>
        </div> 
        <button *ngIf="showArrows" mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button *ngIf="showArrows" mat-icon-button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
    </div>
</div>