import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {

  capitals: string = 'assets/data/usa-capitals.geojson';

  constructor(private http: HttpClient) { }
  
  makeCapitalMarkers(map: L.Map): void {
    this.http.get(this.capitals).subscribe((res: any) => {
        const marker = L.marker([50.0350840, 14.4146881]);        
        marker.addTo(map);
    });
   }
}
