<h4 class="col-title">{{'FOOT.USEFULL-LINKS' | translate }}</h4>
<!-- <div class="divider"></div> -->
<p class="mt-2">
    <a routerLink="privacy-policy" class="link secondary-color">{{'FOOT.TERMS_AND_CONDITIONS' | translate}}</a>
</p>
<!-- <p class="mt-1"><a routerLink="e-shop-requirement" class="link secondary-color">{{ 'FOOT.COMPLAINTS_PROCEDURE' | translate
    }}</a></p> -->
<p class="mt-1"><a routerLink="e-shop-requirement" class="link secondary-color">{{'FOOT.PROTECTION_OF_PERSONAL_DATA' |
        translate}}</a></p>
<p class="mt-1"><a routerLink="cookies" class="link secondary-color">{{'ABOUTUS.COOKIES-USAGE' |
        translate}}</a></p>
<p class="mt-1"><a routerLink="support" class="link secondary-color">{{'FOOT.TECHNICAL_SUPPORT' | translate }}</a></p>