import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.url + '/assets/i18n/', '.json');
}

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { AppSettings } from './app.settings';
import { AppService } from './app.service';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SupportComponent } from './pages/main-pages/support/support.component';
import { SupportForAndroidComponent } from './pages/main-pages/support/support-for-android/support-for-android.component';
import { SupportForIphoneComponent } from './pages/main-pages/support/support-for-iphone/support-for-iphone.component';
import { SupportForPNDComponent } from './pages/main-pages/support/support-for-pnd/support-for-pnd.component';
import { ManualsComponent } from './pages/main-pages/support/manuals/manuals.component';
import { RepairComponent } from './pages/main-pages/support/repair/repair.component';
import { DynavixManagerComponent } from './pages/main-pages/support/dynavix-manager/dynavix-manager.component';
import { ProductsOperations } from './data/operations/products.operations';
import { UserOperations } from './data/operations/user.operations';
import { ProductUtil } from './data/api/eshop/model/products';
import { CurrencyChangeSyncService } from './basics/services/currency-change-sync.service';
import { CurrencyChangeSyncOperations } from './basics/operations/currency-change-sync.operations';
import { OrderOperations } from './data/operations/order.operations';
import { MockOrderService } from './data/services/mock-order.service';
import { OrderMapper } from './data/mappers/order-mapper';
import { ProductMapper } from './data/mappers/product-mapper';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MockDataService } from './data/services/mock-products.service';
import { AboutUsOperations } from '@operations/about-us.operations';
import { HttpManager } from './basics/utils/http-request-sender';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost'// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank" rel="noopener">{{tosLink}}</a>
    </span>
    `,
  },
  content: {
    message: 'By using our site, you acknowledge that you have read and understand our ',

    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: 'https://cookie.com',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://privacy.com',

    tosLink: 'Terms of Service',
    tosHref: 'https://tos.com',
  }
};


export let AppInjector: Injector;
import { AboutUsComponent } from './pages/main-pages/about-us/about-us.component';
import { EShopRequirementComponent } from './pages/footer-pages/e-shop-requirement/e-shop-requirement.component';
import { PrivacyPolicyComponent } from './pages/footer-pages/privacy-policy/privacy-policy.component';
import { MapComponent } from './shared/map/map.component';
import { MarkerService } from './marker.service';
import { AboutUsService } from './data/services/about-us.service';
import { AuthService } from './basics/services/auth.service';
import { AuthOperations } from './basics/operations/auth.operations';
import { LogInComponent } from './pages/account/log-in/log-in.component';
import { SignUpComponent } from './pages/account/sign-up/sign-up.component';
import { UserService } from './data/services/user.service';
import { RoleProvider } from './basics/acl/role.provider';
import { InternalInfoOperations } from './data/operations/internal-info.operations';
import { InternalInfoService } from './data/services/internal-info.service';
import { LanguageSettingComponent } from './theme/components/language-setting/language-setting.component';
import { ServicesProductsColumnComponent } from './theme/components/footer/services-products-column/services-products-column.component';
import { UsefullLinksColumnComponent } from './theme/components/footer/usefull-links-column/usefull-links-column.component';
import { ContactColumnComponent } from './theme/components/footer/contact-column/contact-column.component';
import { AESService } from './basics/services/aes.service';
import { AESOperations } from './basics/operations/aes.operations';
import { ProductsService } from './data/services/products.service';
import { OrderService } from './data/services/order.service';
// import { CookiesComponent } from './pages/cookies/cookies.component';
import { RecaptchaModule } from "ng-recaptcha";
import { ScrollToTopResolver } from './scroll-to-top.resolver';
import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { HomeModule } from "./pages/main-pages/home/home.module";

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return '';
  }
}
@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    SharedModule,
    AppRoutingModule,
    HomeModule
],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    TopMenuComponent,
    LanguageSettingComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    SupportComponent,
    DynavixManagerComponent,
    AboutUsComponent,
    EShopRequirementComponent,
    PrivacyPolicyComponent,
    MapComponent,
    SupportForAndroidComponent,
    SupportForIphoneComponent,
    SupportForPNDComponent,
    ManualsComponent,
    RepairComponent,
    DynavixManagerComponent,
    LogInComponent,
    SignUpComponent,
    ServicesProductsColumnComponent,
    UsefullLinksColumnComponent,
    ContactColumnComponent,
    // CookiesComponent,
  ],
  providers: [
    AppSettings,
    AppService,
    ProductUtil,
    HttpManager,
    OrderMapper, ProductMapper,
    AppService,
    MarkerService,
    ScrollToTopResolver,
    RoleProvider,
    {
      provide: MissingTranslationHandler,
      useClass: MyMissingTranslationHandler
    },
    { provide: AboutUsOperations, useClass: AboutUsService },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: UserOperations, useClass: UserService },
    { provide: ProductsOperations, useClass: ProductsService },
    { provide: OrderOperations, useClass: OrderService },
    { provide: AuthOperations, useClass: AuthService },
    { provide: AESOperations, useClass: AESService },
    { provide: InternalInfoOperations, useClass: InternalInfoService },
    { provide: CurrencyChangeSyncOperations, useClass: CurrencyChangeSyncService },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}