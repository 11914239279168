import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  ngOnInit(): void {
    // window.scrollTo(0, 0);
    console.log(window.scrollY);
    setTimeout(() => {
      console.log(window.scrollY);

    }, 1000)
  }
}
