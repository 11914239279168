import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-column',
  templateUrl: './contact-column.component.html',
  styleUrls: ['./contact-column.component.scss']
})
export class ContactColumnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
