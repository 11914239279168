import { DomainMapper } from "./domain-mapper";
import { Currency as CurrencyProto,
         Price as PriceProto,
         Prices as PricesProto,
         Product as ProductProto } from '../api/eshop/proto/generated/dynavix/eshop/EshopProducts_pb';
import { Currency, Price, Prices, Product } from "@model/products";

export class ProductMapper implements DomainMapper<Product, ProductProto> {

  mapToDomain(data: ProductProto): Product {
      if (data === undefined || data === null) {
          return null
      }

      const pricesMapper = new PricesMapper()

      let retVal = new Product()
      retVal.productId = data.getProductid()
      retVal.categoryId = Number(data.getCategoryid())
      retVal.description = data.getLocalizedshortdescription()
      retVal.discount = data.getDiscount()
      retVal.name = data.getLocalizedname()
      retVal.images = data.getImageurlsList()
      retVal.pricesForCurrency = data.getPricesforcurrencyList().map(prices => { return pricesMapper.mapToDomain(prices)})
      
      return retVal
  }
  
  mapFromDomain(data: Product): ProductProto {
      throw new Error("Method not implemented.");
  }
}

export class PricesMapper implements DomainMapper<Prices, PricesProto> {

  mapToDomain(data: PricesProto): Prices {
      if (data === undefined || data === null) {
          return null
      }
      let retVal = new Prices()

      return retVal
  }
  
  mapFromDomain(data: Prices): PricesProto {
      throw new Error("Method not implemented.");
  }
}

export class PriceMapper implements DomainMapper<Price, PriceProto> {

  mapToDomain(data: PriceProto): Price {
      if (data === undefined || data === null) {
          return null
      }
      let retVal = new Price()

      return retVal
  }
  
  mapFromDomain(data: Price): PriceProto {
      throw new Error("Method not implemented.");
  }
}

export class CurrencysMapper implements DomainMapper<Currency, CurrencyProto> {

  mapToDomain(data: CurrencyProto): Currency {
      if (data === undefined || data === null) {
          return null
      }
      let retVal = new Currency()

      return retVal
  }
  
  mapFromDomain(data: Currency): CurrencyProto {
      throw new Error("Method not implemented.");
  }
}