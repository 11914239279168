<mat-toolbar id="menu-toolbar" class="top-navbar">
    <div class="theme-container" fxLayout="row" fxLayoutAlign="end">
        <img src="assets/images/dynavix/Dynavix-logo.svg" alt="Dynavix logo" class="mr-1"
            style="min-width: 100px; width: 12%; cursor: pointer;" routerLink="/">
        <span fxShow.gt-md="true" fxShow="false" fxLayout="row" fxLayoutAlign="end" fxFlex="60%">
         
            <a routerLink="/" (click)="closeMiniMenu()" [ngClass]="{'activeMenuItem': isActive('monitoring')}"
                class="font-awesome" size="lg" title="{{'NAV.MONITORING' | translate}}">
                {{'NAV.MONITORING' | translate}}
            </a>
            <a class="dropdown" routerLink="/products/dynavix-navigation" (click)="closeMiniMenu()"
                [ngClass]="{'activeMenuItem': (isActive('dynavix') || isActive('maps'))}" class="font-awesome" size="lg"
                title="{{'NAV.NAVIGATION' | translate}}">
                {{ 'NAV.NAVIGATION' | translate }}
            </a>
            <a routerLink="/support/main" (click)="closeMiniMenu()" [ngClass]="{'activeMenuItem': isActive('support')}"
                class="font-awesome" size="lg" title="{{'NAV.SUPPORT' | translate}}">
                {{'NAV.SUPPORT' | translate}}
            </a>
            <a routerLink="/about-us" [ngClass]="{'activeMenuItem': isActive('about-us')}" class="font-awesome"
                size="lg" title="{{'NAV.ABOUT_US' | translate}}">
                {{'NAV.ABOUT_US' | translate}}
            </a>
            <!-- <a routerLink="/about-us"
                [ngClass]="{'activeMenuItem': isActive('blog')}" class="font-awesome" size="lg"
                title="{{'NAV.BLOG' | translate}}">
                {{'NAV.BLOG' | translate}}
            </a> -->
        </span>
        <span style="align-content: center; display: flex; margin-left: auto">
            <span mat-button class="dropdown" routerLink="/products/e-shop" (click)="closeMiniMenu()"
                [ngClass]="{'activeMenuItem': isActive('e-shop')}" class="font-awesome special-link" size="lg"
                style="margin-left: 8px;">
                <span class="eshop-button" fxShow.gt-xs="true" fxShow="false">
                    <mat-icon class="mat-icon mr-1" svgIcon="shopping-basket"
                        style="align-content: start; display: flex;"></mat-icon>
                    Eshop
                </span>
            </span>
            <span mat-button class="dropdown"
                onclick="window.open(`https://fleet.dynavix.cz/fleet/dashboard`, '_blank')"
                [ngClass]="{'activeMenuItem': isActive('e-shop')}" class="font-awesome special-link" size="lg">
                <span class="demo-button" fxShow.gt-xs="true" fxShow="false">
                    <!-- <mat-icon class="mat-icon mr-1" svgIcon="demo"
                        style="align-content: start; display: flex;"></mat-icon> -->
                    <mat-icon class="mat-icon" style="margin-right: 4px;
                        margin-left: -3px;">pin_drop</mat-icon>
                    Monitoring demo
                </span>
            </span>
            <!-- <span mat-button class="dropdown" routerLink="/e-shop/all" (click)="closeMiniMenu()"
                [ngClass]="{'activeMenuItem': isActive('e-shop')}" class="font-awesome special-link" size="lg">
                <span class="search-button">
                    <mat-icon class="mat-icon">search</mat-icon>
                </span>
            </span> -->
            <app-language-setting></app-language-setting>
            <span mat-button fxShow.gt-md="false" fxShow="true" (click)="sidenavToggle()"
                class="font-awesome special-link">
                <span class="search-button">
                    <mat-icon>menu</mat-icon>
                </span>
            </span>
        </span>
        <ng-container *ngIf="isDev()">
            <a mat-button routerLink="/admin" (click)="closeMiniMenu()"
                [ngClass]="{'activeMenuItem': isActive('admin')}" class="font-awesome" size="lg">
                ADMIN
            </a>
        </ng-container>
        <!-- <a mat-button routerLink="/products" routerLinkActive="horizontal-active-link"
    (click)="openMegaMenu()">{{'NAV.ALL_PRODUCTS' | translate}}</a>
    <a mat-button routerLink="/support" (click)="openSupport()" [ngClass]="{'activeMenuItem': isActive('support')}"
    class="font-awesome" size="lg">
    {{'NAV.SUPPORT' | translate}}
</a> -->
        <!-- <a mat-button routerLink="/ev-portal" (click)="closeMiniMenu()"
    [ngClass]="{'activeMenuItem': isActive('ev-portal')}" class="font-awesome" size="lg">
    {{'NAV.EV_PORTAL' | translate}}
</a> -->

        <!-- <div *ngIf="isProducts" fxLayout="row" class="produst-buttons">
            <input type="radio" name="radio" id="dynavix-for-android" checked>
            <input type="radio" name="radio" id="dynavix-for-iphone">
            <input type="radio" name="radio" id="maps">

            <a routerLink="products/dynavix-for-android" (click)="selectedInput(1)" mat-button id="android"
                fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MENU.DYNAVIX-FOR-ANDROID' | translate}}</span>
            </a>

            <a routerLink="products/dynavix-for-iphone" (click)="selectedInput(2)" for="r2" mat-button id="iphone"
                fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MENU.DYNAVIX-FOR-IPHONE' | translate}}</span>
            </a>

            <a routerLink="products/maps" (click)="selectedInput(3)" mat-button for="r3" id="dynavix-maps"
                fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MENU.DYNAVIX-FOR-MAP' | translate}}</span>
            </a>
        </div>

        <div *ngIf="isAboutUs" fxLayout="row" class="about-buttons">
            <input type="radio" name="radio" id="contact" checked>
            <input type="radio" name="radio" id="dynavix">

            <a routerLink="about-us" (click)="selectedInput(1)" mat-button id="about-contact" fxLayout="row"
                fxLayoutAlign="start center">
                <span>{{'ABOUTUS.CONTACT' | translate}}</span>
            </a>

            <a routerLink="about-us/dynavix" (click)="selectedInput(2)" for="r2" mat-button id="about-dynavix"
                fxLayout="row" fxLayoutAlign="start center">
                <span>DYNAVIX A.S.</span>
            </a>
        </div>

        <div *ngIf="isSupport" fxLayout="row" class="support-buttons">
            <input type="radio" name="radio-support" id="support-for-android">
            <input type="radio" name="radio-support" id="support-for-iphone">
            <input type="radio" name="radio-support" id="support-for-pnd">
            <input type="radio" name="radio-support" id="manuals">
            <input type="radio" name="radio-support" id="repair">
            <input type="radio" name="radio-support" id="dynavix-manager">

            <a routerLink="support/support-for-android" (click)="selectedInput(1)" mat-button id="support-android"
                fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MENU.DYNAVIX-FOR-ANDROID' | translate}}</span>
            </a>

            <a routerLink="support/support-for-iphone" (click)="selectedInput(2)" for="r2" mat-button
                id="support-iphone" fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MENU.DYNAVIX-FOR-IPHONE' | translate}}</span>
            </a>

            <a routerLink="support/support-for-pnd" (click)="selectedInput(3)" mat-button for="r3" id="pnd"
                fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MENU.DYNAVIX-FOR-PND' | translate}}</span>
            </a>

            <a routerLink="support/manuals" (click)="selectedInput(4)" mat-button id="support-manuals" fxLayout="row"
                fxLayoutAlign="start center">
                <span>{{'MENU.MANUALS' | translate}}</span>
            </a>

            <a routerLink="support/repair" (click)="selectedInput(5)" for="r2" mat-button id="support-repair"
                fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MENU.REPAIR' | translate}}</span>
            </a>

            <a routerLink="support/dynavix-manager" (click)="selectedInput(6)" mat-button for="r3" id="manager"
                fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MENU.MANAGER' | translate}}</span>
            </a>
        </div> -->
    </div>
</mat-toolbar>