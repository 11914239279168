import { DomainMapper } from "./domain-mapper";
import { AuthTokens } from "@model/auth";
import { AuthUserDevice } from "@model/auth"; 
import { GetWebUserInfoResponse } from "../api/eshop/proto/generated/dynavix/WebUserInfo_pb";
import { UserInfo, UserRole } from "@model/userInfo";

export class UserInfoMapper implements DomainMapper<UserInfo, GetWebUserInfoResponse> {

  mapToDomain(data: GetWebUserInfoResponse): UserInfo {
    let retVal = new UserInfo();
    retVal.username = data.getUsername();
    retVal.showedname = data.getDisplayedname();
    retVal.roles = data.getRolesList().map(it => {return UserRole[it]});
    return retVal;
  }
  
  mapFromDomain(data: UserInfo): GetWebUserInfoResponse {
      throw new Error("Method not implemented.");
  }
}
