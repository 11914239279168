import { Token } from "src/app/basics/utils/accessContext";

export class AuthTokens {
  accessToken: Token<string> 
  refreshToken: Token<string> 
}

export enum PlatformType {
  PLATFORM_UNSPECIFIED = 0,
  PLATFORM_ANDROID = 1,
  PLATFORM_IOS = 2,
  PLATFORM_WEBCLIENT = 3,
}

export class AuthUserDevice {
  username: string;
  password: string;
  platform: PlatformType;
}