import { Address } from '@model/order';
import { 
  BillingAddress as BillingAddressProto,
  DeliveryAddress as DeliveryAddressProto
} from '../api/eshop/proto/generated/dynavix/WebRegistration_pb';
import { DomainMapper } from "./domain-mapper";
import { BillingAddress } from '@model/order';

export class BillingAddressMapper implements DomainMapper<BillingAddress, BillingAddressProto> {
    mapToDomain(data: BillingAddressProto): BillingAddress {
      var billAddr = new BillingAddress();
      billAddr.firstName = data.getName();
      billAddr.lastName = data.getSurname();
      billAddr.email = data.getEmail();
      billAddr.address = data.getStreet();
      billAddr.city = data.getCity();
      billAddr.zip = data.getPsc();
      
      if(data.hasMobile())
        billAddr.phone = data.getMobile();
      if(data.hasBillingname())
        billAddr.billingName = data.getBillingname();
      if(data.hasCompany())
        billAddr.company = data.getCompany();
      if(data.hasIc())
        billAddr.businessID = data.getIc();
      if(data.hasDic())
        billAddr.taxID = data.getDic();

      return billAddr;
    }
    mapFromDomain(data: BillingAddress): BillingAddressProto {    
        var billAddr = new BillingAddressProto();
        billAddr.setName(data.firstName);
        billAddr.setSurname(data.lastName);
        billAddr.setBillingname(data.billingName);
        billAddr.setEmail(data.email);
        billAddr.setMobile(data.phone);
        billAddr.setCity(data.city);
        billAddr.setCountycode(data.country);
        billAddr.setPsc(data.zip);
        billAddr.setStreet(data.address);
        billAddr.setCompany(data.company);
        billAddr.setIc(data.businessID);
        billAddr.setDic(data.taxID);

        return billAddr;
    }
  }

export class DeliveryAddressMapper implements DomainMapper<Address, DeliveryAddressProto> {
    mapToDomain(data: DeliveryAddressProto): Address {
      var delivAddr = new BillingAddress();
      delivAddr.firstName = data.getName();
      delivAddr.lastName = data.getSurname();
      delivAddr.email = data.getEmail();
      delivAddr.address = data.getStreet();
      delivAddr.city = data.getCity();
      delivAddr.zip = data.getPsc();
      
      if(data.hasMobile())
        delivAddr.phone = data.getMobile();
      return delivAddr;
    }
    mapFromDomain(data: Address): DeliveryAddressProto {    
        var delivAddr = new DeliveryAddressProto();
        delivAddr.setName(data.firstName);
        delivAddr.setSurname(data.lastName);
        delivAddr.setEmail(data.email);
        delivAddr.setMobile(data.phone);
        delivAddr.setCity(data.city);
        delivAddr.setCountycode(data.country);
        delivAddr.setPsc(data.zip);
        delivAddr.setStreet(data.address);
      
        return delivAddr;
    }
  }