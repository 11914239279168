import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { HomeComponent } from './home.component';
import { MainCardsComponent } from './sections/main-cards/main-cards.component';
import { PartnersComponent } from './sections/partners/partners.component';
import { AboutUsComponent } from './sections/about-us/about-us.component';
import { ContactUsComponent } from './sections/contact-us/contact-us.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InfoStripeComponent } from './sections/info-stripe/info-stripe.component';
import { ScrollToTopResolver } from 'src/app/scroll-to-top.resolver';
import { MultiparagraphInfoStripeComponent } from './sections/multiparagraph-info-stripe/multiparagraph-info-stripe.component';

export const routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', data: { title: 'Dynavix - navigační systémy pro mobilní zařízení' }, resolve: { data: ScrollToTopResolver }}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HomeComponent,
    MainCardsComponent,
    PartnersComponent,
    AboutUsComponent,
    ContactUsComponent,
    InfoStripeComponent,
    MultiparagraphInfoStripeComponent,
  ],
  exports:[
    MultiparagraphInfoStripeComponent,
    InfoStripeComponent
  ]
})
export class HomeModule { }