import { Observable } from "rxjs";
import { InternalUserDevice, UpdatePurchasedProductVersionsRequest } from "@model/internalInfo";

export abstract class InternalInfoOperations {

  public abstract getInternalInfo(email: string | undefined, username: string | undefined, userDeviceId: number | undefined): Observable<InternalUserDevice[]>
  public abstract updatePurchasedProductVersions(
    udpvId: number[], 
    request: UpdatePurchasedProductVersionsRequest
  ): Observable<void>
}  