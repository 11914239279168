<section id="traffic_cameras" fxLayout="column" fxLayoutAlign="start center" class="info-section theme-container">
    <!-- <h2 fxFlex="100" class="headline-h2">{{'HOME.ABOUT-US.TITLE' | translate}}</h2> -->
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between" class="section-content">
        <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
            <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="start center" style="padding-left: 0 !important;">
                <img src="assets/images/dynavix/About-us.png" alt="about us image" border="0"
                    class="img-width-100">
            </mat-card>
        </div>
        <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
            <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="center">
                <h3 style="font-size: 2em; max-width: 75%;">{{ 'HOME.ABOUT-US.HEADING' | translate }}</h3>
                <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.TRAFFIC-CAMERAS.PAR1' | translate"></p>
                <!-- <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.TRAFFIC-CAMERAS.PAR3' | translate"></p> -->
                <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.TRAFFIC-CAMERAS.PAR2' | translate"></p>
            </mat-card>
        </div>
    </div>
</section>