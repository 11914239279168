import { DomainMapper } from "./domain-mapper";
import { DateTime } from "../api/eshop/proto/generated/dynavix/bcl_pb";

export class DateMapper implements DomainMapper<Date, DateTime> {

  mapToDomain(data: DateTime): Date {
      if (data === undefined || data === null) {
          return null;
      }
      // console.log(data.getScale());
      // console.log(data.getValue());
      
      switch(data.getScale()) {
        case DateTime.TimeSpanScale.TICKS: {
          return new Date(data.getValue() / 10000);
        }
        case DateTime.TimeSpanScale.MILLISECONDS: {
          return new Date(data.getValue());
        }
        case DateTime.TimeSpanScale.SECONDS: {
          return new Date(data.getValue() * 1000);
        }
        case DateTime.TimeSpanScale.MINUTES: {
          return new Date(data.getValue() * 60 * 1000);
        }
        case DateTime.TimeSpanScale.HOURS: {
          return new Date(data.getValue() * 60 * 60 * 1000);
        }
        case DateTime.TimeSpanScale.DAYS: {
          return new Date(data.getValue() * 24 * 60 * 60 * 1000);
        }
        default:
          return new Date(data.getValue() / 10000);
      }
  }
  
  mapFromDomain(data: Date): DateTime {
    if (data === undefined || data === null) {
        return null;
    }
    let retVal = new DateTime();
    retVal.setScale(DateTime.TimeSpanScale.MILLISECONDS);
    retVal.setValue(data.getTime());
    
    return retVal;
  }
}