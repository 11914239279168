import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpManager } from 'src/app/basics/utils/http-request-sender';
import { WebChangePassword, WebChangePasswordResponse } from 'src/app/data/api/eshop/proto/generated/dynavix/WebPasswordChange_pb';
import {
  GetWebUserInfo as GetWebUserInfoProto,
  UpdateWebUserDeliveryAddress, UpdateWebUserDeliveryAddressResponse, UpdateWebUserInfo, UpdateWebUserInfoResponse
} from 'src/app/data/api/eshop/proto/generated/dynavix/WebUserInfo_pb';
import { Address, BillingAddress } from '@model/order';
import { UserInfo, UserInfoWithAddresses, UserRole } from '@model/userInfo';
import { WebRegistration } from '@model/web-sign-up';
import { DeliveryAddressMapper } from '../mappers/address-mapper';
import { UserOperations } from '@operations/user.operations';

const accountServerURL = "https://devel-europe-cz01.dynavix.com/AccountServer/";
const proxyServerURL = "https://api.fleet.dynavix.com/http-server-for-web/"
// const proxyServerURL = "http://localhost:8090/"

@Injectable({
  providedIn: 'root'
})
export class UserService implements UserOperations {
  private currentToken: string;

  constructor(
    private httpManager: HttpManager,
  ) { }

  public getCurrentToken(): string {
    return this.currentToken;
  }

  public instantLogout(): void {
    this.currentToken = null;
    localStorage.removeItem('token');
  }

  public async instantLogin(username: string, password: string): Promise<string> {
    localStorage.removeItem('credentials');
    return await fetch(proxyServerURL + "login", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ username: username, password: password })
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      console.log('Response:', data);
      if (data.code == "200") {
        // this.firstLogin(username, password);
        this.currentToken = data.sessionToken.token;
        localStorage.setItem('token', this.currentToken);
        return data.token;
      }
      throw new Error("wrong credentials")
    })
  }

  public async activateAccount(activationCode: string): Promise<void> {
    return await fetch(accountServerURL + "json/reply/UserActivation", {
      method: 'POST',
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ ActivationCode: activationCode })
    }).then(response => {
      console.log("activation of account", response);
      const billingAddress = JSON.parse(localStorage.getItem("billingAddress"));
      const credentials = JSON.parse(localStorage.getItem("credentials"));
      if (response.ok && billingAddress) {
        this.instantLogin(credentials.username, credentials.password).then(() => {
          console.log(this.currentToken);
          this.editBillingAddress(billingAddress).then(() => {
            localStorage.removeItem('credential');
          });
        });
      }
    })
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<void> {
    let request = new WebChangePassword();
    var subject = new Subject<void>();

    request.setOldpassword(oldPassword);
    request.setNewpassword(newPassword);

    this.httpManager.sendRequest(request, WebChangePasswordResponse).subscribe({
      next: (data: WebChangePasswordResponse) => {
        subject.next(null);
      },
      error: (err) => {
        subject.error(err);
      }
    })

    return subject.asObservable();
  }

  public async registerNewUser(userInfo: WebRegistration): Promise<any> {
    try {
      const retVal = await fetch(proxyServerURL + "register", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          UserName: userInfo.userInfo.username, Password: userInfo.password, Version: "new-dynavix-web", Address: userInfo.billingAddress
        })
      });

      // console.log("registration successful", retVal.text());
      localStorage.setItem("billingAddress", JSON.stringify(userInfo.billingAddress));
      localStorage.setItem("credentials", JSON.stringify({ username: userInfo.userInfo.username, password: userInfo.password }));

      return retVal.json();

    } catch (e) {
      console.error(e);
    }
  }

  public editUserInfo(userInfo: UserInfo): Observable<void> {
    let request = new UpdateWebUserInfo();
    request.setDisplayedname(userInfo.showedname);
    var subject = new Subject<void>();

    this.httpManager.sendRequest(request, UpdateWebUserInfoResponse).subscribe({
      next: (data: WebChangePasswordResponse) => {
        subject.next(null);
      },
      error: (err) => {
        subject.error(err);
      }
    })

    return subject.asObservable();
  }

  public async editBillingAddress(newBillingAddress: BillingAddress): Promise<void> {
    try {
      if (this.currentToken === undefined || this.currentToken === null) {
        this.currentToken = localStorage.getItem('token');
      }
      console.log(this.currentToken);

      const retVal = await fetch(proxyServerURL + "userinfo", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          token: this.currentToken, billingAddress: {
            email: newBillingAddress.email, company: newBillingAddress.company, billingName: newBillingAddress.billingName,
            lastName: newBillingAddress.lastName, firstName: newBillingAddress.firstName,
            phone: newBillingAddress.phone, city: newBillingAddress.city, address: newBillingAddress.address,
            countryCode: newBillingAddress.country, zip: newBillingAddress.zip, ico: newBillingAddress.businessID, dic: newBillingAddress.taxID
          }
        })
      });

      console.log("editBillingAddress successful", retVal.text());
      localStorage.setItem("billingAddress", JSON.stringify(newBillingAddress));

    } catch (e) {
      console.error(e);
    }
  }

  public editDeliveryAddress(newDeliveryAddress: Address): Observable<void> {
    let request = new UpdateWebUserDeliveryAddress();
    var subject = new Subject<void>();
    const mapper = new DeliveryAddressMapper();
    request.setDeliveryaddress(mapper.mapFromDomain(newDeliveryAddress));

    this.httpManager.sendRequest(request, UpdateWebUserDeliveryAddressResponse).subscribe({
      next: (data: UpdateWebUserDeliveryAddressResponse) => {
        subject.next(null);
      },
      error: (err) => {
        subject.error(err);
      }
    })

    return subject.asObservable();
  }
  public async setUserInfo(userInfo: UserInfoWithAddresses):Promise<void>{
    await fetch(proxyServerURL + "userinfo", {
      method: "POST",
      body: JSON.stringify({
        token: this.currentToken || localStorage.getItem('token'),
        billingAddress: userInfo.billingAddress,
        deliveryAddress: userInfo.deliveryAddress
      })
    }).then(response => {
      return response.json();
    }).catch(
      (e) => {
        console.log(e);
      }
    ).then(data => {
      if (data.code !== "200") {
       console.error('user data update failed')
      }
    })

  }

  public async GetUserInfo(): Promise<UserInfoWithAddresses> {
    let request = new GetWebUserInfoProto();
    var subject = new Subject<UserInfoWithAddresses>();

    let retVal = new UserInfoWithAddresses();
    retVal.userInfo = new UserInfo();
    // retVal.userInfo.showedname = "novakjan"
    retVal.userInfo.roles = [UserRole.CUSTOMER];

    if (this.currentToken === undefined || this.currentToken === null) {
      this.currentToken = localStorage.getItem('token');
    }
    console.log(this.currentToken);

    return await fetch(proxyServerURL + "userinfo?token=" + this.currentToken, {
      method: "GET"
    }).then(response => {
      return response.json();
    }).catch(
      () => {
        return new UserInfoWithAddresses().userInfo = retVal.userInfo;
      }
    ).then(data => {
      if (data.code == "200") {
        retVal.billingAddress = data.billingAddress;
        retVal.deliveryAddress = data.deliveryAddress;
        //  localStorage.setItem("billingAddress", JSON.stringify(retVal.billingAddress));
        return retVal;
      }
      return new UserInfoWithAddresses();
    })
    // .then(data => {
    //   retVal.billingAddress.address = data.address_1;
    //   retVal.billingAddress.billingName = data.name;
    //   retVal.billingAddress.businessID = data.ICO;
    //   retVal.billingAddress.city = data.city;
    //   retVal.billingAddress. = data.address_1;
    //   retVal.billingAddress.address = data.address_1;
    //   retVal.billingAddress.address = data.address_1;
    //   retVal.billingAddress.address = data.address_1;
    //   retVal.billingAddress.address = data.address_1;

    //   return retVal;
    // });

    // retVal.billingAddress = new BillingAddress();
    // retVal.billingAddress.firstName = "Jan";
    // retVal.billingAddress.lastName = "Novak";
    // retVal.billingAddress.email = "jan.novak@gmail.com";
    // retVal.billingAddress.country = "CZ";
    // retVal.billingAddress.city = "New York";
    // retVal.billingAddress.zip = "12002";
    // retVal.billingAddress.address = "Wall st. 20";

    // console.log("next subject");

    // return new Promise((resolve, reject) => {
    //   resolve(retVal);
    // });


    // this.httpManager.sendRequest(request, GetWebUserInfoResponse).subscribe({
    //   next: (data: GetWebUserInfoResponse) => {
    //     const delivMapper = new DeliveryAddressMapper();
    //     const billMapper = new BillingAddressMapper();

    //     var response = new UserInfoWithAddresses();
    //     response.billingAddress = billMapper.mapToDomain(data.getBillingaddress());
    //     response.deliveryAddress = null;
    //     if (data.hasDeliveryaddress()) {
    //       response.deliveryAddress = delivMapper.mapToDomain(data.getDeliveryaddress());
    //     }
    //     response.userInfo = new UserInfo();
    //     response.userInfo.showedname = data.getDisplayedname();
    //     response.userInfo.username = data.getUsername();

    //     subject.next(response);
    //   },
    //   error: (err) => {
    //     subject.error(err);
    //   }
    // })

    // return subject.asObservable();
  }

}
