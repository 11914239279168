import { AppService } from "./app.service";

export class Category {
  constructor(
    public id: number, 
    public name:string, 
    public hasSubCategory: boolean,
    public parentId: number,
    public hasProducts?: boolean,
    ){ }
}

// export class ProductUtil {
//   constructor(
//     private appService: AppService
//   ) {  }

//   copyProduct(product: Product) {
//     return new Product(
//       this.appService,
//       product.id ,
//       product.name ,
//       product.images ,
//       product.discount ,
//       product.description,
//       product.availibilityCount,
//       product.cartCount,
//       product.categoryId,
//     )
//   }
// }

// export class Product {
//   constructor(
//     private appService: AppService,
//     public id: number,
//     public name: string,
//     public images: Array<any>,
//     // private oldPrice: number,
//     // private newPrice: number,
//     // private dphLessPrice: number,
//     public discount: number,
//     public description: string,
//     public availibilityCount: number,
//     public cartCount: number,
//     public categoryId: number){ }

//     public getOldPrice() {
//       switch(localStorage.getItem('currency')) {
//         case 'CZE': return 500
//         case 'USD': return 20
//         case 'EUR': return 20
//         case 'GBP': return 17
//         default: return 500
//       }
//     }
//     public getNewPrice()  {
//       switch(localStorage.getItem('currency')) {
//         case 'CZE': return 400
//         case 'USD': return 16
//         case 'EUR': return 16
//         case 'GBP': return 13.8
//         default: return 400
//       }
//     }
//     public getDPHLessPrice()  {
//       switch(localStorage.getItem('currency')) {
//         case 'CZE': return 475
//         case 'USD': return 19
//         case 'EUR': return 19
//         case 'GBP': return 13
//         default: return 475
//       }
//     }
//     public getOldPriceAsString() {
//       switch(localStorage.getItem('currency')) {
//         case 'CZE': return '500 Kč'
//         case 'USD': return '$20'
//         case 'EUR': return '€20'
//         case 'GBP': return '£17'
//         default: return '500 Kč'
//       }
//     }
//     public getNewPriceAsString()  {
//       switch(localStorage.getItem('currency')) {
//         case 'CZE': return '400 Kč'
//         case 'USD': return '$16'
//         case 'EUR': return '€16'
//         case 'GBP': return '£13.8'
//         default: return '400 Kč'
//       }
//     }
//     public getDPHLessPriceAsString()  {
//       switch(localStorage.getItem('currency')) {
//         case 'CZE': return '475 Kč'
//         case 'USD': return '$19'
//         case 'EUR': return '€19'
//         case 'GBP': return '£13'
//         default: return '475 Kč'
//       }
//     }
// }

export enum DeliveryMethod {
  EMAIL = 'email',
  PPL = 'ppl'
}
export enum PaymentMethod {
  // CREDIT_CARD = 'card',
  TRANSFER = 'transfer',
  // CASH_ON_DELIVERY = 'cash'
}

export class Currency {
  constructor(
    public symbol:string, 
    public code: string,
    public string: string){ }
}

export interface Countries {
  code: string
  code3: string
  name: string
  number: string
}