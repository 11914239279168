import { Injectable } from '@angular/core';
import { UserInfo, UserRole } from '@model/userInfo';
import { AuthOperations } from '@operations-basics/auth.operations';
import { map, Observable, Subject, takeUntil } from 'rxjs';

@Injectable()
export class RoleProvider {

  private _destroyed$ = new Subject();

  constructor(private authOp: AuthOperations) {
  }

  getRole(): Observable<string[]> {
    return this.authOp.getCurrentUserAsObservable().pipe(
      takeUntil(this._destroyed$),
      map((user: UserInfo) => {
        if (user !== undefined) {
          return user.roles
        } else {
          return [UserRole.GUEST]
        }
    }))
  }
}
