import { Injectable } from "@angular/core"
import { AppService } from "src/app/app.service"

export class Currency {
  public symbol: string 
  public code: string
}

export class Price {
  public price: number
  public localizedDesc: string 
}

export class Prices {
  public currency: Currency 
  public oldPrice: Price  
  public newPrice: Price 
  public VatLessPrice: Price 
}

export class Product {
  public productId: number
  public name: string 
  public pricesForCurrency: Prices[] 
  public images: any[]
  public discount: number
  public description: string
  public availibilityCount: number
  public cartCount: number
  public categoryId: number
  public productSku: string
  public canbePurchased: boolean
  public i18n_key?: string
  public file_url?: string
}

@Injectable()
export class ProductUtil {
  constructor(
    private appService: AppService
  ) {  }

  copyProduct(product: Product) {
    if(product == null) return null;
    return JSON.parse(JSON.stringify(product));
  }

  public getOldPrice(product: Product | null | undefined) {
    // switch(localStorage.getItem('currency')) {
    //   case 'CZE': return 500
    //   case 'USD': return 20
    //   case 'EUR': return 20
    //   case 'GBP': return 17
    //   default: return 500
    // }
  }
  public getNewPrice(product: Product | null | undefined) {
    // switch(localStorage.getItem('currency')) {
    //   case 'CZE': return 400
    //   case 'USD': return 16
    //   case 'EUR': return 16
    //   case 'GBP': return 13.8
    //   default: return 400
    // }
    return product.pricesForCurrency[0].newPrice.price
  }
  public getVatLessPrice(product: Product | null | undefined) {
    // switch(localStorage.getItem('currency')) {
    //   case 'CZE': return 316
    //   case 'USD': return 13.35
    //   case 'EUR': return 13
    //   case 'GBP': return 11.39
    //   default: return 475
    // }
    return product.pricesForCurrency[0].VatLessPrice.price
  }
  public getOldPriceAsString(product: Product) {
    // switch(localStorage.getItem('currency')) {
    //   case 'CZE': return '500 Kč'
    //   case 'USD': return '$20'
    //   case 'EUR': return '€20'
    //   case 'GBP': return '£17'
    //   default: return '500 Kč'
    // }
    return product.pricesForCurrency[0].oldPrice?.localizedDesc
  }
  public getNewPriceAsString(product: Product | null | undefined) {
    // switch(localStorage.getItem('currency')) {
    //   case 'CZE': return '400 Kč'
    //   case 'USD': return '$16'
    //   case 'EUR': return '€16'
    //   case 'GBP': return '£13.8'
    //   default: return '400 Kč'
    // }
    return product.pricesForCurrency[0].newPrice?.localizedDesc
  }
  public getVatLessPriceAsString(product: Product | null | undefined) {
    // switch(localStorage.getItem('currency')) {
    //   case 'CZE': return '316 Kč'
    //   case 'USD': return '$13.35'
    //   case 'EUR': return '€13'
    //   case 'GBP': return '£11.39'
    //   default: return '316 Kč'
    // }
    return product.pricesForCurrency[0].VatLessPrice?.localizedDesc
  }
}