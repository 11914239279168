import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ScrollToTopResolver implements Resolve<any> {

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Observable<any>> {
    // window.scrollBy(0, -window.scrollY);
    const mainEl = (document.querySelector('div'))
    if(mainEl) {
      mainEl.style.display = 'none';
    }
    window.scrollTo({top: 0, behavior: 'instant' as ScrollBehavior});
    await this.sleep();
    if(mainEl) {
      mainEl.style.display = 'unset';
    }
    return of(0)
  }

  sleep() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve('done!');
      }, 5);
    });
  }
}