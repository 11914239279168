// source: dynavix/UpdateUserDeviceProductVersions.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var dynavix_ResponseStatus_pb = require('../dynavix/ResponseStatus_pb.js');
goog.object.extend(proto, dynavix_ResponseStatus_pb);
var dynavix_bcl_pb = require('../dynavix/bcl_pb.js');
goog.object.extend(proto, dynavix_bcl_pb);
goog.exportSymbol('proto.ProductServerDto.UpdateUserDeviceProductVersions', null, global);
goog.exportSymbol('proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProductServerDto.UpdateUserDeviceProductVersions.repeatedFields_, null);
};
goog.inherits(proto.ProductServerDto.UpdateUserDeviceProductVersions, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.UpdateUserDeviceProductVersions.displayName = 'proto.ProductServerDto.UpdateUserDeviceProductVersions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.displayName = 'proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.UpdateUserDeviceProductVersions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.UpdateUserDeviceProductVersions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.toObject = function(includeInstance, msg) {
  var f, obj = {
    userdeviceproductversionidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    enabled: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    activated: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
    validfrom: (f = msg.getValidfrom()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    validto: (f = msg.getValidto()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    unsetvalidfrom: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f,
    unsetvalidto: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.UpdateUserDeviceProductVersions;
  return proto.ProductServerDto.UpdateUserDeviceProductVersions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.UpdateUserDeviceProductVersions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserdeviceproductversionids(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivated(value);
      break;
    case 4:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setValidfrom(value);
      break;
    case 5:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setValidto(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnsetvalidfrom(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnsetvalidto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.UpdateUserDeviceProductVersions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.UpdateUserDeviceProductVersions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserdeviceproductversionidsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getValidfrom();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getValidto();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * repeated int64 UserDeviceProductVersionIds = 1;
 * @return {!Array<number>}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.getUserdeviceproductversionidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.setUserdeviceproductversionidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.addUserdeviceproductversionids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.clearUserdeviceproductversionidsList = function() {
  return this.setUserdeviceproductversionidsList([]);
};


/**
 * optional bool Enabled = 2;
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.setEnabled = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.clearEnabled = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.hasEnabled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool Activated = 3;
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.getActivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.setActivated = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.clearActivated = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.hasActivated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bcl.DateTime ValidFrom = 4;
 * @return {?proto.bcl.DateTime}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.getValidfrom = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 4));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
*/
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.setValidfrom = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.clearValidfrom = function() {
  return this.setValidfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.hasValidfrom = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bcl.DateTime ValidTo = 5;
 * @return {?proto.bcl.DateTime}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.getValidto = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 5));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
*/
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.setValidto = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.clearValidto = function() {
  return this.setValidto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.hasValidto = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool UnsetValidFrom = 6;
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.getUnsetvalidfrom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.setUnsetvalidfrom = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.clearUnsetvalidfrom = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.hasUnsetvalidfrom = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool UnsetValidTo = 7;
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.getUnsetvalidto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.setUnsetvalidto = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersions} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.clearUnsetvalidto = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersions.prototype.hasUnsetvalidto = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsestatus: (f = msg.getResponsestatus()) && dynavix_ResponseStatus_pb.ResponseStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse;
  return proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dynavix_ResponseStatus_pb.ResponseStatus;
      reader.readMessage(value,dynavix_ResponseStatus_pb.ResponseStatus.deserializeBinaryFromReader);
      msg.setResponsestatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsestatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dynavix_ResponseStatus_pb.ResponseStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseStatus ResponseStatus = 1;
 * @return {?proto.ResponseStatus}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.prototype.getResponsestatus = function() {
  return /** @type{?proto.ResponseStatus} */ (
    jspb.Message.getWrapperField(this, dynavix_ResponseStatus_pb.ResponseStatus, 1));
};


/**
 * @param {?proto.ResponseStatus|undefined} value
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse} returns this
*/
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.prototype.setResponsestatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse} returns this
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.prototype.clearResponsestatus = function() {
  return this.setResponsestatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.UpdateUserDeviceProductVersionsResponse.prototype.hasResponsestatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.ProductServerDto);
