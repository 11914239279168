import { UpdatePurchasedProductVersionsRequest } from '@model/internalInfo';
import { DateMapper } from './../mappers/date-mapper';
import { InternalInfo } from './../api/eshop/proto/generated/dynavix/InternalInfo_pb';
import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { InternalUserDevice } from '@model/internalInfo';
import { InternalInfoOperations } from '@operations/internal-info.operations';
import { HttpManager } from 'src/app/basics/utils/http-request-sender';
import { InternalInfoResponse } from '../api/eshop/proto/generated/dynavix/InternalInfoResponse_pb';
import { InternalUserDeviceMapper } from '../mappers/internalInfo-mapper';
import { UpdateUserDeviceProductVersions, UpdateUserDeviceProductVersionsResponse } from '../api/eshop/proto/generated/dynavix/UpdateUserDeviceProductVersions_pb';

@Injectable({
  providedIn: 'root'
})
export class InternalInfoService implements InternalInfoOperations {

  constructor(
    private httpManager: HttpManager,
  ) { }
  
  public getInternalInfo(email: string, username: string, userdeviceId: number): Observable<InternalUserDevice[]> {
    let request = new InternalInfo();
    var subject = new Subject<InternalUserDevice[]>();
    var filterChosen = false;
    
    if(username) {
      filterChosen = true;
      request.setUsername(username)
    }
    
    if(email) {
      filterChosen = true;
      request.setEmail(email)
    }
    
    if(userdeviceId) {
      filterChosen = true;
      request.setUserdeviceid(userdeviceId)
    }
    
    if(!filterChosen) {
      subject.error("no filter chosen error");
    } else {
      this.httpManager.sendRequest(request, InternalInfoResponse).pipe(take(1)).subscribe({
        next: (data: InternalInfoResponse) => {
          const mapper = new InternalUserDeviceMapper;
          subject.next(data.getUserdevicesList().map(device => { return mapper.mapToDomain(device)}).sort((a, b) => b.lastUsed.getTime() - a.lastUsed.getTime()));
        },
        error: (err) => {
          subject.error(err);
        }
      })
    }
    return subject.asObservable();
  }

  public updatePurchasedProductVersions(
    udpvId: number[], 
    request: UpdatePurchasedProductVersionsRequest 
  ): Observable<void> {
    let requestProto = new UpdateUserDeviceProductVersions();
    const mapper = new DateMapper();
    var subject = new Subject<void>();

    requestProto.setUserdeviceproductversionidsList(udpvId);
    requestProto.setEnabled(request.enabled);
    requestProto.setValidfrom(mapper.mapFromDomain(request.validFrom));
    requestProto.setValidto(mapper.mapFromDomain(request.validTo));
    requestProto.setUnsetvalidfrom(request.validFrom === null);
    requestProto.setUnsetvalidto(request.validTo === null);

    console.log(requestProto);
    console.log(request);
    

    this.httpManager.sendRequest(requestProto, UpdateUserDeviceProductVersionsResponse).pipe(take(1)).subscribe({
      next: () => {
        subject.next(null);
      },
      error: (err) => {
        subject.error(err);
      }
    })
    return subject.asObservable();
  }

}
