import { BillingAddress } from '@model/order';
import { Observable } from "rxjs";
import { Address } from "@model/order";
import { UserInfo, UserInfoWithAddresses } from "@model/userInfo";
import { WebRegistration } from "@model/web-sign-up";

export abstract class UserOperations {

  public abstract registerNewUser(userInfo: WebRegistration): Promise<any>

  public abstract GetUserInfo(): Promise<UserInfoWithAddresses>
  public abstract setUserInfo(userInfo: UserInfoWithAddresses):Promise<void>

  public abstract instantLogin(username: string, password: string): Promise<string>
  public abstract instantLogout(): void
  public abstract getCurrentToken(): string
  public abstract activateAccount(activationCode: string): Promise<void>

  public abstract changePassword(oldPassword: string, newPassword: string): Observable<void>
  public abstract editUserInfo(userInfo: UserInfo): Observable<void>
  public abstract editBillingAddress(newBillingAddress: BillingAddress): Promise<void>
  public abstract editDeliveryAddress(newDeliveryAddress: Address): Observable<void>

}  