// source: dynavix/InternalInfoResponse.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var dynavix_AuthUserDevice_pb = require('../dynavix/AuthUserDevice_pb.js');
goog.object.extend(proto, dynavix_AuthUserDevice_pb);
var dynavix_ResponseStatus_pb = require('../dynavix/ResponseStatus_pb.js');
goog.object.extend(proto, dynavix_ResponseStatus_pb);
var dynavix_ProductInfoCommon_pb = require('../dynavix/ProductInfoCommon_pb.js');
goog.object.extend(proto, dynavix_ProductInfoCommon_pb);
var dynavix_bcl_pb = require('../dynavix/bcl_pb.js');
goog.object.extend(proto, dynavix_bcl_pb);
goog.exportSymbol('proto.ProductServerDto.ABTestGroup', null, global);
goog.exportSymbol('proto.ProductServerDto.EshopProduct', null, global);
goog.exportSymbol('proto.ProductServerDto.InternalInfoResponse', null, global);
goog.exportSymbol('proto.ProductServerDto.InternalInstalledBundle', null, global);
goog.exportSymbol('proto.ProductServerDto.InternalInstalledProductVersion', null, global);
goog.exportSymbol('proto.ProductServerDto.InternalProductVersion', null, global);
goog.exportSymbol('proto.ProductServerDto.InternalPromoCodeUsage', null, global);
goog.exportSymbol('proto.ProductServerDto.InternalUserDevice', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.InternalInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProductServerDto.InternalInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.ProductServerDto.InternalInfoResponse, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.InternalInfoResponse.displayName = 'proto.ProductServerDto.InternalInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.InternalUserDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProductServerDto.InternalUserDevice.repeatedFields_, null);
};
goog.inherits(proto.ProductServerDto.InternalUserDevice, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.InternalUserDevice.displayName = 'proto.ProductServerDto.InternalUserDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.InternalProductVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProductServerDto.InternalProductVersion, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.InternalProductVersion.displayName = 'proto.ProductServerDto.InternalProductVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.InternalPromoCodeUsage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProductServerDto.InternalPromoCodeUsage.repeatedFields_, null);
};
goog.inherits(proto.ProductServerDto.InternalPromoCodeUsage, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.InternalPromoCodeUsage.displayName = 'proto.ProductServerDto.InternalPromoCodeUsage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.EshopProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProductServerDto.EshopProduct, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.EshopProduct.displayName = 'proto.ProductServerDto.EshopProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.InternalInstalledProductVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProductServerDto.InternalInstalledProductVersion.repeatedFields_, null);
};
goog.inherits(proto.ProductServerDto.InternalInstalledProductVersion, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.InternalInstalledProductVersion.displayName = 'proto.ProductServerDto.InternalInstalledProductVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProductServerDto.InternalInstalledBundle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProductServerDto.InternalInstalledBundle, jspb.Message);
if (true) {
  /**
   * @public
   * @override
   */
  proto.ProductServerDto.InternalInstalledBundle.displayName = 'proto.ProductServerDto.InternalInstalledBundle';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProductServerDto.InternalInfoResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.InternalInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.InternalInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.InternalInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsestatus: (f = msg.getResponsestatus()) && dynavix_ResponseStatus_pb.ResponseStatus.toObject(includeInstance, f),
    userdevicesList: jspb.Message.toObjectList(msg.getUserdevicesList(),
    proto.ProductServerDto.InternalUserDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.InternalInfoResponse}
 */
proto.ProductServerDto.InternalInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.InternalInfoResponse;
  return proto.ProductServerDto.InternalInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.InternalInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.InternalInfoResponse}
 */
proto.ProductServerDto.InternalInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dynavix_ResponseStatus_pb.ResponseStatus;
      reader.readMessage(value,dynavix_ResponseStatus_pb.ResponseStatus.deserializeBinaryFromReader);
      msg.setResponsestatus(value);
      break;
    case 2:
      var value = new proto.ProductServerDto.InternalUserDevice;
      reader.readMessage(value,proto.ProductServerDto.InternalUserDevice.deserializeBinaryFromReader);
      msg.addUserdevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.InternalInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.InternalInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.InternalInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsestatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dynavix_ResponseStatus_pb.ResponseStatus.serializeBinaryToWriter
    );
  }
  f = message.getUserdevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ProductServerDto.InternalUserDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseStatus ResponseStatus = 1;
 * @return {?proto.ResponseStatus}
 */
proto.ProductServerDto.InternalInfoResponse.prototype.getResponsestatus = function() {
  return /** @type{?proto.ResponseStatus} */ (
    jspb.Message.getWrapperField(this, dynavix_ResponseStatus_pb.ResponseStatus, 1));
};


/**
 * @param {?proto.ResponseStatus|undefined} value
 * @return {!proto.ProductServerDto.InternalInfoResponse} returns this
*/
proto.ProductServerDto.InternalInfoResponse.prototype.setResponsestatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.InternalInfoResponse} returns this
 */
proto.ProductServerDto.InternalInfoResponse.prototype.clearResponsestatus = function() {
  return this.setResponsestatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInfoResponse.prototype.hasResponsestatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated InternalUserDevice UserDevices = 2;
 * @return {!Array<!proto.ProductServerDto.InternalUserDevice>}
 */
proto.ProductServerDto.InternalInfoResponse.prototype.getUserdevicesList = function() {
  return /** @type{!Array<!proto.ProductServerDto.InternalUserDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProductServerDto.InternalUserDevice, 2));
};


/**
 * @param {!Array<!proto.ProductServerDto.InternalUserDevice>} value
 * @return {!proto.ProductServerDto.InternalInfoResponse} returns this
*/
proto.ProductServerDto.InternalInfoResponse.prototype.setUserdevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ProductServerDto.InternalUserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.InternalUserDevice}
 */
proto.ProductServerDto.InternalInfoResponse.prototype.addUserdevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ProductServerDto.InternalUserDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalInfoResponse} returns this
 */
proto.ProductServerDto.InternalInfoResponse.prototype.clearUserdevicesList = function() {
  return this.setUserdevicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProductServerDto.InternalUserDevice.repeatedFields_ = [1,2,10,11,31,33,35];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.InternalUserDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.InternalUserDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.InternalUserDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalUserDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchasedproductversionsList: jspb.Message.toObjectList(msg.getPurchasedproductversionsList(),
    proto.ProductServerDto.InternalProductVersion.toObject, includeInstance),
    activemapproductcodesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    userdeviceid: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    username: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    hardwareid: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    platformtype: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    devicename: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    lastused: (f = msg.getLastused()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    usedpromocodesList: jspb.Message.toObjectList(msg.getUsedpromocodesList(),
    proto.ProductServerDto.InternalPromoCodeUsage.toObject, includeInstance),
    installedproductversionsList: jspb.Message.toObjectList(msg.getInstalledproductversionsList(),
    proto.ProductServerDto.InternalInstalledProductVersion.toObject, includeInstance),
    firebasetoken: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    enabled: (f = jspb.Message.getBooleanField(msg, 13)) == null ? undefined : f,
    activated: (f = jspb.Message.getBooleanField(msg, 14)) == null ? undefined : f,
    anonymous: (f = jspb.Message.getBooleanField(msg, 15)) == null ? undefined : f,
    appversioncode: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f,
    osversion: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f,
    resolution: (f = jspb.Message.getField(msg, 18)) == null ? undefined : f,
    advertisignid: (f = jspb.Message.getField(msg, 19)) == null ? undefined : f,
    isbeta: (f = jspb.Message.getBooleanField(msg, 20)) == null ? undefined : f,
    appid: (f = jspb.Message.getField(msg, 21)) == null ? undefined : f,
    projecttype: (f = jspb.Message.getField(msg, 22)) == null ? undefined : f,
    locale: (f = jspb.Message.getField(msg, 23)) == null ? undefined : f,
    userid: (f = jspb.Message.getField(msg, 24)) == null ? undefined : f,
    deviceid: (f = jspb.Message.getField(msg, 25)) == null ? undefined : f,
    userenabled: (f = jspb.Message.getBooleanField(msg, 26)) == null ? undefined : f,
    deviceenabled: (f = jspb.Message.getBooleanField(msg, 27)) == null ? undefined : f,
    email: (f = jspb.Message.getField(msg, 28)) == null ? undefined : f,
    ishuaweidevice: (f = jspb.Message.getBooleanField(msg, 29)) == null ? undefined : f,
    huaweitoken: (f = jspb.Message.getField(msg, 30)) == null ? undefined : f,
    rolesList: (f = jspb.Message.getRepeatedField(msg, 31)) == null ? undefined : f,
    deviceabtestgroup: (f = jspb.Message.getField(msg, 32)) == null ? undefined : f,
    supportedarchsList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    currentarch: (f = jspb.Message.getField(msg, 34)) == null ? undefined : f,
    availableproductsList: jspb.Message.toObjectList(msg.getAvailableproductsList(),
    dynavix_ProductInfoCommon_pb.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.InternalUserDevice}
 */
proto.ProductServerDto.InternalUserDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.InternalUserDevice;
  return proto.ProductServerDto.InternalUserDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.InternalUserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.InternalUserDevice}
 */
proto.ProductServerDto.InternalUserDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ProductServerDto.InternalProductVersion;
      reader.readMessage(value,proto.ProductServerDto.InternalProductVersion.deserializeBinaryFromReader);
      msg.addPurchasedproductversions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addActivemapproductcodes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserdeviceid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    case 7:
      var value = /** @type {!proto.AccountServerDto.PlatformType} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicename(value);
      break;
    case 9:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setLastused(value);
      break;
    case 10:
      var value = new proto.ProductServerDto.InternalPromoCodeUsage;
      reader.readMessage(value,proto.ProductServerDto.InternalPromoCodeUsage.deserializeBinaryFromReader);
      msg.addUsedpromocodes(value);
      break;
    case 11:
      var value = new proto.ProductServerDto.InternalInstalledProductVersion;
      reader.readMessage(value,proto.ProductServerDto.InternalInstalledProductVersion.deserializeBinaryFromReader);
      msg.addInstalledproductversions(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebasetoken(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivated(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnonymous(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAppversioncode(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setOsversion(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setResolution(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisignid(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsbeta(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppid(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjecttype(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviceid(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserenabled(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceenabled(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIshuaweidevice(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setHuaweitoken(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    case 32:
      var value = /** @type {!proto.ProductServerDto.ABTestGroup} */ (reader.readEnum());
      msg.setDeviceabtestgroup(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupportedarchs(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentarch(value);
      break;
    case 35:
      var value = new dynavix_ProductInfoCommon_pb.Product;
      reader.readMessage(value,dynavix_ProductInfoCommon_pb.Product.deserializeBinaryFromReader);
      msg.addAvailableproducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.InternalUserDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.InternalUserDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.InternalUserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalUserDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchasedproductversionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ProductServerDto.InternalProductVersion.serializeBinaryToWriter
    );
  }
  f = message.getActivemapproductcodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {!proto.AccountServerDto.PlatformType} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastused();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getUsedpromocodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ProductServerDto.InternalPromoCodeUsage.serializeBinaryToWriter
    );
  }
  f = message.getInstalledproductversionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.ProductServerDto.InternalInstalledProductVersion.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBool(
      14,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBool(
      15,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeString(
      19,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeBool(
      20,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeString(
      22,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeBool(
      26,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeBool(
      27,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeString(
      28,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeBool(
      29,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      31,
      f
    );
  }
  f = /** @type {!proto.ProductServerDto.ABTestGroup} */ (jspb.Message.getField(message, 32));
  if (f != null) {
    writer.writeEnum(
      32,
      f
    );
  }
  f = message.getSupportedarchsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      33,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 34));
  if (f != null) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getAvailableproductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      dynavix_ProductInfoCommon_pb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InternalProductVersion PurchasedProductVersions = 1;
 * @return {!Array<!proto.ProductServerDto.InternalProductVersion>}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getPurchasedproductversionsList = function() {
  return /** @type{!Array<!proto.ProductServerDto.InternalProductVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProductServerDto.InternalProductVersion, 1));
};


/**
 * @param {!Array<!proto.ProductServerDto.InternalProductVersion>} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
*/
proto.ProductServerDto.InternalUserDevice.prototype.setPurchasedproductversionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ProductServerDto.InternalProductVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.InternalProductVersion}
 */
proto.ProductServerDto.InternalUserDevice.prototype.addPurchasedproductversions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ProductServerDto.InternalProductVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearPurchasedproductversionsList = function() {
  return this.setPurchasedproductversionsList([]);
};


/**
 * repeated string ActiveMapProductCodes = 2;
 * @return {!Array<string>}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getActivemapproductcodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setActivemapproductcodesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.addActivemapproductcodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearActivemapproductcodesList = function() {
  return this.setActivemapproductcodesList([]);
};


/**
 * optional int64 UserDeviceId = 4;
 * @return {number}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getUserdeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setUserdeviceid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearUserdeviceid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasUserdeviceid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string Username = 5;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setUsername = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearUsername = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string HardwareId = 6;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setHardwareid = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearHardwareid = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasHardwareid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AccountServerDto.PlatformType PlatformType = 7;
 * @return {!proto.AccountServerDto.PlatformType}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getPlatformtype = function() {
  return /** @type {!proto.AccountServerDto.PlatformType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.AccountServerDto.PlatformType} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setPlatformtype = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearPlatformtype = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasPlatformtype = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string DeviceName = 8;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getDevicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setDevicename = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearDevicename = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasDevicename = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bcl.DateTime LastUsed = 9;
 * @return {?proto.bcl.DateTime}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getLastused = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 9));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
*/
proto.ProductServerDto.InternalUserDevice.prototype.setLastused = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearLastused = function() {
  return this.setLastused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasLastused = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated InternalPromoCodeUsage UsedPromoCodes = 10;
 * @return {!Array<!proto.ProductServerDto.InternalPromoCodeUsage>}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getUsedpromocodesList = function() {
  return /** @type{!Array<!proto.ProductServerDto.InternalPromoCodeUsage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProductServerDto.InternalPromoCodeUsage, 10));
};


/**
 * @param {!Array<!proto.ProductServerDto.InternalPromoCodeUsage>} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
*/
proto.ProductServerDto.InternalUserDevice.prototype.setUsedpromocodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ProductServerDto.InternalPromoCodeUsage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage}
 */
proto.ProductServerDto.InternalUserDevice.prototype.addUsedpromocodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ProductServerDto.InternalPromoCodeUsage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearUsedpromocodesList = function() {
  return this.setUsedpromocodesList([]);
};


/**
 * repeated InternalInstalledProductVersion InstalledProductVersions = 11;
 * @return {!Array<!proto.ProductServerDto.InternalInstalledProductVersion>}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getInstalledproductversionsList = function() {
  return /** @type{!Array<!proto.ProductServerDto.InternalInstalledProductVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProductServerDto.InternalInstalledProductVersion, 11));
};


/**
 * @param {!Array<!proto.ProductServerDto.InternalInstalledProductVersion>} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
*/
proto.ProductServerDto.InternalUserDevice.prototype.setInstalledproductversionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.ProductServerDto.InternalInstalledProductVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion}
 */
proto.ProductServerDto.InternalUserDevice.prototype.addInstalledproductversions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.ProductServerDto.InternalInstalledProductVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearInstalledproductversionsList = function() {
  return this.setInstalledproductversionsList([]);
};


/**
 * optional string FirebaseToken = 12;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getFirebasetoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setFirebasetoken = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearFirebasetoken = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasFirebasetoken = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool Enabled = 13;
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setEnabled = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearEnabled = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasEnabled = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool Activated = 14;
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getActivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setActivated = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearActivated = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasActivated = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool Anonymous = 15;
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getAnonymous = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setAnonymous = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearAnonymous = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasAnonymous = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int32 AppVersionCode = 16;
 * @return {number}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getAppversioncode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setAppversioncode = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearAppversioncode = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasAppversioncode = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string OSVersion = 17;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getOsversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setOsversion = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearOsversion = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasOsversion = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string Resolution = 18;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getResolution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setResolution = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearResolution = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasResolution = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string AdvertisignId = 19;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getAdvertisignid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setAdvertisignid = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearAdvertisignid = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasAdvertisignid = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool IsBeta = 20;
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getIsbeta = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setIsbeta = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearIsbeta = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasIsbeta = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string AppId = 21;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getAppid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setAppid = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearAppid = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasAppid = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string ProjectType = 22;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getProjecttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setProjecttype = function(value) {
  return jspb.Message.setField(this, 22, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearProjecttype = function() {
  return jspb.Message.setField(this, 22, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasProjecttype = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string Locale = 23;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setLocale = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearLocale = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasLocale = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional int64 UserId = 24;
 * @return {number}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setUserid = function(value) {
  return jspb.Message.setField(this, 24, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearUserid = function() {
  return jspb.Message.setField(this, 24, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional int64 DeviceId = 25;
 * @return {number}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setDeviceid = function(value) {
  return jspb.Message.setField(this, 25, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearDeviceid = function() {
  return jspb.Message.setField(this, 25, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasDeviceid = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional bool UserEnabled = 26;
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getUserenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setUserenabled = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearUserenabled = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasUserenabled = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional bool DeviceEnabled = 27;
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getDeviceenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setDeviceenabled = function(value) {
  return jspb.Message.setField(this, 27, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearDeviceenabled = function() {
  return jspb.Message.setField(this, 27, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasDeviceenabled = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional string Email = 28;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 28, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 28, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional bool IsHuaweiDevice = 29;
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getIshuaweidevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setIshuaweidevice = function(value) {
  return jspb.Message.setField(this, 29, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearIshuaweidevice = function() {
  return jspb.Message.setField(this, 29, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasIshuaweidevice = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string HuaweiToken = 30;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getHuaweitoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setHuaweitoken = function(value) {
  return jspb.Message.setField(this, 30, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearHuaweitoken = function() {
  return jspb.Message.setField(this, 30, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasHuaweitoken = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated string Roles = 31;
 * @return {!Array<string>}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 31));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 31, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 31, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional ABTestGroup DeviceABTestGroup = 32;
 * @return {!proto.ProductServerDto.ABTestGroup}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getDeviceabtestgroup = function() {
  return /** @type {!proto.ProductServerDto.ABTestGroup} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.ProductServerDto.ABTestGroup} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setDeviceabtestgroup = function(value) {
  return jspb.Message.setField(this, 32, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearDeviceabtestgroup = function() {
  return jspb.Message.setField(this, 32, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasDeviceabtestgroup = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * repeated string SupportedArchs = 33;
 * @return {!Array<string>}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getSupportedarchsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setSupportedarchsList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.addSupportedarchs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearSupportedarchsList = function() {
  return this.setSupportedarchsList([]);
};


/**
 * optional string CurrentArch = 34;
 * @return {string}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getCurrentarch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.setCurrentarch = function(value) {
  return jspb.Message.setField(this, 34, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearCurrentarch = function() {
  return jspb.Message.setField(this, 34, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalUserDevice.prototype.hasCurrentarch = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * repeated Product AvailableProducts = 35;
 * @return {!Array<!proto.ProductServerDto.Product>}
 */
proto.ProductServerDto.InternalUserDevice.prototype.getAvailableproductsList = function() {
  return /** @type{!Array<!proto.ProductServerDto.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, dynavix_ProductInfoCommon_pb.Product, 35));
};


/**
 * @param {!Array<!proto.ProductServerDto.Product>} value
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
*/
proto.ProductServerDto.InternalUserDevice.prototype.setAvailableproductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.ProductServerDto.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.Product}
 */
proto.ProductServerDto.InternalUserDevice.prototype.addAvailableproducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.ProductServerDto.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalUserDevice} returns this
 */
proto.ProductServerDto.InternalUserDevice.prototype.clearAvailableproductsList = function() {
  return this.setAvailableproductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.InternalProductVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.InternalProductVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.InternalProductVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalProductVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    productversionid: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    productname: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    eshopproductid: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    validfrom: (f = msg.getValidfrom()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    validto: (f = msg.getValidto()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    transactiontime: (f = msg.getTransactiontime()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    udi: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    udpid: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    udpvid: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    enabled: (f = jspb.Message.getBooleanField(msg, 11)) == null ? undefined : f,
    merged: (f = jspb.Message.getBooleanField(msg, 12)) == null ? undefined : f,
    udpenabled: (f = jspb.Message.getBooleanField(msg, 13)) == null ? undefined : f,
    udpactivated: (f = jspb.Message.getBooleanField(msg, 14)) == null ? undefined : f,
    version: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    revision: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.InternalProductVersion}
 */
proto.ProductServerDto.InternalProductVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.InternalProductVersion;
  return proto.ProductServerDto.InternalProductVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.InternalProductVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.InternalProductVersion}
 */
proto.ProductServerDto.InternalProductVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductversionid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEshopproductid(value);
      break;
    case 5:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setValidfrom(value);
      break;
    case 6:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setValidto(value);
      break;
    case 7:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setTransactiontime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUdi(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUdpid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUdpvid(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMerged(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpenabled(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpactivated(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRevision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.InternalProductVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.InternalProductVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.InternalProductVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalProductVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getValidfrom();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getValidto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getTransactiontime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBool(
      14,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeInt32(
      16,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 ProductVersionId = 2;
 * @return {number}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getProductversionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setProductversionid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearProductversionid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasProductversionid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string ProductName = 3;
 * @return {string}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getProductname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setProductname = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearProductname = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasProductname = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 EshopProductId = 4;
 * @return {number}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getEshopproductid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setEshopproductid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearEshopproductid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasEshopproductid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bcl.DateTime ValidFrom = 5;
 * @return {?proto.bcl.DateTime}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getValidfrom = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 5));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
*/
proto.ProductServerDto.InternalProductVersion.prototype.setValidfrom = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearValidfrom = function() {
  return this.setValidfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasValidfrom = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bcl.DateTime ValidTo = 6;
 * @return {?proto.bcl.DateTime}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getValidto = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 6));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
*/
proto.ProductServerDto.InternalProductVersion.prototype.setValidto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearValidto = function() {
  return this.setValidto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasValidto = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bcl.DateTime TransactionTime = 7;
 * @return {?proto.bcl.DateTime}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getTransactiontime = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 7));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
*/
proto.ProductServerDto.InternalProductVersion.prototype.setTransactiontime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearTransactiontime = function() {
  return this.setTransactiontime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasTransactiontime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 Udi = 8;
 * @return {number}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getUdi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setUdi = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearUdi = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasUdi = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 UdpId = 9;
 * @return {number}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getUdpid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setUdpid = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearUdpid = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasUdpid = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 UdpvId = 10;
 * @return {number}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getUdpvid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setUdpvid = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearUdpvid = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasUdpvid = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool Enabled = 11;
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setEnabled = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearEnabled = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasEnabled = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool Merged = 12;
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getMerged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setMerged = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearMerged = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasMerged = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool UdpEnabled = 13;
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getUdpenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setUdpenabled = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearUdpenabled = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasUdpenabled = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool UdpActivated = 14;
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getUdpactivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setUdpactivated = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearUdpactivated = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasUdpactivated = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int32 Version = 15;
 * @return {number}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int32 Revision = 16;
 * @return {number}
 */
proto.ProductServerDto.InternalProductVersion.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.setRevision = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalProductVersion} returns this
 */
proto.ProductServerDto.InternalProductVersion.prototype.clearRevision = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalProductVersion.prototype.hasRevision = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProductServerDto.InternalPromoCodeUsage.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.InternalPromoCodeUsage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.InternalPromoCodeUsage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalPromoCodeUsage.toObject = function(includeInstance, msg) {
  var f, obj = {
    used: (f = msg.getUsed()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f),
    username: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    userdeviceid: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    deviceid: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    devicename: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    platformtype: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    userid: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    hardwareid: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.ProductServerDto.EshopProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage}
 */
proto.ProductServerDto.InternalPromoCodeUsage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.InternalPromoCodeUsage;
  return proto.ProductServerDto.InternalPromoCodeUsage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.InternalPromoCodeUsage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage}
 */
proto.ProductServerDto.InternalPromoCodeUsage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setUsed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserdeviceid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviceid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicename(value);
      break;
    case 6:
      var value = /** @type {!proto.AccountServerDto.PlatformType} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 10:
      var value = new proto.ProductServerDto.EshopProduct;
      reader.readMessage(value,proto.ProductServerDto.EshopProduct.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.InternalPromoCodeUsage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.InternalPromoCodeUsage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalPromoCodeUsage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {!proto.AccountServerDto.PlatformType} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ProductServerDto.EshopProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional bcl.DateTime Used = 1;
 * @return {?proto.bcl.DateTime}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getUsed = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 1));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
*/
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setUsed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearUsed = function() {
  return this.setUsed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasUsed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setUsername = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearUsername = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 UserDeviceId = 3;
 * @return {number}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getUserdeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setUserdeviceid = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearUserdeviceid = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasUserdeviceid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 DeviceId = 4;
 * @return {number}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setDeviceid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearDeviceid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasDeviceid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string DeviceName = 5;
 * @return {string}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getDevicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setDevicename = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearDevicename = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasDevicename = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AccountServerDto.PlatformType PlatformType = 6;
 * @return {!proto.AccountServerDto.PlatformType}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getPlatformtype = function() {
  return /** @type {!proto.AccountServerDto.PlatformType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.AccountServerDto.PlatformType} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setPlatformtype = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearPlatformtype = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasPlatformtype = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 UserId = 7;
 * @return {number}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setUserid = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearUserid = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string HardwareId = 8;
 * @return {string}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setHardwareid = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearHardwareid = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasHardwareid = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string Code = 9;
 * @return {string}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearCode = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.hasCode = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated EshopProduct Products = 10;
 * @return {!Array<!proto.ProductServerDto.EshopProduct>}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.ProductServerDto.EshopProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProductServerDto.EshopProduct, 10));
};


/**
 * @param {!Array<!proto.ProductServerDto.EshopProduct>} value
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
*/
proto.ProductServerDto.InternalPromoCodeUsage.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ProductServerDto.EshopProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.EshopProduct}
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ProductServerDto.EshopProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalPromoCodeUsage} returns this
 */
proto.ProductServerDto.InternalPromoCodeUsage.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.EshopProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.EshopProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.EshopProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.EshopProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    eshopproductid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    sku: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.EshopProduct}
 */
proto.ProductServerDto.EshopProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.EshopProduct;
  return proto.ProductServerDto.EshopProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.EshopProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.EshopProduct}
 */
proto.ProductServerDto.EshopProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEshopproductid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.EshopProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.EshopProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.EshopProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.EshopProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 EshopProductId = 1;
 * @return {number}
 */
proto.ProductServerDto.EshopProduct.prototype.getEshopproductid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.EshopProduct} returns this
 */
proto.ProductServerDto.EshopProduct.prototype.setEshopproductid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.EshopProduct} returns this
 */
proto.ProductServerDto.EshopProduct.prototype.clearEshopproductid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.EshopProduct.prototype.hasEshopproductid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.ProductServerDto.EshopProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.EshopProduct} returns this
 */
proto.ProductServerDto.EshopProduct.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.EshopProduct} returns this
 */
proto.ProductServerDto.EshopProduct.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.EshopProduct.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Sku = 3;
 * @return {string}
 */
proto.ProductServerDto.EshopProduct.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.EshopProduct} returns this
 */
proto.ProductServerDto.EshopProduct.prototype.setSku = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.EshopProduct} returns this
 */
proto.ProductServerDto.EshopProduct.prototype.clearSku = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.EshopProduct.prototype.hasSku = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProductServerDto.InternalInstalledProductVersion.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.InternalInstalledProductVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.InternalInstalledProductVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalInstalledProductVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    bundlesList: jspb.Message.toObjectList(msg.getBundlesList(),
    proto.ProductServerDto.InternalInstalledBundle.toObject, includeInstance),
    productversionid: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    version: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    lastupdated: (f = msg.getLastupdated()) && dynavix_bcl_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion}
 */
proto.ProductServerDto.InternalInstalledProductVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.InternalInstalledProductVersion;
  return proto.ProductServerDto.InternalInstalledProductVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.InternalInstalledProductVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion}
 */
proto.ProductServerDto.InternalInstalledProductVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ProductServerDto.InternalInstalledBundle;
      reader.readMessage(value,proto.ProductServerDto.InternalInstalledBundle.deserializeBinaryFromReader);
      msg.addBundles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductversionid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 5:
      var value = new dynavix_bcl_pb.DateTime;
      reader.readMessage(value,dynavix_bcl_pb.DateTime.deserializeBinaryFromReader);
      msg.setLastupdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.InternalInstalledProductVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.InternalInstalledProductVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalInstalledProductVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBundlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ProductServerDto.InternalInstalledBundle.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLastupdated();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dynavix_bcl_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InternalInstalledBundle Bundles = 1;
 * @return {!Array<!proto.ProductServerDto.InternalInstalledBundle>}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.getBundlesList = function() {
  return /** @type{!Array<!proto.ProductServerDto.InternalInstalledBundle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProductServerDto.InternalInstalledBundle, 1));
};


/**
 * @param {!Array<!proto.ProductServerDto.InternalInstalledBundle>} value
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
*/
proto.ProductServerDto.InternalInstalledProductVersion.prototype.setBundlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ProductServerDto.InternalInstalledBundle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProductServerDto.InternalInstalledBundle}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.addBundles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ProductServerDto.InternalInstalledBundle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.clearBundlesList = function() {
  return this.setBundlesList([]);
};


/**
 * optional int64 ProductVersionId = 2;
 * @return {number}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.getProductversionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.setProductversionid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.clearProductversionid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.hasProductversionid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 Version = 4;
 * @return {number}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bcl.DateTime LastUpdated = 5;
 * @return {?proto.bcl.DateTime}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.getLastupdated = function() {
  return /** @type{?proto.bcl.DateTime} */ (
    jspb.Message.getWrapperField(this, dynavix_bcl_pb.DateTime, 5));
};


/**
 * @param {?proto.bcl.DateTime|undefined} value
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
*/
proto.ProductServerDto.InternalInstalledProductVersion.prototype.setLastupdated = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProductServerDto.InternalInstalledProductVersion} returns this
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.clearLastupdated = function() {
  return this.setLastupdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInstalledProductVersion.prototype.hasLastupdated = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.toObject = function(opt_includeInstance) {
  return proto.ProductServerDto.InternalInstalledBundle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProductServerDto.InternalInstalledBundle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalInstalledBundle.toObject = function(includeInstance, msg) {
  var f, obj = {
    bundleid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    version: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    revision: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProductServerDto.InternalInstalledBundle}
 */
proto.ProductServerDto.InternalInstalledBundle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProductServerDto.InternalInstalledBundle;
  return proto.ProductServerDto.InternalInstalledBundle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProductServerDto.InternalInstalledBundle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProductServerDto.InternalInstalledBundle}
 */
proto.ProductServerDto.InternalInstalledBundle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBundleid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRevision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProductServerDto.InternalInstalledBundle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProductServerDto.InternalInstalledBundle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProductServerDto.InternalInstalledBundle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int64 BundleId = 1;
 * @return {number}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.getBundleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalInstalledBundle} returns this
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.setBundleid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalInstalledBundle} returns this
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.clearBundleid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.hasBundleid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProductServerDto.InternalInstalledBundle} returns this
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalInstalledBundle} returns this
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 Version = 3;
 * @return {number}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalInstalledBundle} returns this
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalInstalledBundle} returns this
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 Revision = 4;
 * @return {number}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProductServerDto.InternalInstalledBundle} returns this
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.setRevision = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ProductServerDto.InternalInstalledBundle} returns this
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.clearRevision = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProductServerDto.InternalInstalledBundle.prototype.hasRevision = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.ProductServerDto.ABTestGroup = {
  ABTESTGROUPNOTSET: 0,
  A_TRIAL5DAYS: 1,
  B_TRIAL10DAYS: 2,
  C_TRIAL20DAYS: 3
};

goog.object.extend(exports, proto.ProductServerDto);
