import { AfterContentInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12;
  public isLangCZ: boolean

  public year: number = new Date().getFullYear();

  constructor(public translateService: TranslateService) { }

  ngOnInit() { 
    this.isLangCZ = false
    if(this.translateService.currentLang === 'cz') {
      this.isLangCZ = true
    }

    this.translateService.onLangChange.subscribe(() => {
      this.isLangCZ = false
      if(this.translateService.currentLang === 'cz') {
        this.isLangCZ = true
      }
    })
  }
  
  subscribe(){ }
}