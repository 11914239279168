import { Address, BillingAddress } from './order';
export class UserInfo {
  username: string;
  showedname: string;
  roles: UserRole[];
}

export class UserInfoWithAddresses {
  userInfo: UserInfo;
  billingAddress: BillingAddress;
  deliveryAddress: Address;
}


export enum UserRole {
  ESHOP_DEVELOPER = "eshop developer",
  CUSTOMER = "customer",
  GUEST = "guest"
}