import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { filter, lastValueFrom, map } from 'rxjs';
import { AppService } from './app.service';
import { AuthOperations } from './basics/operations/auth.operations';
import { MatIconRegistry } from "@angular/material/icon";
import { NgcCookieConsentService } from 'ngx-cookieconsent';

const URL = 'assets/languages-list.json';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  loading: boolean = false;
  public settings: Settings;
  languageUtils: any;

  constructor(
    public appSettings: AppSettings, 
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private appService: AppService,
    private authService: AuthOperations,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    // private ccService: NgcCookieConsentService
  ){
    this.matIconRegistry.addSvgIcon(
      `demo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/demo-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `shopping-basket`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/basket-icon.svg")
    );

    this.settings = this.appSettings.settings;
    const currency = localStorage.getItem('currency') 

    if(currency === null) {
      localStorage.setItem('currency', 'CZE') 
      this.appService.setCurrentCurrency('CZE')
    } else {
      localStorage.setItem('currency', currency) 
      this.appService.setCurrentCurrency(currency)
    }
    
  }

  async ngOnInit(): Promise<void> {
    this.languageUtils = await lastValueFrom(this.httpClient.get(URL))
    this.translate.addLangs(this.languageUtils.map(it => it.code));

    if(localStorage.getItem('dynavix-web-language')) {
      this.translate.use(localStorage.getItem('dynavix-web-language'));
    } else {
      this.setDefaultLang();
    }
    this.translate.setDefaultLang('en');

    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });

    this.authService.getCurrentUserAsObservable().subscribe()
  }

  private setDefaultLang(): void {
    const browserLang = navigator.language.toLowerCase().slice(0,2);
    this.languageUtils.every(lang => {
      if(browserLang === lang.code.slice(0,2)) {
        this.translate.use(lang.code);
        localStorage.setItem('dynavix-web-language', lang.code)
        return false;
      }
      return true;
    })

    if(!(localStorage.getItem('dynavix-web-language'))) {
      this.translate.use('cz');
      localStorage.setItem('dynavix-web-language', 'cz')
    } else if(localStorage.getItem('dynavix-web-language') !== 'cz' && this.router.url.includes('.com')) {
      this.translate.use('en');
      localStorage.setItem('dynavix-web-language', 'en')
    }
      
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        } 
      }
    })  
  }
}
