import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-added-product-to-cart-dialog',
  templateUrl: './added-product-to-cart-dialog.component.html',
  styleUrls: ['./added-product-to-cart-dialog.component.scss']
})
export class AddedProductToCartDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddedProductToCartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  onNavigateToCart(): void {
    this.router.navigate(['/cart'])
    this.dialogRef.close(true);
  }

  onContinueShopping(): void {
    this.dialogRef.close(false);
  }

}
