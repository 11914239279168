<!--<app-main-cards></app-main-cards>-->
<app-info-stripe (buttonClickEvent)="startDemo()" [imgUrl]="'assets/images/dynavix/Fleet.png'"
    [heading]="'HOME.CARD.TITLE1' | translate" [paragraph]="'HOME.CARD.PARAGRAPH1' | translate"
    [button]="'HOME.FLEET-DEMO'" [class]="'img-width-85 left'">

</app-info-stripe>
<app-info-stripe [imgUrl]="'assets/images/dynavix/Navigace.png'" [heading]="'HOME.CARD.TITLE2' | translate"
    [paragraph]="'HOME.CARD.PARAGRAPH2' | translate" [button]="'HOME.MORE-INFO'" link="/products"
    [layout]="'row-reverse wrap'" [class]="'img-width-85 right'">

</app-info-stripe>
<app-contact-us></app-contact-us>
<app-about-us></app-about-us>
<div class="divider2 theme-container"></div>
<app-partners></app-partners>
<div class="divider2 theme-container"></div>
<h2 style="text-align: center; margin-top: 2rem; font-size: 2em !important;">{{ 'HOME.INFO-STRIPES.TITLE' | translate }}</h2>
<app-info-stripe [imgUrl]="'assets/images/dynavix/stripes/01.png'" [heading]="'HOME.INFO-STRIPES.HEADING1' | translate"
    [paragraph]="'HOME.INFO-STRIPES.PARAGRAPH1' | translate">
</app-info-stripe>
<app-info-stripe [imgUrl]="'assets/images/dynavix/stripes/02.png'" [heading]="'HOME.INFO-STRIPES.HEADING2' | translate"
    [paragraph]="'HOME.INFO-STRIPES.PARAGRAPH2' | translate" [layout]="'row-reverse wrap'"> 
</app-info-stripe>
<app-info-stripe [imgUrl]="'assets/images/dynavix/stripes/03.png'" [heading]="'HOME.INFO-STRIPES.HEADING3' | translate"
    [paragraph]="'HOME.INFO-STRIPES.PARAGRAPH3' | translate">
</app-info-stripe>
<app-info-stripe [layout]="'row-reverse wrap'" [imgUrl]="'assets/images/dynavix/stripes/04.png'"
    [heading]="'HOME.INFO-STRIPES.HEADING4' | translate" [paragraph]="'HOME.INFO-STRIPES.PARAGRAPH4' | translate">
</app-info-stripe>
<!-- <div class="theme-container">

    <div fxLayout="row wrap" fxLayout.xs="column" class="info-bar">
        <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50">
            <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="start center">
                <div>
                    <div fxFlex="16">
                        <img src="../../assets/images/dynavix/dynavix-logo-simple.png" alt="Dynavix GPS navigace a mapy"
                            border="0" class="logo-dynavix">
                    </div>
                    <div fxFlex="84" class="content">
                        <span class="header1">{{'HOME.DYNAVIX_GPS' | transla</span>
                    </div>
                </div>

                <p class="check mt-2">{{'HOME.FREE-NAV-MAPS' | transla</p>
                <p class="check">{{'HOME.FREE-MAP-UPDATES' | transla.</p>
                <p class="check">{{'HOME.REAL-TIME-INFO' | transla</p>
                <p class="check">{{'HOME.TRAFFIC-CAMERAS' | transla</p>
                <p class="check">{{'HOME.LANE-ASSISTANT' | transla</p>
                <p class="check">{{'HOME.MAP-DETAILS' | transla</p>
                <p class="check">{{'HOME.VOICE-NAV' | transla</p>
                <div class="download-btn">
                    <a class="readmore" routerLink="products">
                        {{'HOME.MORE-INFO' | transla
                        <mat-icon class="icon" color="white">keyboard_arrow_right</mat-icon>
                    </a>
                </div>
            </mat-card>
        </div>
        <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50">
            <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="start center">
                <img [attr.src]="getAndroidTitul()" alt="Dynavix GPS navigace a mapy" border="0"
                    class="example-android">
                <a href="https://play.google.com/store/apps/details?id=com.dynavix.navigation" target="_blank"
                    rel="noopener noreferrer">
                    <img [attr.src]="getGooglePlayBadge()" alt="Dostupné na Google Play" border="0"
                        class="mat-icon-xlg text-muted m-2 badge">
                </a>
                <a href="https://apps.apple.com/us/app/dynavix-gps-navigation-maps/id1458876641" target="_blank"
                    rel="noopener noreferrer">
                    <img [attr.src]="getAppStoreBadge()" alt="Dostupné v App Store" border="0"
                        class="mat-icon-xlg text-muted m-2 badge">
                </a>
                <a href="https://appgallery.cloud.huawei.com/marketshare/app/C102250503" target="_blank"
                    rel="noopener noreferrer">
                    <img [attr.src]="getAppGalleryBadge()" alt="Dostupné v AppGallery" border="0"
                        class="mat-icon-xlg text-muted m-2 badge">
                </a>
            </mat-card>
        </div>
    </div>

    <div class="relative-position z-2" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between">
        <mat-card class="menu-item flex-item">
            <a style="text-decoration: none;"
                href="javascript:document.getElementById('traffic_cameras').scrollIntoView(true);" class="mid-menu">
                <span>{{'HOME.MID-MENU.TRAFFIC_CAMERAS' | transla</span>
            </a>
        </mat-card>
        <mat-card class="menu-item flex-item">
            <a style="text-decoration: none;"
                href="javascript:document.getElementById('traffic_info').scrollIntoView(true);" class="mid-menu">
                <span>{{'HOME.MID-MENU.TRAFFIC_INFORMATION' | transla</span>
            </a>
        </mat-card>
        <mat-card class="menu-item flex-item">
            <a style="text-decoration: none;"
                href="javascript:document.getElementById('destination').scrollIntoView(true);" class="mid-menu">
                <span>{{'HOME.MID-MENU.DESTINATION' | transla</span>
            </a>
        </mat-card>
        <mat-card class="menu-item flex-item">
            <a style="text-decoration: none;"
                href="javascript:document.getElementById('alt-routes').scrollIntoView(true);" class="mid-menu">
                <span>{{'HOME.MID-MENU.ALTERNATIVE_ROUTES' | transla</span>
            </a>
        </mat-card>
        <div class="absolute grey-backgorund z-1"></div>
    </div>

    <div class="wrapper relative-position" fxLayoutAlign="center">
        <div class="grayfill-transparent" ngClass.xs="grayfill-transparent-xs">
            <h2 class="headline-greyfill">{{'HOME.INFO-SECTIONS.3D-GRAPHIC.HEADLINE' | transla</h2>
            <p>&nbsp;</p>
            <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.3D-GRAPHIC.PAR1' | translate"></p>
            <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.3D-GRAPHIC.PAR2' | translate"></p>
            <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.3D-GRAPHIC.PAR3' | translate"></p>
        </div>
        <div class="absolute-position img-backgorund z-1"></div>
    </div>

    <div id="traffic_cameras" fxLayout="column" fxLayoutAlign="start center" class="info-section">
        <h2 fxFlex="100" class="headline-h2">{{'HOME.INFO-SECTIONS.TRAFFIC-CAMERAS.HEADLINE' | transla</h2>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between" class="section-content">
            <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
                <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="start center">
                    <img [attr.src]="getIndexImg()" alt="Dynavix GPS navigace a mapy" border="0" class="img-height-100">
                </mat-card>
            </div>
            <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
                <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="center">
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.TRAFFIC-CAMERAS.PAR1' | translate"></p>
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.TRAFFIC-CAMERAS.PAR3' | translate"></p>
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.TRAFFIC-CAMERAS.PAR2' | translate"></p>
                </mat-card>
            </div>
        </div>
    </div>

    <div id="traffic_info" class="relative-position z-2 info-section" fxLayout="column wrap"
        fxLayoutAlign="start center">
        <h2 fxFlex="100" class="headline-h2">{{'HOME.INFO-SECTIONS.REAL-TIME-TRAFFIC-INFO.HEADLINE' | transla</h2>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between" class="section-content">
            <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
                <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="center">
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.REAL-TIME-TRAFFIC-INFO.PAR1' | translate">
                    </p>
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.REAL-TIME-TRAFFIC-INFO.PAR2' | translate">
                    </p>
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.REAL-TIME-TRAFFIC-INFO.PAR3' | translate">
                    </p>
                </mat-card>
            </div>
            <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
                <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="start center">
                    <img [attr.src]="getTrafficInfoExampleImg()" alt="Dynavix GPS navigace a mapy" border="0"
                        class="img-height-100">
                </mat-card>
            </div>
        </div>
        <div class="absolute-position grey-backgorund z-1"></div>
    </div>

    <div id="destination" fxLayout="column" fxLayoutAlign="start center" class="info-section">
        <h2 fxFlex="100" class="headline-h2">{{'HOME.INFO-SECTIONS.FULLTEXT-SEARCH.HEADLINE' | transla</h2>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between" class="section-content">
            <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
                <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="start center">
                    <img src="../../assets/images/dynavix/fulltext-example.jpg" alt="Dynavix GPS navigace a mapy"
                        border="0" class="img-height-100">
                </mat-card>
            </div>
            <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
                <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="center">
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.FULLTEXT-SEARCH.PAR1' | translate"></p>
                </mat-card>
            </div>
        </div>
    </div>

    <div id="alt-routes" fxLayout="column" fxLayoutAlign="start center" class="relative-position z-2 info-section">
        <h2 fxFlex="100" class="headline-h2">{{'HOME.INFO-SECTIONS.ALT-ROUTES.HEADLINE' | transla</h2>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between" class="section-content">
            <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
                <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="center">
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.ALT-ROUTES.PAR1' | translate"></p>
                    <p class="check-light" [innerHTML]="'HOME.INFO-SECTIONS.ALT-ROUTES.PAR2' | translate"></p>
                </mat-card>
            </div>
            <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
                <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="start center">
                    <img src="../../assets/images/dynavix/alt-routes-example-en.jpg" alt="Dynavix GPS navigace a mapy"
                        border="0" class="img-height-100">
                </mat-card>
            </div>
        </div>
        <div class="absolute-position grey-backgorund z-1"></div>
    </div>

    <app-brands-carousel [brands]="brands" [title]="'HOME.EXAMPLE-SCREENS'"></app-brands-carousel>
</div> -->