import { Pipe } from "@angular/core";
import { AppService } from "src/app/app.service";

@Pipe({
  name: 'currencyCustom'
})
export class CurrencyPipe {
  constructor(
    private appService: AppService
  ) {}

  transform(val: number, code: string) {
    if(!val) {
      return ''
    }
    // console.log(val , code);
    
    let retVal = val.toString()

    switch(code) {
      case 'CZE': {
        retVal = retVal.replace(',', ' ').replace('.', ',').concat(' Kč')
        break
      }
      case 'USD': {
        retVal = '$'.concat(retVal)
        break
      }
      case 'EUR': {
        retVal = '€'.concat(retVal)
        break
      } 
      case 'GBP': {
        retVal = '£'.concat(retVal)
        break
      }
      default: {
        retVal = retVal.replace(',', ' ').replace('.', ',').concat(' Kč')
      }
    }
    return retVal
  }
}