import { ViewportScroller, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Category } from '../app.models';
import { AppService } from '../app.service';
import { AppSettings, Settings } from '../app.settings';
import { CurrencyChangeSyncOperations } from '../basics/operations/currency-change-sync.operations';
import { ProductUtil } from '../data/api/eshop/model/products';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { CurrencyPipe } from '../theme/pipes/currency.pipe';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [SidenavMenuService]
})
export class PagesComponent implements OnInit, AfterViewInit {
  public showBackToTop: boolean = false;
  public categories: Category[];
  public category: Category;
  public sidenavMenuItems: Array<any>;
  public changes: any;
  private change: boolean = false;

  @ViewChild('sidenav', { static: true }) sidenav: any;

  public grandTotalAsString: string

  private subscriptionCurrencyChange: Subscription;
  private subscriptionCartChange: Subscription;

  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    public sidenavMenuService: SidenavMenuService,
    public router: Router,
    private translationService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public productUtil: ProductUtil,
    private currencyPipe: CurrencyPipe,
    private currencyChangeService: CurrencyChangeSyncOperations,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private vps: ViewportScroller
  ) {
    this.settings = this.appSettings.settings;
    this.subscriptionCurrencyChange = this.currencyChangeService.currencyChange.subscribe(() => {
      var grandTotal = 0
      this.appService.Data.cartList.forEach(product => {
        grandTotal += product.cartCount * this.productUtil.getNewPrice(product);
        this.grandTotalAsString = this.getTotalInCurrency(grandTotal)
      })

      this.grandTotalAsString = this.getTotalInCurrency(this.appService.Data.totalPrice)
      this.cdr.markForCheck()
    })

    this.subscriptionCartChange = this.appService.subject.subscribe(() => {
      var grandTotal = 0
      this.appService.Data.cartList.forEach(product => {
        grandTotal += product.cartCount * this.productUtil.getNewPrice(product);
        this.grandTotalAsString = this.getTotalInCurrency(grandTotal)
      })

      this.grandTotalAsString = this.getTotalInCurrency(this.appService.Data.totalPrice)
      this.cdr.markForCheck()
    })
  }

  ngOnInit() {
    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    // setTimeout(() => {
    //   this.settings.theme = 'green'; 
    // });
    this.grandTotalAsString = this.getTotalInCurrency(this.appService.Data.totalPrice)

    // this.router.events.subscribe((event) => {
    // 
    // if (event instanceof NavigationEnd) {
    //   this.closeSubMenus();
    //   this.scrollToTopInstant();
    //   window.scrollTo(0, 0);
    // }

    // 
    // })

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
        // window.scrollBy(0, -window.scrollY);
        // this.scrollToTop()
        // window.scrollTo(0, 0);
        // setTimeout(() => {
        //   this.scrollToTop()
        //   this.cdr.markForCheck();
        // }, 1000);
        this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
        this.cdr.markForCheck();
      }
    });
  }

  public getCategories() {
    this.appService.getCategories().subscribe(data => {
      this.categories = data;
      this.category = data[0];
      this.appService.Data.categories = data;
    })
  }

  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public remove(product) {
    // const index: number = this.appService.Data.cartList.indexOf(product);
    // if (index !== -1) {
    //     this.appService.Data.cartList.splice(index, 1);
    //     this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice*product.cartCount;
    //     this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
    //     this.appService.resetProductCartCount(product);
    // }        
    this.appService.removeFromCart(product)
  }

  public clear() {
    this.appService.Data.cartList.forEach(product => {
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
    localStorage.removeItem('cart')
  }


  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public search() { }


  public scrollToTopInstant() {
    // window.scrollTo(0, 0);
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    //   console.log("general kenobi!");
    // }, 100)
    // console.log("hello there!");
  }

  public scrollToTop() {
    // this.ngZone.run(() => {
      // Code executed inside NgZone
      // Angular change detection is triggered
      var scrollDuration = 1;
      var scrollStep = -window.scrollY / (scrollDuration / 1000);
      var scrollInterval = setInterval(() => {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep);
        }
        else {
          // this.router.navigate(['']);
          clearInterval(scrollInterval);
        }
      }, 1);
      if (window.innerWidth <= 768) {
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
        });
      }
    // });
  }

  public scrollToTopAndNavigate() {
    // this.ngZone.run(() => {
      // Code executed inside NgZone
      // Angular change detection is triggered
      // var scrollDuration = 1;
      window.scrollBy(0, -window.scrollY);
      setTimeout(() => {
        this.router.navigate(['']);
      }, 100);
      // var scrollStep = -window.scrollY / (scrollDuration / 1000);
      // var scrollInterval = setInterval(() => {
      //   if (window.scrollY != 0) {
      //   }
      //   else {
      //     clearInterval(scrollInterval);
      //   }
      // }, 1);
      if (window.innerWidth <= 768) {
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
        });
      }
    // });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const scrollTop = Math.max(window.scrollY, document.documentElement.scrollTop, document.body.scrollTop);
    let header_toolbar = document.getElementById('header-toolbar');
    if (header_toolbar) {
      if (scrollTop >= header_toolbar.clientHeight) {
        this.settings.mainToolbarFixed = true;
      }
      else {
        if (!document.documentElement.classList.contains('cdk-global-scrollblock')) {
          this.settings.mainToolbarFixed = false;
        }
      }
    }
    else {
      this.settings.mainToolbarFixed = true;
    }
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.sidenav.close();
    //     window.scrollTo(0,0);
    //   }
    // });
    // this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());

  }

  public closeSubMenus() {
    // this.change = !this.change;
    // this.changes = this.change;
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  public getLogo(): string {
    const lang = this.translationService.currentLang
    switch (lang) {
      case 'cz': {
        return "../../assets/images/dynavix/dynavix-logo-cz.png"
      }
      case 'en': {
        return "../../assets/images/dynavix/dynavix-logo-en.png"
      }
      default: return "../../assets/images/dynavix/dynavix-logo-en.png"
    }
  }

  public navigetToCart() {
    this.router.navigate(['/cart'])
  }

  public getTotalInCurrency(total: number) {
    if (!total) {
      return
    }
    this.appService.resetTotal()
    // console.log(total, this.appService.getCurrentCurrency());

    return this.currencyPipe.transform(Number(total.toFixed(2)), localStorage.getItem('currency'))
  }

}