import { Product } from "./products";

export class Order {
  items: Item[];    
  state: OrderState;
  billingAddress: BillingAddress;
  deliveryAddress: Address;
  paymentMethod: PaymentMethod;
}

export class Item {
  product: Product;
  ammount: number;
}

export class Address {
  country: string;
  city: string;
  zip: string;
  address: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export class BillingAddress extends Address {
  company: string;
  businessID: string;
  taxID: string;
  billingName: string;
}

export enum OrderState {
  UNPAID = 1,
  PAIDANDWAITING = 2,
  DELIVERED = 3,
}

export enum PaymentMethod {
  CASHONDELIVERY = 1,
  TRANSFER = 2,
  CREDITCARD = 3,
}