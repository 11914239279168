import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Category } from 'src/app/app.models';
import { HttpManager } from 'src/app/basics/utils/http-request-sender';
import { environment } from 'src/environments/environment';
import { AuthTokens } from '@model/auth';
import { Product } from '@model/products';
import { AuthUserDeviceResponse } from '../api/eshop/proto/generated/dynavix/AuthUserDeviceResponse_pb';
import { AuthUserDevice } from '../api/eshop/proto/generated/dynavix/AuthUserDevice_pb';
import { AuthMapper } from '../mappers/auth-mapper';
import { ProductsOperations } from '@operations/products.operations';

const url = environment.url + '/assets/data/';
const proxyServerURL = "https://api.fleet.dynavix.com/http-server-for-web/"
// const proxyServerURL = "http://localhost:8090/"

const DPHrate = 1.21

@Injectable({
  providedIn: 'root'
})
export class ProductsService implements ProductsOperations {

  constructor(
    private httpManager: HttpManager,
  ) { }

  public async getProductCategories(): Promise<Category[]> {
    return await this.makeCallToDB("categories")
      .then(data => {
        let categories: Category[] = data.sort((a, b) => b.ordering - a.oredering)
          .map(category => new Category(category.virtuemart_category_id, category.i18n_key, false, category.category_parent_id))
        categories.forEach(category => category.hasSubCategory = categories.some(categoryChild => categoryChild.parentId === category.id))
        categories.push({ "id": 0, "name": "E-SHOP.NAVBAR.ALL-CATEGORIES", "hasSubCategory": true, "parentId": -1 })
        return categories
      });
  }

  private async makeCallToDB(query: string): Promise<any> {

    try {
      const retVal = await fetch(proxyServerURL + query, {
        method: "POST",
        headers: { "Content-type": "application/json" }
      });
      const textDecoder = new TextDecoder();
      const reader = retVal.body.getReader();

      let data = '';
      const readNextChunk = () => {
        return reader.read().then(({ done, value }) => {
          if (done) {
            return JSON.parse(data).values;
          } else {
            data += textDecoder.decode(value);
            return readNextChunk();
          }
        });
      };

      return readNextChunk();

    } catch (e) {
      console.error(e);
    }
  }

  public getProductsLocalized(lang: string, category: string): Observable<Product[]> {
    throw new Error('Method not implemented.');
  }

  public getSessionId(username: string, password: string): Observable<AuthTokens> {
    let request = new AuthUserDevice();
    request.setUsername(username);
    request.setPassword(password);

    var subject = new Subject<AuthTokens>();

    this.httpManager.sendRequest(request, AuthUserDeviceResponse).subscribe({
      next: (data: AuthUserDeviceResponse) => {
        const mapper = new AuthMapper();
        var retVal = mapper.mapToDomain(data);
        subject.next(retVal);
      },
      error: (err) => {
        subject.error(err);
      }
    })

    return subject.asObservable();
  }

  public async getProducts(): Promise<Product[]> {
    return await this.makeCallToDB("products")
      .then(data => {
        // console.table(data);

        const groupedData = this.groupBy(data, "virtuemart_product_id");

        // console.table(groupedData);

        let products: Product[] = groupedData.map((productGroup: any[]) => {
          productGroup.sort((a, b) => a.product_price_publish_down.localeCompare(b.product_price_publish_down))

          let base = productGroup[productGroup.length - 1];
          let baseId = productGroup.length - 1

          if ((new Date(productGroup[productGroup.length - 1].product_price_publish_down) < new Date()) 
            || 
          (new Date(productGroup[productGroup.length - 1].product_price_publish_up) > new Date())) {
            base = productGroup[0]
            baseId = productGroup.length
          }

          let retval = new Product()

          retval.productId = base.virtuemart_product_id

          retval.categoryId = base.virtuemart_category_id
          retval.i18n_key = base.i18n_key?.replace('\n', '')
          retval.file_url = base.file_url

          let price
          let prevPrice

          if (base.product_override_price > 0.000001) {
            price = Math.round(base.product_override_price)
            prevPrice = Math.round(productGroup.reduce((max, obj) => {
              return Math.max(max, obj["product_override_price"]);
            }, productGroup[0]["product_override_price"]))

          } else {
            price = Math.round(base.product_price * DPHrate)
            prevPrice = Math.round(productGroup.reduce((max, obj) => {
              return Math.max(max, obj["product_price"]);
            }, productGroup[0]["product_price"]) * DPHrate)
          }

          let prevPriceObj
          if (prevPrice <= price) {
            prevPriceObj = null
          } else {
            prevPriceObj = {
              price: prevPrice,
              localizedDesc: JSON.stringify(prevPrice) + ' Kč'
            }
          }

          retval.pricesForCurrency = [
            {
              currency: { code: 'CZE', symbol: 'Kč' },
              oldPrice: prevPriceObj,
              newPrice: {
                price: price,
                localizedDesc: JSON.stringify(price) + ' Kč'
              },
              VatLessPrice: {
                price: Math.round(price / DPHrate),
                localizedDesc: JSON.stringify(Math.round(price / DPHrate)) + ' Kč'
              }
            }
          ]
          retval.cartCount = 1;
          retval.images = [
            {
              "small": "assets/" + base.file_url,
              "medium": "assets/" + base.file_url,
              "big": "assets/" + base.file_url
            }]
          return retval;
        })

        return products.filter(product => product.i18n_key)
      });
  }

  private groupBy(array, attribute) {
    return Object.values(array.reduce((groups, item) => {
      const key = item[attribute];
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {}));
  }


}
