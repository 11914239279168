<section id="traffic_cameras" fxLayout="column" fxLayoutAlign="start center" class="info-section theme-container">
    <div fxLayout="{{layout}}" fxLayout.xs="column" fxLayoutAlign="space-between" class="section-content">
        <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
            <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="start center">
                <img src="{{imgUrl}}" alt="image" border="0" class="img-width-100 {{class}}">
            </mat-card>
        </div>
        <div fxFlex="50" fxFlex.gt-md="50" fxFlex.gt-xs="50" class="section-column">
            <mat-card class="light-block-no-background" fxLayout="column" fxLayoutAlign="center">
                <h3 style="font-size: 2.5rem;">{{ heading }}</h3>
                <p class="check-light" [innerHTML]="paragraph"></p>
                
                <div *ngIf="button != null">
                    <button mat-raised-button class="redirect-btn green-btn mt-15" 
                        (click)="onButtonClick()" routerLink="{{link}}" type="button">
                        {{ button | translate}}
                        <mat-icon class="icon" color="white">keyboard_arrow_right</mat-icon>
                    </button>
                </div>

            </mat-card>

        </div>
    </div>
</section>