<h1 mat-dialog-title>{{ 'ACCOUNT.REGISTRATION.CONFIRMATION-DIALOG-TITLE' | translate }}</h1>

<div mat-dialog-content>
    <p>{{ 'ACCOUNT.REGISTRATION.CONFIRMATION-VIA-EMAIL' | translate }} {{data?.email}}</p>
</div>

<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <button mat-raised-button color="primary" (click)="onClose()">{{ 'BUTTONS.CONTINUE' | translate }}</button>
    </div>  
</div>